export class HelpArticleImageModalConstant {
	public static ModalOptions = {
		"templateUrl": "../../templates/help/help-article-image-modal.html",
		"windowClass": "help-article-image-modal contextual-help",
		"keyboard": true,
		"backdrop": "static",
		"controller": "HelpArticleImageController",
		"controllerAs": "haiCtrl",
		"bindToController": true,
		"resolve": {}
	};
}

export class HelpArticleConstants {
	public static HelpArticleTitles = {
		HELP_RELATED_TO_THIS_PAGE: "Help related to this page"
	}
}