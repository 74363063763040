import { Component, Input } from "@angular/core";
import {
  FxpRootScopeService,
  IRootSubjects,
} from "../../../js/services/FxpRootScopeService";

@Component({
  selector: "fxpbreadcrumb",
  templateUrl: "./fxpbreadcrumb.component.html",
})
export class FxpBreadcrumbComponent {
  @Input() fxpBreadcrumb: { displayName: string; href: string }[] = [];
  public breadCrumbEnabled: boolean;
  private fxpRootScopeService: FxpRootScopeService;
  ngOnInit(): void {
    this.fxpRootScopeService = FxpRootScopeService.getInstance();
    this.fxpRootScopeService.rootScopeSubject.subscribe(
      (fxpRootScope: IRootSubjects) => {
        this.fxpBreadcrumb = fxpRootScope.fxpBreadcrumb;
        this.breadCrumbEnabled =
          fxpRootScope.initialFlags.breadcrumbEnabled ?? null;
      }
    );
  }
}
