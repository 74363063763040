import { Component, OnInit } from "@angular/core";
import { FxpRootScopeService } from "../../../js/services/FxpRootScopeService";

@Component({
    selector: 'fxpmessages',
    templateUrl: './fxpmessages.component.html',
    viewProviders: []

})
export class FxpMessagesComponent implements OnInit {
    public rootScopeService: FxpRootScopeService;
    public messages: Array<any> = [];
    public messageClass: string = '';

    constructor() {
        this.rootScopeService = FxpRootScopeService.getInstance();
    }

    ngOnInit(): void {
        this.rootScopeService.rootScopeSubject.subscribe((data) => {
            this.messages = data.messages;
            this.messageClass = data.messageClass;
        });
    }

    clearAndReload(): void {
        this.rootScopeService.rootScopeSubject.value.clearAndReload();
    }

    closeMessage(message): void {
        this.rootScopeService.rootScopeSubject.value.closeMessage(message);
    }

    /**
   * An event handler whenever a key is pressed on Message.
   * @method Fxp.Controllers.AppController.onMessageKeyDown
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use onMessageKeyDown</caption>
   *  <div ng-app="AppController"><div ng-keydown="onMessageKeyDown">Fxp Message</div></div>;
   *  <div ng-app="AppController as app"><div ng-keydown="app.onMessageKeyDown">Focus out</div></div>;
   */
    onMessageKeyDown = function ($event) {
        if ($event.key == "Tab") {
            var targetMessage = $event.target.closest(".message"),
                allMessages = Array.from(document.querySelectorAll(".message")),
                currentMessageIndex = allMessages.indexOf(targetMessage),
                targetType = $event.target.tagName.toLowerCase(),
                isFirstMessageFocused = currentMessageIndex === 0 && targetType === "div",
                isLastMessageFocused =
                    currentMessageIndex === allMessages.length - 1 &&
                    targetType === "button",
                isForwardNavigation = !$event.shiftKey,
                isBackwardNavigation = $event.shiftKey;
            if (isForwardNavigation && isLastMessageFocused) {
                $event.preventDefault();
                $event.stopPropagation();
                (<HTMLElement>allMessages[0].querySelector(".message-content")).focus();
            } else if (isBackwardNavigation && isFirstMessageFocused) {
                $event.preventDefault();
                $event.stopPropagation();
                allMessages[allMessages.length - 1]
                    .querySelector("button")
                    .focus();
            }
        }
    };

}
