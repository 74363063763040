import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FxpRootScopeService, IRootSubjects } from '../../../js/services/FxpRootScopeService';
import { CommonUtils } from '../../../js/utils/CommonUtils';
import { FxpConfigurationService } from '../../../js/services/FxpConfiguration';
import { FxpStateService } from '../../services/FxpStateRoutingHelperService';
import { CustomEvents, FxpConstants } from '../../../js/common/ApplicationConstants';
import { TelemetryConstants } from '../../../js/telemetry/TelemetryConst';
import { DeviceFactoryProvider } from '../../../js/utils/devicefactoryService';
import { HelpArticleConstants, HelpArticleImageModalConstant } from '../../../js/constants/HelpArticle.constants';
import { FeatureUsageEvent } from '../../../js/telemetry/FeatureUsageEvent';
import { ActionStatus, ActionType, ComponentType, EventName } from '@microsoftit/telemetry-extensions-npm';
import { ErrorCodes } from '../../../js/constants/errorCodes';
import { FxpGlobalStoreService } from '../../../js/services/fxp.global.store.service';
import { HideLoader } from '../../loader/loader.actions';
import { FxpBroadcastedEvents } from '../../../js/services/FxpBroadcastedEvents';
import { CreateSupportTicketModalConstant } from '../../../js/constants/createSupportTicketRequest.constants';
import { CreateAskOpsModalConstant } from '../../../js/constants/CreateAskOpsRequest.constants';
import { FxpContext } from '../../../js/context/FxpContext';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';
import { HelpCentralService } from '../../../js/services/HelpCentralService';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FxpUIBModalService } from '../../../js/services/FxpUIBModalService';
@Component({
    selector: 'fxphelpmenu',
    templateUrl: './fxphelp.component.html'
})
export class FxpHelpMenuComponent implements OnInit {
    fxpHelpLinks: any[] = [];
    private isHelpOpen = false;
    initialFlags: any = {};

    private rootScopeService: FxpRootScopeService;
    private fxpRootScope: IRootSubjects;
    private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.HelpController`;
    private navigationCollection: Array<string> = [];
    private currentNavigationIndex: number = 0;
    private article: any = {};
    private contextualHelp: any[] = [];
    searchValue: string = "";
    private defaultHelpArticleLimit: number = 5;
    private searchedHelp: any[] = [];
    private showHelpLoader: boolean = false;
    private isSearchFilterRequired: boolean = false;
    private isContextualFilterRequired: boolean = false;
    private leftNavItem: any = {};
    fxpDefaultHelp: any;
    fxpHelpWithoutFlighting: any;
    fxpHelp: any;
    helpArticleTitles: { HELP_RELATED_TO_THIS_PAGE: string; };
    helpCentralUrl: string;
    feedbackId: any;
    ariaExpanded: string | null = null;
    constructor(
        private modalService: FxpUIBModalService,
        private fxpConfigurationService: FxpConfigurationService,
        private fxpGlobalStoreService: FxpGlobalStoreService,
        private state: FxpStateService,
        private helpCentralService: HelpCentralService,
        private fxpLoggerService: FxpLoggerService,
        private fxpContext: FxpContext,
        private device: DeviceFactoryProvider,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef) {
        this.rootScopeService = FxpRootScopeService.getInstance();
        this.rootScopeService.rootScopeSubject.subscribe((data) => {
            this.fxpRootScope = data;
            this.initialFlags = data.initialFlags;
            this.isHelpOpen = data.isHelpOpen;
        });
        var startTime = performance.now();

        var self = this;
        self.defaultHelpArticleLimit =
            fxpConfigurationService.FxpAppSettings.DefaultHelpArticleLimit;
        var helpCentralUrl =
            fxpConfigurationService.FxpAppSettings.HelpCentralUrl.replace(/\/$/, ""); //removing last slash
        self.helpCentralUrl =
            helpCentralUrl + "/tenant/" + window["tenantConfiguration"].TenantId;

        self.helpArticleTitles = HelpArticleConstants.HelpArticleTitles;

        // Search
        self.searchValue = "";
        self.fxpGlobalStoreService.DispatchGlobalAction("Platform", HideLoader({}));
        self.rootScopeService.setRootScopeField("appLoaded", true);
        self.resetNavigation();
        self.toggleHelp = self.toggleHelp.bind(self);
        self.searchSuggestions = self.searchSuggestions.bind(self);
        self.navigateToArticle = self.navigateToArticle.bind(self);
        self.showMoreContextualHelpLinks =
            self.showMoreContextualHelpLinks.bind(self);
        self.expandArticleImage = self.expandArticleImage.bind(self);
        self.setFocusToNextElement = self.setFocusToNextElement.bind(self);
        self.saveHelpFeedback = self.saveHelpFeedback.bind(self);
        self.logFxpHelpEvent = self.logFxpHelpEvent.bind(self);
        self.templateCheck = self.templateCheck.bind(self);

        this.updateAriaExpanded();

        try {
            self.fxpDefaultHelp = JSON.parse(
                fxpConfigurationService.FxpAppSettings.FxpHelp
            );
        } catch (e) {
            self.fxpDefaultHelp = {};
        }
        self.fxpHelpWithoutFlighting = CommonUtils.isNullOrEmpty(
            window["tenantConfiguration"].HelpConfiguration
        )
            ? self.fxpDefaultHelp
            : window["tenantConfiguration"].HelpConfiguration;

        //flighting code
        self.flightHandler();
        self.rootScopeService.on(
            CustomEvents.StartUpFlagRetrieved,
            self.flightHandler.bind(this)
        );
        self.rootScopeService.on(
            "createAskOpsRequest",
            self.openCreateAskOpsModal.bind(this)
        );
        self.rootScopeService.on(
            "createSupportTicketRequest",
            self.openCreateSupportTicketModal.bind(this)
        );
        self.rootScopeService.on(
            "SetContextAndLaunchBot",
            self.setContextAndLaunchBot.bind(this)
        );
        self.rootScopeService.on(
            FxpBroadcastedEvents.LaunchHelpSection,
            (open: boolean) => {
                self.toggleHelp(open);
            }
        );

        self.clearSearch = self.clearSearch.bind(self);
        // Logging pageload metrics.
        self.fxpLoggerService.logPageLoadMetrics(performance.now() - startTime);
    }

    ngOnInit() {

    }

    updateAriaExpanded() {
        this.ariaExpanded = this.isHelpOpen ? 'true' : null;
        console.log('Aria Expanded:', this.ariaExpanded); // Debug log
    }
    clearSearch() {
        this.searchValue = '';
        $("#HelpSearch_FormControl").focus();
    }

    setContextAndLaunchBot() {
        this.fxpContext.saveContext("activityKey", "Create Support Ticket");
        this.rootScopeService.broadcast(
            FxpBroadcastedEvents.OnLaunchIrisBot,
            "Launch Bot"
        );
    }

    /**
   * A function to open Create Ask Ops Modal.
   * @method Fxp.Controllers.HelpController.openCreateAskOpsModal
   * @example <caption> Example to use openCreateAskOpsModal</caption>
   * this.openCreateAskOpsModal();
   */
    openCreateAskOpsModal(event, requestType) {
        // Show popup.
        let self = this,
            options = CreateAskOpsModalConstant.ModalOptions;
        options.resolve = {
            defaultRequestType: function () {
                return requestType;
            },
        };
        setTimeout(() => {
            // self.$uibModal.open(options);
        }, 200);
    }

    /**
   * A function to open support ticket Modal.
   * @method Fxp.Controllers.HelpController.openCreateSupportTicketModal
   * @example <caption> Example to use openCreateSupportTicketModal</caption>
   * this.openCreateSupportTicketModal();
   */
    openCreateSupportTicketModal(event, requestType) {
        let self = this,
            options = CreateSupportTicketModalConstant.ModalOptions;
        options.resolve = {
            defaultRequestType: function () {
                return requestType;
            },
        };
        setTimeout(function () {
            // let modalInstance = self.$uibModal.open(options);
            // modalInstance.opened.then(() => {
            //     setTimeout(() => {
            //         let focusElement = $("#modal-close-button");
            //         if (focusElement) {
            //             focusElement.focus();
            //         }
            //     }, 0);
            // });
        }, 200);
    }

    flightHandler = (): void => {
        this.fxpHelp = JSON.parse(JSON.stringify(this.fxpHelpWithoutFlighting));
        if (
            !(this.fxpRootScope.initialFlags && this.fxpRootScope.initialFlags.botEnabled)
        )
            this.removeBotLink();
        if (
            !(
                this.fxpRootScope.initialFlags &&
                this.fxpRootScope.initialFlags.askOpsEnabled
            )
        )
            this.removeAskOpslinks();
    }
    removeBotLink(): void {
        let helpSections = this.fxpHelp.FxpHelpLinks;
        for (let i = 0; i < helpSections.length; i++) {
            helpSections[i].HelpLinks = helpSections[i].HelpLinks.filter(function (
                link
            ) {
                return link.EventName != CustomEvents.SkypeBotInit;
            });
        }
    }
    removeAskOpslinks(): void {
        let helpSections = this.fxpHelp.FxpHelpLinks;
        for (let i = 0; i < helpSections.length; i++) {
            helpSections[i].HelpLinks = helpSections[i].HelpLinks.filter(function (
                link
            ) {
                return !(
                    link.EventName == FxpConstants.askOps.createLink ||
                    link.Href == FxpConstants.askOps.viewLink
                );
            });
        }
    }

    toggleHelp = (open: boolean) => {

        //let self = this;
        this.isHelpOpen = open;
        this.updateAriaExpanded();
        console.log('isHelpOpen:', this.isHelpOpen);
        if (
            this.fxpRootScope.initialFlags &&
            this.fxpRootScope.initialFlags.contextualHelpEnabled
        ) {
            if (open) {
                //this.cdr.detectChanges();
                this.fxpLoggerService.renewSubCorrelationId();
                this.fxpRootScope.isHelpFlyoutPinned = false;
                this.resetNavigation();
                if (
                    this.isCurrentLeftNavItemModified() ||
                    !this.contextualHelp
                ) {
                    this.showLoader(true);
                    this.getArticles(this.defaultHelpArticleLimit, false);
                }
                setTimeout(function () {
                    $("#open-help-central").focus();
                }, 1000);
            }

            this.rootScopeService.setRootScopeField("isHelpOpen", open);
            this.cdr.detectChanges();
        }
    }

    resetNavigation() {
        let self = this;
        self.navigationCollection = [];
        self.currentNavigationIndex = 0;
        self.navigationCollection[0] = "home";
        self.searchValue = "";
        self.enableShowMoreLink();
    }

    /**
      * An event handler whenever header is clicked.
      * @method Fxp.Controllers.HelpController.logHelpIconEvent
      * @param {boolean} isModalOpen a flag which states if modal is open/close
      * @example <caption> Example to use logHelpIconEvent</caption>
      * <div ng-click="logHelpIconEvent(true)">Log Fxp Help</div>;
      */
    logHelpIconEvent = function (isModalOpen) {
        if (isModalOpen) {
            var self = this;
            var propBag = self.fxpLoggerService.createPropertyBag();
            propBag.addToBag(FxpConstants.metricConstants.HelpIconClicked, "Yes");
            self.fxpLoggerService.logUserAction(
                `${self.sourceForTelemetry}.logHelpIconEvent`,
                `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpHelpIconClick`,
                "Help icon clicked by user",
                propBag
            );
        }
    }


    navigateToPreviousState() {
        let self = this;
        if (
            self.navigationCollection[
            self.currentNavigationIndex - 1
            ] == "home"
        ) {
            let selectedArticle = self.contextualHelp[
                HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
            ]?.filter(function (article) {
                let result = false;
                if (
                    article.id ==
                    self.navigationCollection[
                        self.currentNavigationIndex
                    ].split("?")[1]
                ) {
                    result = true;
                } else {
                    article.pullFocus = "false";
                    result = false;
                }
                return result;
            });
            if (selectedArticle.length) {
                selectedArticle[0].pullFocus = "true";
            } else {
                self.contextualHelp[
                    HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
                ][0].pullFocus = "true";
            }
        } else if (
            self.navigationCollection[
                self.currentNavigationIndex - 1
            ].indexOf("article") != -1
        ) {
            self.showLoader(true);
            self.getArticleData(
                self.navigationCollection[
                    self.currentNavigationIndex - 1
                ].split("?")[1]
            );
        } else {
            self.showLoader(true);
            self.searchValue =
                self.navigationCollection[
                    self.currentNavigationIndex - 1
                ].split("?")[1];
            self.getArticles(self.defaultHelpArticleLimit, false);
        }
        self.currentNavigationIndex--;
    }


    getArticleData(articleId) {
        var self = this,
            startTime = performance.now(),
            propbag = self.fxpLoggerService.createPropertyBag();
        self.article = {};
        self.helpCentralService.getContextualHelpArticleContent(articleId).then(
            (response) => {
                self.article = response;
                if (self.article.articleContent) {
                    self.article.articleContent =
                        self.article.articleContent.replace(/&nbsp;/g, " ");
                }

                self.showLoader(false);
                propbag.addToBag("ArticleId", articleId);
                propbag.addToBag(
                    "TotalTime",
                    (performance.now() - startTime).toString()
                );
                self.fxpLoggerService.logEvent(
                    `${self.sourceForTelemetry}.getArticleData`,
                    `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetContextualHelpArticleContent`,
                    propbag
                );
                self.saveViewCount(articleId);
            },
            (error) => {
                self.showLoader(false);
                self.logError(
                    error,
                    self.fxpRootScope.fxpUIConstants.UIMessages
                        .HelpCentralGetArticleDataSvcError,
                    ErrorCodes.GetArticleData_Failure
                );
            }
        );
    }

    /**
   * A method to log error
   * @method Fxp.Controllers.HelpController.logError
   * @param {any} error is a error object
   * @example <caption> Example to use sortObject</caption>
   * HelpController.logError(error)
   */
    logError(error, message, code): void {
        var self = this;
        var propbag = self.fxpLoggerService.createPropertyBag();
        propbag.addToBag(FxpConstants.metricConstants.Status, error.status);
        propbag.addToBag(FxpConstants.metricConstants.StatusText, error.statusText);
        self.fxpLoggerService.logError(
            `${this.sourceForTelemetry}.LogError`,
            message.ErrorMessageTitle,
            code,
            null,
            propbag
        );
    }


    saveViewCount(articleId) {
        var self = this,
            startTime = performance.now(),
            propbag = self.fxpLoggerService.createPropertyBag();
        self.helpCentralService.saveArticleViewCount(articleId).then(
            (response) => {
                propbag.addToBag("ArticleId", articleId);
                propbag.addToBag(
                    "TotalTime",
                    (performance.now() - startTime).toString()
                );
                self.fxpLoggerService.logEvent(
                    `${self.sourceForTelemetry}.saveViewCount`,
                    `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SaveHelpFeedback`,
                    propbag
                );
            },
            (error) => {
                self.logError(
                    error,
                    self.fxpRootScope.fxpUIConstants.UIMessages
                        .HelpCentralSaveArticleViewCountSvcError,
                    ErrorCodes.SaveViewCount_Failure
                );
            }
        );
    }

    navigateToNextState() {
        let self = this;
        self.showLoader(true);
        self.currentNavigationIndex++;
        if (
            self.navigationCollection[
                self.currentNavigationIndex
            ].indexOf("article") != -1
        ) {
            self.getArticleData(
                self.navigationCollection[
                    self.currentNavigationIndex
                ].split("?")[1]
            );
        } else if (
            self.navigationCollection[
                self.currentNavigationIndex
            ].indexOf("search") != -1
        ) {
            self.searchHelpArticles(
                self.navigationCollection[
                    self.currentNavigationIndex
                ].split("?")[1]
            );
        }
    }

    /**
   * A method called when help article are to be searched
   * @method Fxp.Controllers.HelpController.searchHelpArticles
   * @param {string} searchString is a string to be searched
   * @example <caption> Example to use searchHelpArticles</caption>
   * HelpController.searchHelpArticles('new')
   */
    searchHelpArticles(searchString) {
        let self = this;
        self.showLoader(true);
        self.searchValue = searchString;
        self.getArticles(self.defaultHelpArticleLimit, false);

        // Add current search to Navigation collection. So that it can be visited again.
        self.currentNavigationIndex++;
        self.navigationCollection.splice(self.currentNavigationIndex);
        self.navigationCollection[self.currentNavigationIndex] =
            "search?" + searchString;
    }

    /**
   * A function to get contextual help article.
   * @method Fxp.Controllers.HelpController.getArticles
   * @param {number} topArticles which is passed for howmany articles to get
   * @param {boolean} isShowAll a flag for which states if call for show more
   * @example <caption> Example to use getArticles</caption>
   * this.getArticles();
   */
    getArticles(topArticles, isShowAll) {
        var self = this,
            businessCapability,
            businessFunctions,
            route,
            searchString,
            startTime = performance.now(),
            propbag = self.fxpLoggerService.createPropertyBag();
        const source = `${self.sourceForTelemetry}.getArticles`;
        const contextualHelpFeature = new FeatureUsageEvent(
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ContextualHelp`,
            ActionType.User,
            "ViewContexualHelp",
            EventName.ButtonClick,
            ComponentType.Web
        );
        contextualHelpFeature.ActionStatus = ActionStatus.Succeeded;
        if (self.searchValue) {
            searchString = self.searchValue;
        } else {
            if (self.fxpRootScope.currentLeftNavItem) {
                //finding currentLeftNavItem is L0 or L1 based on parentId
                businessCapability = !self.fxpRootScope.currentLeftNavItem.parentId
                    ? self.fxpRootScope.currentLeftNavItem.id
                    : self.fxpRootScope.currentLeftNavItem.parentId;
                businessFunctions = self.fxpRootScope.currentLeftNavItem.parentId
                    ? self.fxpRootScope.currentLeftNavItem.id
                    : null;
            }
            if (window.location.hash) {
                route = (window.location.hash) ? window.location.hash.substring(1) : null;
            }
        }
        self.helpCentralService
            .getContextualHelpArticles(
                topArticles,
                businessCapability,
                businessFunctions,
                searchString,
                route
            )
            .then(
                (response) => {
                    if (searchString) {
                        self.searchedHelp = response.result;
                    } else {
                        self.contextualHelp = [{
                            "Help related to this page": [],
                        }];
                        response.result.map((item) => {
                            if (!self.contextualHelp[item.categoryDisplayText]) {
                                self.contextualHelp[item.categoryDisplayText] = [];
                            }
                            self.contextualHelp[item.categoryDisplayText].push(item);
                        });
                    }

                    if (isShowAll) {
                        self.hideShowMoreLink();
                    } else {
                        self.enableShowMoreLink();
                    }
                    self.showLoader(false);
                    propbag.addToBag("BusinessCapability", businessCapability);
                    propbag.addToBag("BusinessFunctions", businessFunctions);
                    propbag.addToBag("Route", route);
                    propbag.addToBag("TopArticles", topArticles);
                    propbag.addToBag(
                        "TotalTime",
                        (performance.now() - startTime).toString()
                    );
                    self.fxpLoggerService.logEvent(
                        source,
                        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.GetContextualHelpArticles`,
                        propbag
                    );
                    self.fxpLoggerService.logFeatureUsageEvent(
                        source,
                        contextualHelpFeature,
                        propbag
                    );
                },
                (error) => {
                    contextualHelpFeature.ActionStatus = ActionStatus.Failed;
                    self.showLoader(false);
                    self.logError(
                        error,
                        self.fxpRootScope.fxpUIConstants.UIMessages
                            .HelpCentralGetArticlesSvcError,
                        ErrorCodes.GetContextualHelpArticles_Failure
                    );
                    self.fxpLoggerService.logFeatureUsageEvent(
                        source,
                        contextualHelpFeature,
                        propbag
                    );
                }
            );
    }


    enableShowMoreLink() {
        var self = this;
        if (self.contextualHelp && !self.searchValue) {
            self.isContextualFilterRequired =
                self.contextualHelp[
                    HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
                ]?.length >= self.defaultHelpArticleLimit;
        }
        if (self.searchedHelp && self.searchValue) {
            self.isSearchFilterRequired =
                self.searchedHelp.length >= self.defaultHelpArticleLimit;
        }
    }



    hideShowMoreLink() {
        var self = this;
        // hide "show more" link in contextual help
        if (self.contextualHelp && !self.searchValue) {
            self.isContextualFilterRequired = false;
            self.contextualHelp[
                HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
            ]?.forEach(function (article) {
                article.pullFocus = "false";
            });
            if (
                self.contextualHelp[
                HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
                ][self.defaultHelpArticleLimit]
            )
                self.contextualHelp[
                    HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
                ][self.defaultHelpArticleLimit].pullFocus = "true";
            else
                self.contextualHelp[
                    HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
                ][
                    self.contextualHelp[
                        HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
                    ].length - 1
                ].pullFocus = "true";
        }
        // hide "show more" link in search
        if (self.searchedHelp && self.searchValue) {
            self.isSearchFilterRequired = false;
            self.searchedHelp?.forEach(function (article) {
                article.pullFocus = "false";
            });
            if (self.searchedHelp[self.defaultHelpArticleLimit])
                self.searchedHelp[
                    self.defaultHelpArticleLimit
                ].pullFocus = "true";
            else
                self.searchedHelp[
                    self.searchedHelp.length - 1
                ].pullFocus = "true";
        }
    }


    /**
 * A method to show hide loader in help flyout
 * @example <caption> Example to use showLoader</caption>
 * HelpController.showLoader(true)
 */
    showLoader(status) {
        var self = this;
        self.showHelpLoader = status;
    }

    navigateToHelpCentral() {
        var self = this;
        window.open(self.helpCentralUrl, "_blank");
    }

    navigateToHome() {
        let self = this;
        self.currentNavigationIndex = 0;
        self.navigationCollection.splice(self.currentNavigationIndex);
        self.navigationCollection[self.currentNavigationIndex] =
            "home";
        self.searchValue = "";
        if (self.isCurrentLeftNavItemModified()) {
            self.showLoader(true);
            self.getArticles(self.defaultHelpArticleLimit, false);
        }
        self.contextualHelp[
            HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
        ]?.forEach(function (article) {
            article.pullFocus = "false";
        });
        self.contextualHelp[
            HelpArticleConstants.HelpArticleTitles.HELP_RELATED_TO_THIS_PAGE
        ][0].pullFocus = "true";
    }

    isCurrentLeftNavItemModified() {
        var self = this;
        const isLeftNavModified = JSON.stringify(this.leftNavItem) !== JSON.stringify(this.fxpRootScope.currentLeftNavItem);
        if (isLeftNavModified) {
            self.leftNavItem = JSON.parse(JSON.stringify(self.fxpRootScope.currentLeftNavItem));
        }
        return isLeftNavModified;
    }



    closeHelp = () => {
        this.isHelpOpen = false;
        this.rootScopeService.setRootScopeField("isHelpOpen", false);
        this.cdr.detectChanges();
    }

    isDeviceMobile() {
        return !this.device.isMobile();
    }

    onSearchKeyDown(event, searchString) {
        var self = this;
        if (event.keyCode == 13) {
            self.searchHelpArticles(searchString);
        }
    }

    onSearchSuggestionSelect(item: NgbTypeaheadSelectItemEvent) {
        var self = this;
        self.searchHelpArticles(item);
    }
    searchSuggestions(searchPhrase) {
        let self = this;
        return self.helpCentralService.getSuggestions(searchPhrase).then(
            (response) => {
                return response.data;
            },
            (reject) => {
                self.logError(
                    reject,
                    self.fxpRootScope.fxpUIConstants.UIMessages
                        .HelpCentralGetSuggestionsError,
                    ErrorCodes.SearchSuggestions_Failure
                );
            }
        );
    }

    navigateToArticle(articleId: string) {
        let self = this;
        self.showLoader(true);
        self.getArticleData(articleId);
        // Add current article to Navigation collection. So that it can be visited again.
        self.currentNavigationIndex++;
        self.navigationCollection.splice(self.currentNavigationIndex);
        self.navigationCollection[self.currentNavigationIndex] =
            "article?" + articleId;
    }

    logFxpHelpEvent(helpItem, parent, view) {
        var self = this;

        var propBag = self.fxpLoggerService.createPropertyBag();
        propBag.addToBag(FxpConstants.metricConstants.HelpLinkParent, parent);
        propBag.addToBag(
            FxpConstants.metricConstants.HelpLinkChild,
            helpItem.title
        );
        if (view == "FlyoutView")
            propBag.addToBag(FxpConstants.metricConstants.HelpView, view);
        self.fxpLoggerService.logUserAction(
            `${self.sourceForTelemetry}.logFxpHelpEvent`,
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpHelpLinkAccessed`,
            "Help link clicked by user",
            propBag
        );
    }

    showMoreContextualHelpLinks() {
        let self = this;
        self.showLoader(true);
        self.getArticles(0, true);
    }

    saveHelpFeedback(articleId, isHelpful) {
        var self = this,
            startTime = performance.now(),
            propbag = self.fxpLoggerService.createPropertyBag();
        self.showLoader(true);
        var feedback = {
            articleId: articleId,
            IsHelpful: isHelpful,
        };
        self.helpCentralService.saveArticleFeedback(feedback).then(
            (response) => {
                propbag.addToBag("ArticleId", articleId);
                propbag.addToBag("IsHelpFul", isHelpful);
                propbag.addToBag(
                    "TotalTime",
                    (performance.now() - startTime).toString()
                );
                self.fxpLoggerService.logEvent(
                    `${self.sourceForTelemetry}.saveHelpFeedback`,
                    `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SaveHelpFeedback`,
                    propbag
                );
                self.feedbackId = response.data;
                self.showLoader(false);
            },
            (error) => {
                self.showLoader(false);
                self.logError(
                    error,
                    self.fxpRootScope.fxpUIConstants.UIMessages
                        .HelpCentralSaveArticleFeedbackSvcError,
                    ErrorCodes.SaveHelpFeedback_Failure
                );
            }
        );
    }

    setFocusToNextElement(event) {
        var focusElement = $(event.target)[0];
        if (event.shiftKey && event.keyCode == 9) {
            if (focusElement.id === "help-back-button") {
                if ($("#help-gotop-button").is(":visible")) {
                    this.setFocus("#help-gotop-button");
                } else {
                    this.setFocus("#view-helpcentral-button");
                }
            }
        } else if (event.keyCode == 9) {
            if (focusElement.id === "view-helpcentral-button") {
                if (!$("#help-gotop-button").is(":visible")) {
                    this.setFocus("#help-back-button");
                }
            } else if (focusElement.id === "help-gotop-button") {
                this.setFocus("#help-back-button");
            }
        }
    }

    private setFocus(controlId) {
        setTimeout(() => {
            $(controlId).focus();
        }, 0);
        return;
    }

    expandArticleImage(source) {
        // Show modal.
        let self = this,
            options = HelpArticleImageModalConstant.ModalOptions;
        options.resolve = {
            source: function () {
                return source;
            },
        };
        setTimeout(function () {
            self.modalService.open(options);
            self.modalService.opened().then(() => {
                setTimeout(function () {
                    var focusElement = $("#btnImageCloseModal");
                    if (focusElement) focusElement.focus();
                }, 0);
            });
        }, 200);
    }
    templateCheck(name) {
        let self = this,
            result = false;
        if (
            self.fxpRootScope.initialFlags.contextualHelpEnabled &&
            self.navigationCollection[
                self.currentNavigationIndex
            ].indexOf(name) != -1
        ) {
            result = true;
        }
        return result;
    }
    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    trackById(index: number, item: any): number {
        return item.id;
    }
}
