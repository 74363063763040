import { FxpUIBModalService } from "../services/FxpUIBModalService";

export class HelpArticleImageController {
	constructor(
		private modalService: FxpUIBModalService,
		private source: any
	) { }

	closeModal() {
		var self = this;
		self.modalService.dismiss()
	}
}