/**
 * @namespace  Microsoft.PS.ConFit
 */
/**
 * @namespace Microsoft.PS.ConFit.Domain
 */
/**
 * Represents a ConfigRequest
 * @class Microsoft.PS.ConFit.Domain.ConfigRequest
 * @classdesc Represents a ConfigRequest
 * @param {string} appId  uniqueid of an application
 * @param {string} featureName  name of an feature
 * @param {string} keys collection of keys against the feature
 * @example <caption> Example to create a ConfigRequest</caption>
 *  // Initializing EnityRequest
 *  // var confiReq = new Microsoft.PS.ConFit.Domain.ConfigRequest("FXP");
 *  // var confiReqFeature = new Microsoft.PS.ConFit.Domain.ConfigRequest("FXP","Telemetry");
 *  // var confiReqFeatureForAKey = new Microsoft.PS.ConFit.Domain.ConfigRequest("FXP","Telemetry",['DiagnosticLevel','EventEnabled']);
 */
var ConfigRequest = /** @class */ (function () {
    function ConfigRequest(_appId, _featureName, _keys) {
        this.app = _appId;
        this.feature = _featureName;
        this.keys = _keys;
    }
    return ConfigRequest;
}());
export { ConfigRequest };
var ConfigResponse = /** @class */ (function () {
    function ConfigResponse() {
    }
    return ConfigResponse;
}());
export { ConfigResponse };
