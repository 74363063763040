import { Component, Input } from "@angular/core";
import {
  FxpRootScopeService,
  IRootSubjects,
} from "../../../js/services/FxpRootScopeService";

@Component({
  selector: "fxploader",
  templateUrl: "./fxploader.component.html",
})
export class FxpLoaderComponent {
  @Input() showLoader: boolean = false;
  @Input() loadingText: string = "";
  private fxpRootScopeService: FxpRootScopeService;
  private fxpRootScope: IRootSubjects;
  ngOnInit(): void {
    this.fxpRootScopeService = FxpRootScopeService.getInstance();
    this.fxpRootScopeService.rootScopeSubject.subscribe(
      (rootScope: IRootSubjects) => {
        this.fxpRootScope = rootScope;
        this.showLoader = rootScope.showLoader;
        this.loadingText = rootScope.loadingText;
      }
    );
  }
}
