import { Component } from '@angular/core';
import { PlannedDownTimeService } from '../../systemMessages/PlannedDownTimeService';

@Component({
    selector: 'fxpsystemupdatemsg',
    templateUrl: './fxpsystemupdatemsg.component.html'
})
export class FxpSystemUpdateMsgComponent {
    constructor(public plannedDowntimeService: PlannedDownTimeService) { }
}
