export enum ComponentType  {
    Web = 0,
    SmartApp = 1,
    Device = 2,
    BackgroundProcess = 3,
    WebService = 4,
    Executable = 5,
    DynamicLinkLibrary = 6,
    JobService = 7,
    WorkflowComponent = 8,
    DataStore = 9,
    Other = 10
}