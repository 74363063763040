import { FxpRootScopeService, IRootSubjects } from "../services/FxpRootScopeService";
export class LeftNavOutsideClickDirective implements angular.IDirective {
    static leftNavOutsideClick($rootScope, $document, $parse): angular.IDirective {
        let rootScopeService = FxpRootScopeService.getInstance();
        let fxpRootScope: IRootSubjects = rootScopeService.rootScopeSubject.getValue();
        rootScopeService.rootScopeSubject.subscribe((data) => {
            fxpRootScope = data;
        });
        return {
            restrict: 'A',
            link: function ($scope, elem, attr) {


                if ($parse(attr.leftNavOutsideClick)($scope)) {
                    var outSideClickHandler = function ($event) {
                        var el = $($event.target).closest(".leftBar");
                        $(".expandedtooltip").hide();
                        if (el.length === 0) {
                            if (fxpRootScope.isLeftNavOpen && !fxpRootScope.isLeftNavPinned) {
                                // $rootScope.$apply(function () {
                                this.rootScopeService.setRootScopeField("isLeftNavOpen", false);
                                // });
                            }
                        }
                    };

                    $document.bind('click', outSideClickHandler);
                    $scope.$on('$destroy', function () {
                        $document.unbind('click', outSideClickHandler);
                    });
                }
            }
        };
    }
}
