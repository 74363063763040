import { Component, OnInit } from '@angular/core';
import { BrowserExperience } from '../../../js/services/BrowserExperience';

@Component({
    selector: 'browserExperience',
    templateUrl: './browserExperience.component.html'
})
export class BrowserExperienceComponent implements OnInit {
    topMargin: string;
    isBannerHidden: boolean;
    message: string;

    constructor(private browserExperienceService: BrowserExperience) { }

    ngOnInit(): void {
        this.browserExperienceService.checkBrowser();
        this.topMargin = this.browserExperienceService.topMargin;
        this.isBannerHidden = this.browserExperienceService.isBannerHidden === 1;
        this.message = this.browserExperienceService.message;
    }

    remindMeLaterClick(): void {
        this.browserExperienceService.remindMeLaterClick();
        this.isBannerHidden = true;
    }
}
