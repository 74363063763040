/**
 * @application  Fxp
 */
/**
 * @module Fxp.Controllers
 */
import { ILogger } from "../../js/interfaces/ILogger";
import { FxpMessageService } from "../banner/FxpMessageService";
import { AdminLandingService } from "./AdminLandingService";
import { FxpAuthorizationService } from "../../js/services/FxpAuthorizationService";
import { FxpConstants } from "../../js/common/ApplicationConstants";
import { FxpEventBroadCastService } from "../../js/services/BroadCastingService";
import { CommonUtils } from "../../js/utils/CommonUtils";
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { HideLoader, HideSpecificLoader, ShowSpecificLoader } from "../loader/loader.actions";
import { UI_LOADER_NAMES } from "../loader/UILoader.constants";
import { TelemetryConstants } from "../../js/telemetry/TelemetryConst";
import { ErrorCodes } from "../../js/constants/errorCodes";
import { ErrorSeverityLevel } from "../../js/telemetry/ErrorSeverityLevel";
import { FxpRootScopeService, IRootSubjects } from "../../js/services/FxpRootScopeService";

/**
* This is the controller to fetch and update the Admin data .
* @class Fxp.Controllers.AdminLandingController
* @classdesc A main controller for AdminLanding of FxpApp module
*/
export class AdminLandingController {
	private fxpRootScope: IRootSubjects;
	private $scope: any;
	private adminLandingService: AdminLandingService;
	private fxpMessage: FxpMessageService;
	private fxpLoggerService: ILogger;
	private fxpAuthorizationService: FxpAuthorizationService;
	private telemetrySource: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AdminLandingController`;


	constructor(
		$scope: any,
		adminLandingService: AdminLandingService,
		fxpMessage: FxpMessageService,
		fxpLoggerService: ILogger,
		fxpAuthorizationService: FxpAuthorizationService,
		private fxpBroadCast: FxpEventBroadCastService,
		private fxpGlobalStoreService: FxpGlobalStoreService) {
		var self = this;
		self.$scope = $scope;
		let rootScopeService = FxpRootScopeService.getInstance();
		rootScopeService.rootScopeSubject.subscribe((data) => {
			this.fxpRootScope = data;
		});
		self.adminLandingService = adminLandingService;
		self.fxpMessage = fxpMessage;
		self.fxpLoggerService = fxpLoggerService;
		self.fxpAuthorizationService = fxpAuthorizationService;
		self.fxpBroadCast.On("testBroadCast", function (evnt, arg) {
			alert(arg);
		});
		self.GetAdminTiles();
	}

	GetAdminTiles = () => {
		var self = this;
		self.fxpGlobalStoreService.DispatchGlobalAction("Platform", ShowSpecificLoader({ name: UI_LOADER_NAMES.FXP_LOADERS.ADMIN_LANDING_LOADER, loadingText: "Admin Page Loading..." }));
		self.adminLandingService.GetAdminTileDetails(false).then(function (response) {
			if (response && response.length > 0) {
				self.$scope.adminTileGroup = response;
			}
			else {
				var event = { preventDefault: function () { } };
				self.fxpAuthorizationService.redirectToUnauthorizedState(event, "Administration");
			}
		}, function (error) {

			self.fxpMessage.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AdminLandingServiceCallFailedError.ErrorMessage, FxpConstants.messageType.error);
			let bag = self.fxpLoggerService.createPropertyBag();
			bag.addToBag('Error', CommonUtils.objectToString(error));
			bag.addToBag('RedBannerDisplayed', 'Yes');
			self.fxpLoggerService.logError(`${self.telemetrySource}.GetAdminTiles`,
				self.fxpRootScope.fxpUIConstants.UIMessages.AdminLandingServiceCallFailedError.ErrorMessageTitle,
				ErrorCodes.OnGetAdminTilesFailure,
				null,
				bag, undefined, undefined, ErrorSeverityLevel.Medium);
		}
		);
		setTimeout(function () {
			self.fxpGlobalStoreService.DispatchGlobalAction("Platform", HideSpecificLoader({ name: UI_LOADER_NAMES.FXP_LOADERS.ADMIN_LANDING_LOADER }));
		}, 100);


	}
}

AdminLandingController.$inject = ['$scope', 'AdminLandingService', 'FxpMessageService', 'FxpLoggerService', 'FxpAuthorizationService', 'FxpEventBroadCastService', 'FxpGlobalStoreService'];