import { Component, Inject, OnInit, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { StateService } from "@uirouter/core";
import { PartnerAppRegistrationService } from '../../../js/services/PartnerAppRegistrationService';
import { FxpLazyLoader } from '../../fxplazyloader';
import { LazyLoaderService } from "../../../js/services/lazyLoaderService";
import { PageLoaderService } from '../../loader/pageLoaderService';
import { FxpEventBroadCastService } from '../../../js/services/BroadCastingService';
import { FxpBroadcastedEvents } from '../../../js/services/FxpBroadcastedEvents';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';
import { ComponentFramework } from '../../../js/common/enum/ComponentFramework';

@Component({
    selector: 'iris-launcher',
    templateUrl: './fxpbot.component.html'
})
export class FxpBotLauncherComponent implements OnInit {

    constructor(
        @Inject(forwardRef(() => PageLoaderService)) public pageLoaderService: PageLoaderService,
                 @Inject(forwardRef(() => LazyLoaderService)) public lazyLoadingService: LazyLoaderService,
                 @Inject(forwardRef(() =>FxpEventBroadCastService)) private fxpEventBroadCastService: FxpEventBroadCastService,
                 @Inject(forwardRef(() =>FxpLoggerService)) private fxpLoggerService: FxpLoggerService,
                 @Inject(forwardRef(() => StateService)) private stateService: StateService,
                 ){
        
    }

    @ViewChild('container', { read: ViewContainerRef, static: false }) entry: ViewContainerRef;
    ngOnInit() {
        this.fxpEventBroadCastService.On(FxpBroadcastedEvents.OnLaunchIrisBot, this.showVirtualAssistant.bind(this));
    }

    showVirtualAssistant = function() {
        this.fxpLoggerService.renewCorrelationId(); 
        if(window["IsBotVisible"] == undefined || !window["IsBotVisible"]) {           
            let self = this;         
            this.pageLoaderService.fnShowPageLoader("Loading Bot...");
            var FxpAppSettings = FxpAppSettings || window["FxpAppSettings"] || {};
            const moduleBundle = FxpAppSettings.IrisModuleBundle;
            const bundle = FxpAppSettings.IrisBundle;
            this.lazyLoadingService.getInstance().load([moduleBundle, bundle], { serie: true }).then(function(){
                let route = self.stateService.get('iris-launcher');
                let ngComponent = route.childComponent;
                let ngModule = route.ngModule; 
                
                setTimeout(function () {
                    if (ngModule._ngModuleDefFactory) {
                        //Module is AOT compiled
                        let ngModuelRef = FxpLazyLoader.instantiateModule(ngModule);
                        self.loadComponent(ngModuelRef, ngComponent);
                        return;
                    }
        
                    FxpLazyLoader.compileAndLoadModule(ngModule).then(function (ngModuleRef) {
                        self.loadComponent(ngModuleRef, ngComponent);
                    }).catch((error) => {
                        console.log(error);
                    });
                }, 0);
                self.pageLoaderService.fnHidePageLoader();
                window["IsBotVisible"] = true;
            });
        }
    }


    loadComponent(moduleRef: any, component: any): void {
        const self = this;
        try {
            let componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(component);
            this.entry.createComponent(componentFactory, 0, moduleRef.injector);
        }
        catch (error) {
            console.error(error);
        }
    }
}
