import { Component, Input, OnInit } from "@angular/core";
import { ActionStatus, ActionType, ComponentType, EventName } from "@microsoftit/telemetry-extensions-npm";
import { CustomEvents, FxpConstants } from "../../../js/common/ApplicationConstants";
import { ErrorCodes } from "../../../js/constants/errorCodes";
import { DashBoardHelper } from "../../../js/factory/DashBoardHelper";
import { FxpBroadcastedEvents } from "../../../js/services/FxpBroadcastedEvents";
import { FxpConfigurationService } from "../../../js/services/FxpConfiguration";
import { FxpFeedbackService } from "../../../js/services/FxpFeedbackService";
import { FxpRootScopeService, IRootSubjects } from "../../../js/services/FxpRootScopeService";
import { OBOUserService } from "../../../js/services/OBOUserService";
import { UserInfoService } from "../../../js/services/UserInfoService";
import { FxpGlobalStoreService } from "../../../js/services/fxp.global.store.service";
import { UserProfileService } from "../../../js/services/userProfileService";
import { ErrorSeverityLevel } from "../../../js/telemetry/ErrorSeverityLevel";
import { FeatureUsageEvent } from "../../../js/telemetry/FeatureUsageEvent";
import { LogPropertyBag } from "../../../js/telemetry/LogPropertyBag";
import { TelemetryConstants } from "../../../js/telemetry/TelemetryConst";
import { FxpLoggerService } from "../../../js/telemetry/fxpLogger";
import { TelemetryContext } from "../../../js/telemetry/telemetrycontext";
import { CommonUtils } from "../../../js/utils/CommonUtils";
import { DeviceFactoryProvider } from "../../../js/utils/devicefactoryService";
import { FxpMessageService } from "../../banner/FxpMessageService";
import { FxpStateService } from "../../services/FxpStateRoutingHelperService";

@Component({
    selector: 'fxpheader',
    templateUrl: './fxpheader.component.html',
    styleUrls: []
})
export class FxpHeaderComponent implements OnInit {
    @Input() fxpUIConstants: any;
    @Input() fxpheaderdata: any;
    @Input() initialFlags: any;
    @Input() isBotEnabled: boolean;
    @Input() app: any;
    @Input() userProfile: any;
    @Input() userThumbnailPhoto: string;
    @Input() reportsToThumbnailPhoto: string;
    @Input() profileFlyoutConfig: any[];
    @Input() manageProfileFlight: boolean;
    @Input() actOnBehalfOfUserActive: boolean;
    @Input() isFullScreenEnabled: boolean;
    @Input() isFeedbackDialogOpen: boolean;
    private userThumbnailWaitTime: number = 3000;
    public showViewFullProfileLink: any;
    public oboUIStrings: any;
    private rootScopeService: FxpRootScopeService;
    @Input() OBOUser: any;
    @Input() headerLogo: any;
    public fxpRootScope: IRootSubjects;
    private readonly sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AppController`;


    constructor(
        private fxpConfigurationService: FxpConfigurationService,
        private userInfoService: UserInfoService,
        private fxpStateService: FxpStateService,
        private fxpGlobalStoreService: FxpGlobalStoreService,
        private fxpLogger: FxpLoggerService,
        private fxpMessage: FxpMessageService,
        private device: DeviceFactoryProvider,
        private OBOUserService: OBOUserService,
        private dashBoardHelper: DashBoardHelper,
        private userProfileService: UserProfileService,
        private fxpFeedbackService: FxpFeedbackService,
        private fxpTelemetryContext: TelemetryContext
    ) {
        this.OBOUser = {};
        this.showViewFullProfileLink =
            window["tenantConfiguration"].ShowFullProfile;

        this.rootScopeService = FxpRootScopeService.getInstance();
        this.OBOUserService = OBOUserService;
        //this.fxpLoggerService = fxpLoggerService;
        // this.userProfileService = userProfileService;
        // this.fxpFeedbackService = fxpFeedbackService;
        // this.fxpTelemetryContext = fxpTelemetryContext;
    }

    ngOnInit(): void {
        this.profileFlyoutConfig = JSON.parse(this.fxpConfigurationService.FxpAppSettings.ProfileFlyoutConfig);
        this.manageProfileFlight = false;
        this.rootScopeService.rootScopeSubject.subscribe((data) => {
            this.fxpRootScope = data;
            this.fxpUIConstants = data.fxpUIConstants;
            this.fxpheaderdata = data.fxpheaderdata;
            this.initialFlags = data.initialFlags;
            this.isBotEnabled = data.isBotEnabled;
            this.userProfile = data.userProfile;
            this.userThumbnailPhoto = data.userThumbnailPhoto;
            this.reportsToThumbnailPhoto = data.reportsToThumbnailPhoto;
            this.actOnBehalfOfUserActive = data.actOnBehalfOfUserActive;
            this.isFullScreenEnabled = data.isFullScreenEnabled;
            if (this.fxpRootScope.actOnBehalfOfUserActive) {
                this.OBOUser.name = this.OBOUserService.getOBOUser().displayName;
                this.OBOUser.href = this.OBOUserService.getOBOUser().href;
                this.OBOUser.alias = this.OBOUserService.getOBOUser().alias;
            }
            else {
                this.OBOUser = {};
            }
        });
        this.oboUIStrings = this.fxpRootScope.fxpUIConstants.UIStrings.OBOUIStrings;
        this.rootScopeService.on(CustomEvents.OnProfileFromGraphAPI, () => {
            this.showViewFullProfileLink = false;
        });

        this.rootScopeService.on(FxpBroadcastedEvents.OnUserContextChanged, (currentUserChangedUserAlias) => {
            if (this.fxpRootScope.actOnBehalfOfUserActive) {
                this.rootScopeService.setRootScopeField("currentRoutes", this.OBOUserService.getOBOUserRoutes());
                this.OBOUser.name = this.OBOUserService.getOBOUser().displayName;
                this.OBOUser.href = this.OBOUserService.getOBOUser().href;
                this.OBOUser.alias = this.OBOUserService.getOBOUser().alias;
                if (!CommonUtils.isNullOrEmpty(this.OBOUserService.getOBOUserTenantConfiguration())) {
                    this.setTenantConfiguration(this.OBOUserService.getOBOUserTenantConfiguration());
                }
                console.log("OBO Routes Filling");
            } else {
                this.OBOUserService.removeOBOUserContext();
                this.rootScopeService.setRootScopeField("currentRoutes", window["loggedInUserConfig"]);
                this.setTenantConfiguration(window["tenantConfiguration"]);
                console.log("Logged in Routes Filling");
            }
            this.dashBoardHelper.fillRoutes(this.fxpRootScope.currentRoutes);
            if (this.fxpRootScope.initialFlags.feedbackEnabled)
                this.fxpFeedbackService.setUserDetailsToFeedback();
        });

        this.rootScopeService.setRootScopeField("navigateToLandingPage", this.navigateToLandingPage.bind(this));
    }

    private setTenantConfiguration(tenantConfig: any): void {
        var self = this;
        self.setUIStrings(tenantConfig.UIStrings);
        self.updateHeaderLogo(tenantConfig.FxpHeaderLogo);
    }

    private setUIStrings(uiStrings: any): void {
        var self = this;
        var uiStringsInternal = self.userInfoService.isActingOnBehalfOf() ? self.filterUIStringsForOBO(uiStrings) : uiStrings;

        angular.forEach(uiStringsInternal, function (value, key) {
            self.fxpRootScope.fxpUIConstants.UIStrings[key] = value;
        });
    }

    private filterUIStringsForOBO(uiStrings: any): any {
        var self = this;
        var uiStringsInternal = {};

        self.fxpConfigurationService.FxpBaseConfiguration.OBOTenantUIStrings.map(function (item) {
            if (uiStrings[item] !== undefined) {
                uiStringsInternal[item] = uiStrings[item];
            }
        });
        return uiStringsInternal;
    }

    private updateHeaderLogo(headerLogo: any): void {
        var self = this;
        if (CommonUtils.isNullOrEmpty(headerLogo)) {
            self.headerLogo = self.fxpConfigurationService.FxpBaseConfiguration.FxpHeaderLogo;
        }
        else {
            self.headerLogo = headerLogo;
        }
    }

    getApplicationNameForDisplay() {
        const hasCSARoles = this.hasCSARoles();
        if (hasCSARoles) {
            return this.getAppNameForCSARoles();
        }
        return this.getAppNameForNonCSARoles();
    }

    getAppNameForCSARoles() {
        if (this.device.isTablet() || this.device.isMobile()) {
            return this.fxpConfigurationService.FxpAppSettings
                .GlobalCustomerExperienceApplicationNameForTablet;
        }
        return this.fxpConfigurationService.FxpAppSettings
            .GlobalCustomerExperienceApplicationName;
    }

    getAppNameForNonCSARoles() {
        if (this.device.isTablet() || this.device.isMobile()) {
            return this.fxpRootScope.fxpUIConstants.UIStrings.AppHeaderAlias;
        }
        return this.fxpRootScope.fxpUIConstants.UIStrings.AppHeader;
    }

    hasCSARoles(): boolean {
        const defaultAppRole =
            this.userInfoService.getCurrentUserClaims().defaultAppRole;
        const csaRoles =
            this.fxpConfigurationService.FxpAppSettings
                .AppRolesForGlobalCustomerExperienceApplicationName;
        if (
            CommonUtils.isNullOrEmpty(defaultAppRole) ||
            CommonUtils.isNullOrEmpty(csaRoles)
        )
            return false;
        return csaRoles === "*" || csaRoles.split(",").indexOf(defaultAppRole) >= 0;
    }

    /**
   * A method to navigate to landing page.
   * @method Fxp.Controllers.AppController.navigateToLandingPage
   * @example <caption> Example to use navigateToLandingPage</caption>
   *  <div ng-app="AppController"><div ng-click="navigateToLandingPage">Navigate to landing page</div></div>;
   *  <div ng-app="AppController as app"><div ng-click="app.navigateToLandingPage">Navigate to landing page</div></div>;
   */
    navigateToLandingPage = () => {
        let state = this.fxpStateService.getCurrentState();
        let landingState = this.fxpStateService.get('Dashboard');
        if (CommonUtils.isNullOrEmpty(landingState)) {
            landingState = this.fxpRootScope.defaultStateName;
        }
        this.logHeaderClickTelemetryInfo(landingState);
        return state?.current?.name === landingState
            ? this.fxpStateService.reload()
            : this.fxpStateService.go(landingState, {}, { location: "replace" });
    };

    logHeaderClickTelemetryInfo = (state: any) => {
        var self = this,
            propBag;
        propBag = self.fxpLogger.createPropertyBag();
        propBag.addToBag(
            FxpConstants.metricConstants.HeaderClickNavigatedStateName,
            state.name
        );
        propBag.addToBag(
            FxpConstants.metricConstants.HeaderClickNavigatedStateTemplateURL,
            state.templateUrl
        );
        self.fxpLogger.logEvent(
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.appCntrl.logHeaderClickTelemetryInfo`,
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.HeaderClick`,
            propBag
        );
    }

    initializeFeedback = () => {
        let self = this;
        const source_telemetry = `${this.sourceForTelemetry}.InitializeFeedback`;
        const currentUserData = self.userInfoService.getCurrentUserData();
        window["OfficeBrowserFeedback"] =
            window["OfficeBrowserFeedback"] || <any>{};
        window["OfficeBrowserFeedback"].initOptions = {
            appId: self.fxpConfigurationService.FxpAppSettings.OCVAppId, // Replace by your own app id
            stylesUrl: "lib/OCV/styles/officebrowserfeedback_fxp.css", // Replace by where you have hosted the .css
            intlUrl: "lib/OCV/intl/", // Replace by where you have hosted the intl files.
            // intlFilename is an optional property for using a custom filename for the internationalized strings, the default filename will be used if it is not specified
            environment: self.fxpConfigurationService.FxpAppSettings.OCVEnvironment, // 0 - Prod, 1 - Int
            primaryColour: "#0078d6", // Replace by a colour which goes with your website.
            secondaryColour: "#0078D4", // Replace by a colour which goes with your website.
            userEmailConsentDefault: false,
            userEmail: currentUserData.EmailName || currentUserData.email,
            build: self.fxpConfigurationService.FxpAppSettings.BuildNumber, // (optional) Another example: 99.1.1.123456789
            locale: "en",
            transitionEnabled: false,
            onError: function onError(err) {
                self.fxpLogger.logError(
                    source_telemetry,
                    "Feedback OCV SDK encountered error: " + err,
                    ErrorCodes.InitializeFeedback_Failure,
                    null,
                    null,
                    null,
                    null,
                    ErrorSeverityLevel.Low
                );
            }, // (optional) Callback which gets executed when SDK errors
            onDismiss: function dismissed(submitted) {
                self.isFeedbackDialogOpen = false;
                if (submitted) {
                    self.fxpLogger.logEvent(
                        source_telemetry,
                        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FeedbackSubmitted`
                    );
                    self.fxpMessage.addMessage(
                        "Your feedback has been submitted",
                        "success"
                    );
                } else {
                    self.fxpLogger.logError(
                        source_telemetry,
                        "Error while submitting the feedback.",
                        ErrorCodes.Error_SubmittingFeedback,
                        null
                    );
                    self.fxpMessage.addMessage(
                        "Error while submitting the feedback.",
                        "error"
                    );
                }
            },
            onCancel: function cancel() {
                $("#feedback-open").focus();
                self.isFeedbackDialogOpen = false;
            },
        };
        this.LaunchFeedback();
    }


    LaunchFeedback = () => {
        var launchOptions = {
            webGroup: {
                browser: navigator["browserDetails"]
                    ? navigator["browserDetails"].name
                    : "",
                browserVersion: navigator["browserDetails"]
                    ? navigator["browserDetails"].version
                    : "",
                sourcePageName: document.getElementsByTagName("title")[0].innerHTML,
                sourcePageURI: window.location.href,
            },
            telemetryGroup: {
                platform: navigator["deviceDetails"],
                featureArea: this.getPartnerName(this.fxpStateService.getCurrentStateName()),
                loggableUserId:
                    this.fxpGlobalStoreService.GetPlatformState().CurrentUser.Claims
                        .aadObjectId,
                tenantId: this.fxpConfigurationService.FxpAppSettings.TenantId,
            },
        };
        this.handlePromise(
            window["OfficeBrowserFeedback"].multiFeedback(launchOptions)
        );
    }

    handlePromise = (promise) => {
        let self = this;
        const source_telemetry = `${this.sourceForTelemetry}.HandlePromise`;
        const feedbackFeature = new FeatureUsageEvent(
            `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.OCVFeedback`,
            ActionType.User,
            "FeedbackComponentLoad",
            EventName.ButtonClick,
            ComponentType.Web
        );
        promise
            .then(function onFulfilled() {
                self.isFeedbackDialogOpen = true;
                feedbackFeature.ActionStatus = ActionStatus.Succeeded;
                self.fxpLogger.logFeatureUsageEvent(source_telemetry, feedbackFeature);
            })
            .catch(function onRejected(err) {
                self.fxpLogger.logError(
                    source_telemetry,
                    "Error while loading feedback UI. Error: " + err,
                    ErrorCodes.Error_LoadingFeedback,
                    null,
                    null,
                    null,
                    null,
                    ErrorSeverityLevel.Medium
                );
                self.isFeedbackDialogOpen = false;
                feedbackFeature.ActionStatus = ActionStatus.Failed;
                self.fxpLogger.logFeatureUsageEvent(source_telemetry, feedbackFeature);
            });
    }

    getPartnerName = (stateName: string): string => {
        let stateDetails = this.fxpStateService.get(stateName);
        if (!stateDetails || !stateDetails.data) {
            return "";
        }
        if (stateDetails.data.ocvAreaName) {
            return stateDetails.data.ocvAreaName;
        }
        if (stateDetails.data.partnerTelemetryName)
            return stateDetails.data.partnerTelemetryName;

        return "";
    }


    /**
   * An event handler to log Telemetry when mini profile icon is clicked.
   * @method Fxp.Controllers.AppController.logMiniProfileTelemetryInfo
   * @param {string} action action to decide whether it is profile icon click or manage profile click.
   * @param {boolean} isModalOpen flag to decide if uib dropdown is open or closed.
   */

    logMiniProfileTelemetryInfo = (action: string, isModalOpen: boolean, properties?: any) => {
        const viewProfileFeature = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ViewProfile`;
        let viewProfileFeatureUsageEvent: FeatureUsageEvent;

        let source = `${this.sourceForTelemetry}.logMiniProfileTelemetryInfo`;
        if (isModalOpen) {
            var propBag: LogPropertyBag;
            var self = this;
            propBag = self.fxpLogger.createPropertyBag();
            for (let prop in properties) {
                propBag.addToBag(prop, properties[prop]);
            }
            switch (action) {
                case "ProfileIconClick":
                    self.fxpLogger.renewCorrelationId();
                    propBag.addToBag(
                        FxpConstants.metricConstants.MiniProfileIconClick,
                        "Yes"
                    );
                    viewProfileFeatureUsageEvent = new FeatureUsageEvent(
                        viewProfileFeature,
                        ActionType.User,
                        "MiniProfileIconClicked",
                        EventName.ButtonClick,
                        ComponentType.Web
                    );
                    break;
                case "ProfileLinkClick":
                    self.fxpLogger.renewSubCorrelationId();
                    propBag.addToBag(
                        FxpConstants.metricConstants.ProfileLinkClick,
                        "Yes"
                    );
                    viewProfileFeatureUsageEvent = new FeatureUsageEvent(
                        viewProfileFeature,
                        ActionType.User,
                        "ProfileLinkClicked",
                        EventName.LinkClicked,
                        ComponentType.Web
                    );
                    break;
                case "ChatIconClick":
                    self.fxpLogger.renewSubCorrelationId();
                    propBag.addToBag(FxpConstants.metricConstants.ChatIconClick, "Yes");
                    viewProfileFeatureUsageEvent = new FeatureUsageEvent(
                        viewProfileFeature,
                        ActionType.User,
                        "ChatIconClicked",
                        EventName.ButtonClick,
                        ComponentType.Web
                    );
                    break;
                case "MailIconClick":
                    self.fxpLogger.renewSubCorrelationId();
                    propBag.addToBag(FxpConstants.metricConstants.MailIconClick, "Yes");
                    viewProfileFeatureUsageEvent = new FeatureUsageEvent(
                        viewProfileFeature,
                        ActionType.User,
                        "MailIconClicked",
                        EventName.ButtonClick,
                        ComponentType.Web
                    );
            }
            self.fxpLogger.logEvent(
                source,
                `${this.sourceForTelemetry}.MiniProfileClick`,
                propBag
            );
            self.fxpLogger.logFeatureUsageEvent(
                source,
                viewProfileFeatureUsageEvent,
                propBag
            );
        }
    }

    /**
       * An event handler whenever header is clicked.
       * @method Fxp.Controllers.AppController.renderHeaderForKeydown
       * @param {Event} $event An event object which is passed from the view.
       * @example <caption> Example to use renderHeaderForKeydown</caption>
       *  <div ng-app="AppController"><div ng-keydown="renderHeaderForKeydown">Render header</div></div>;
       *  <div ng-app="AppController as app"><div ng-keydown="app.renderHeaderForKeydown">Render header</div></div>;
       */
    renderHeaderForKeydown = ($event) => {
        var target = $($event.target);
        if ($event.key == "Tab" && $event.shiftKey) {
            target.parent().removeClass("open");
            target.attr("aria-expanded", "false");
        } else if ($event.key == "Down" && target.parent().hasClass("open")) {
            var menuItems = target.parent().find("[uib-dropdown-menu] li a");
            menuItems[0].focus();
        }
    };

    /**
   * An event handler whenever header is clicked.
   * @method Fxp.Controllers.AppController.renderHeaderForClick
   * @param {Event} $event An event object which is passed from the view.
   * @example <caption> Example to use renderHeaderForClick</caption>
   *  <div ng-app="AppController"><div ng-click="renderHeaderForClick">Render header</div></div>;
   *  <div ng-app="AppController as app"><div ng-click="app.renderHeaderForClick">Render header</div></div>;
   */
    renderHeaderForClick = ($event) => {
        $(".helpflyoutmenu ul.dropdown-menu").animate({ scrollTop: 0 }, "fast");
        var featureFlagJson = localStorage["FeatureFlags"];
        var featureflags = {};
        var isDefaultLinkEnabled = true;
        if (featureFlagJson) {
            featureflags = JSON.parse(featureFlagJson);
            for (let i = 0; i < this.profileFlyoutConfig.length; i++) {
                const experience = this.profileFlyoutConfig[i];
                const featureFlag = experience.featureFlag;
                experience.link = experience.link.replace("{userAlias}", this.fxpRootScope?.userProfile?.alias?.toLowerCase());
                this.profileFlyoutConfig[i].link = experience.link.replace("{userId}", this.fxpRootScope?.userProfile?.aadObjectId);
                if (featureFlag) {
                    this.profileFlyoutConfig[i].isEnabled = featureflags[featureFlag] == undefined ? false : featureflags[featureFlag];
                    if (experience.experience == "r360wbExperience") {
                        this.manageProfileFlight = this.profileFlyoutConfig[i].isEnabled;
                    }
                    if (this.profileFlyoutConfig[i].isEnabled) {
                        isDefaultLinkEnabled = false;
                        break;
                    }
                }
            }
            if (isDefaultLinkEnabled) {
                this.profileFlyoutConfig[this.profileFlyoutConfig.length - 1].isEnabled = true;
            }
        } else {
            const experience = this.profileFlyoutConfig[this.profileFlyoutConfig.length - 1];
            experience.link = experience.link.replace("{userAlias}", this.fxpRootScope?.userProfile?.alias?.toLowerCase());
            this.profileFlyoutConfig[this.profileFlyoutConfig.length - 1].link = experience.link.replace("{userId}", this.fxpRootScope?.userProfile?.aadObjectId);
            this.profileFlyoutConfig[this.profileFlyoutConfig.length - 1].isEnabled = true;
        }
    };
    /**
       * An event handler whenever focus is lost from header.
       * @method Fxp.Controllers.AppController.renderHeaderForFocusout
       * @param {Event} $event An event object which is passed from the view.
       * @example <caption> Example to use renderHeaderForFocusout</caption>
       *  <div ng-app="AppController"><div ng-blur="renderHeaderForFocusout">Focus out header</div></div>;
       *  <div ng-app="AppController as app"><div ng-blur="app.renderHeaderForFocusout">Focus out header</div></div>;
       */
    renderHeaderForFocusout = function ($event) {
        var target = $($event.target);
        target.removeClass("focus-removed");
    };

    copyToClipboard() {
        let copyText: string = "";
        for (var experience of this.profileFlyoutConfig) {
            if (experience.isEnabled) {
                if (experience.link.includes("engage360")) { //TODO: add to application constants
                    copyText = experience.link;

                }
                else {
                    const esxpBaseURLindex = document.baseURI.indexOf("#");
                    copyText = document.baseURI.slice(0, esxpBaseURLindex) + experience.link;
                }
                break;
            }
        }
        navigator.clipboard.writeText(copyText).then(
            function () {
                document.getElementById("copytooltip").innerHTML = "Copied!";
                document.getElementById("copyicon").blur;
            },
            function () {
                document.getElementById("copytooltip").innerHTML = "Could not copy";
            }
        );
        document.getElementById("copyicon").onblur = () =>
            (document.getElementById("copytooltip").innerHTML = "Copy profile URL");
        document.getElementById("copyicon").onmouseup = () =>
            (document.getElementById("copytooltip").innerHTML = "Copy profile URL");
    }

    maximize() {
        this.rootScopeService.setRootScopeField("isFullScreen", true);
    }

    appToggleOCV() {
        return window["fxpOcvLoaded"] && window["fxpOcvFloodGateLoaded"];
    }

    isDeviceMobile() {
        return !this.device.isMobile();
    }

    renderHeaderMenuForKeydown(event: KeyboardEvent) {
        var targetMenu = $(event.target).closest("[uib-dropdown-menu]"),
            targetMenuToggleBtnId = targetMenu.attr("aria-labelledby"),
            targetMenuToggleBtn = $("#" + targetMenuToggleBtnId),
            allMenuItems = targetMenu.find("li a"),
            currentMenuItemIndex = allMenuItems.index($(event.target));

        if (event.keyCode == FxpConstants.keyCodes.escapeKey) {
            targetMenuToggleBtn.focus();
            if (targetMenuToggleBtnId.indexOf("Fxpdashboard_LeftNavItem") != -1) {
                targetMenu.parent().removeClass("open");
                targetMenuToggleBtn.attr("aria-expanded", "false");
            }
        } else if (
            event.keyCode == FxpConstants.keyCodes.tabKey &&
            !event.shiftKey &&
            currentMenuItemIndex == allMenuItems.length - 1
        ) {
            targetMenuToggleBtn.focus();
            targetMenu.parent().removeClass("open");
            targetMenuToggleBtn.attr("aria-expanded", "false");
        } else if (
            event.keyCode == FxpConstants.keyCodes.tabKey &&
            event.shiftKey &&
            currentMenuItemIndex == 0
        ) {
            event.preventDefault();
            event.stopPropagation();
            targetMenuToggleBtn.focus();
        } else if (event.keyCode == FxpConstants.keyCodes.arrowDownKey) {
            event.preventDefault();
            event.stopPropagation();
            if (currentMenuItemIndex < allMenuItems.length - 1) {
                allMenuItems[currentMenuItemIndex + 1].focus();
            } else {
                allMenuItems[0].focus();
            }
        } else if (event.keyCode == FxpConstants.keyCodes.arrowUpKey) {
            event.preventDefault();
            event.stopPropagation();
            if (currentMenuItemIndex > 0) {
                allMenuItems[currentMenuItemIndex - 1].focus();
            } else {
                allMenuItems[allMenuItems.length - 1].focus();
            }
        }
    }

    closeActOnBehalfOfUserClick(event: Event) {
        var self = this;
        this.rootScopeService.setRootScopeField('actOnBehalfOfUserActive', false);

        if (window['_showPageDirtyPopup']) {
            if (!confirm(self.fxpRootScope.fxpUIConstants.UIMessages.PageDirtyMessages.UnsavedChangesMessage)) {
                event.preventDefault();
                return;
            }
            else {
                window['_showPageDirtyPopup'] = false;
            }
        }

        const source_telemetry = `${this.sourceForTelemetry}.CloseActOnBehalfOfUserClick`;
        var propbag = self.fxpLogger.createPropertyBag();

        try {
            self.fxpLogger.renewCorrelationId();
            self.fxpLogger.logTrace('Fxp.closeactOnBehalfofuser', "close actOnBehalfOfUser Started");
            self.userInfoService.setCurrentUserOID(self.userInfoService.getLoggedInUserOID());
            self.userProfileService.setCurrentUser(self.userInfoService.getLoggedInUser());
            self.OBOUserService.setCurrentUserStateFromLoggedInUserState();

            self.OBOUserService.removeOBOUserContext();
            //when obo user session closed
            this.rootScopeService.broadcast(FxpBroadcastedEvents.OnUserContextChanged, self.userInfoService.getCurrentUser(), self.userInfoService.getCurrentUserData().roleGroupId, self.userInfoService.getCurrentUserData().businessRoleId);
            this.rootScopeService.broadcast(FxpBroadcastedEvents.ActOnBehalfClosed, self.userInfoService.getCurrentUser(), self.userInfoService.getCurrentUserData().roleGroupId, self.userInfoService.getCurrentUserData().businessRoleId);
            //Telemetry
            var oboUserEndTime = new Date().getTime().toString();
            propbag.addToBag(FxpConstants.OBOConstants.ActonBehalfofAdminEndTime, oboUserEndTime);
            var startTime = localStorage.getItem(FxpConstants.OBOConstants.ActonBehalfofAdminStartTime.toString());
            var responseTime = (parseInt(oboUserEndTime) - parseInt(startTime));
            var oboUserMetric = self.fxpLogger.createMetricBag();
            oboUserMetric.addToBag(FxpConstants.OBOConstants.AdminActOnBehaflOfDuratoin, responseTime);
            self.fxpLogger.logEvent(source_telemetry, 'Closing of actOnBehalfOfUser completed.', propbag, oboUserMetric);
            //Remove global properties
            self.fxpTelemetryContext.removeFromGlobalPropertyBag(FxpConstants.OBOConstants.OnBehalfOfUserUpn);
            self.fxpTelemetryContext.removeFromGlobalPropertyBag(FxpConstants.OBOConstants.ActonBehalfMode);
            self.fxpTelemetryContext.removeFromGlobalPropertyBag(FxpConstants.OBOConstants.OnBehalfOfUserBusinessRoleId);
            self.fxpTelemetryContext.removeFromGlobalPropertyBag(FxpConstants.OBOConstants.OnBehalfOfUserBusinessRole);
            self.fxpLogger.setOBOUserContext(null, null, self.userProfileService.isObo(), null, null);
            self.fxpLogger.logTrace(source_telemetry, "closeactOnBehalfofuser end");
            if (self.fxpRootScope.initialFlags.feedbackEnabled)
                self.fxpFeedbackService.setUserDetailsToFeedback();
        }
        catch (e) {
            propbag.addToBag("OBOCloseError", e);
            self.fxpMessage.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.GeneralExceptionError.ErrorMessage, FxpConstants.messageType.error);
            self.fxpLogger.logError(source_telemetry,
                self.fxpRootScope.fxpUIConstants.UIMessages.GeneralExceptionError.ErrorMessage,
                ErrorCodes.CloseActOnBehalofUser_Failure,
                null,
                propbag,
                null,
                null,
                ErrorSeverityLevel.Medium
            );
        }

    }
}
