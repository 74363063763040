import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ILogger } from "../interfaces/ILogger";
import { FxpConfigurationService } from "./FxpConfiguration";
import { FxpAnnouncementModalConstant } from "../constants/FxpModal.constants";
import { FxpGlobalStoreService } from "./fxp.global.store.service";
import { IRootScope } from "../interfaces/IRootScope";
import { UserFeedbackUIService } from "./UserFeedbackUIService";
import { FxpUIBModalService } from "./FxpUIBModalService";

export class UserAnnouncementUIService {
	private readonly sourceForTelemetry: string = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.UserAnnouncementUIService`; 
  
	constructor(private modalService: FxpUIBModalService,
        private fxpLogger: ILogger, private fxpConfiguration: FxpConfigurationService, private fxpGlobalStoreService: FxpGlobalStoreService,private $rootScope: IRootScope, private userFeedbackUIService: UserFeedbackUIService){
    }
	
  private showAnnouncement(content: string, callback: any){
    const modalProps: any = FxpAnnouncementModalConstant.options;
    const self = this;
    const source_telemetry = `${this.sourceForTelemetry}.showAnnouncement`;
    modalProps.controller = function ($scope) {
      this.Content = content;

      this.onCancel = _ => {           
        self.modalService.dismiss();
        self.$rootScope.IsModelOpen = false;
        setTimeout(function () {
          self.fxpLogger.logEvent(source_telemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AnnouncementPopupClosed`);  
          self.userFeedbackUIService.collectGCEFeedback();
        }, self.fxpConfiguration.FxpAppSettings.DelayFeedbackPoupInSeconds * 1000);
      };
    };
    this.modalService.open(modalProps);
    localStorage["AnnouncementShown"] = true;
  }

    public showUserAnnouncement(){
      let self = this;
      const source_telemetry = `${this.sourceForTelemetry}.showUserAnnouncement`;
      let showGCEAnnouncementDialog = self.fxpGlobalStoreService.GetPlatformState().FeatureFlags[this.fxpConfiguration.FxpAppSettings.ShowSystemAnnouncementFeatureFlagName];
			let GCEFeedbackDialogFeatureFlag = self.fxpGlobalStoreService.GetPlatformState().FeatureFlags[this.fxpConfiguration.FxpAppSettings.GCERingReleaseFeatureFlagName];
			let isAnnouncementShown = localStorage["AnnouncementShown"] === "true" ? true : false;
			setTimeout(function () {
				if (showGCEAnnouncementDialog && GCEFeedbackDialogFeatureFlag && !isAnnouncementShown && !self.$rootScope.IsModelOpen) {
					self.showAnnouncement(self.fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.AnnouncementPopupMessages.ContentMessage, {});
          self.$rootScope.IsModelOpen = true;
          self.fxpLogger.logEvent(source_telemetry, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.AnnouncementPopupOpened`);  
        }
			}, 2000);
    }
}