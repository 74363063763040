export enum FxpTelemActionStatus {
    Succeeded = "Succeeded",
    Failed = "Failed",
    NA = "NA"
}
export enum FxpTelemActionType {
    User = "User",
    System = "System",
    Aware = "Aware"
}
export enum FxpTelemEventName {
    PageLoad = "PageLoad",
    DropdownSelected = "DropdownSelected",
    DropdownChange = "DropdownChange",
    PageNavigation = "PageNavigation",
    CheckboxChange = "CheckboxChange",
    ListChange = "ListChange",
    ButtonClick = "ButtonClick",
    TabChange = "TabChange",
    TabNavigation = "TabNavigation",
    PanelOpened = "PanelOpened",
    PanelClosed = "PanelClosed",
    PickerSelected = "PickerSelected",
    PickerDeselected = "PickerDeselected",
    TileAdded = "TileAdded",
    TileClicked = "TileClicked",
    TileDeleted = "TileDeleted",
    TileLoaded = "TileLoaded",
    OnFocus = "OnFocus",
    OnBlur = "OnBlur",
    TextChange = "TextChange",
    Scroll = "Scroll",
    SectionRendering = "SectionRendering",
    BackEndAPICall = "BackEndAPICall",
    Rendering = "Rendering",
    NA = "NA",
    Hover = "Hover",
    LinkClicked = "LinkClicked"
}