import { FxpConfigurationService } from "../services/FxpConfiguration";
import { ILogger } from "../interfaces/ILogger";
import { SessionTimeoutModalConstant } from "../constants/SessionTimeoutModal.constants";
import { FxpConstants } from "../common/ApplicationConstants";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { IRootScope } from "../interfaces/IRootScope";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { FxpUIBModalService } from "../services/FxpUIBModalService";

/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */


/**
   * A facroey to display Modal
   * @class FxpModalService
   * @classdesc A service to display popup modal
   * @example <caption> Example to create an instance of sessionTimeoutmodalfactory</caption>
   *  //Initializing sessionTimeOutModalFactory
   *  angular.module('FxPApp').controller('AppController', ['SessionTimeoutModalFactory', AppController]);
   *  function AppController(fxpModalService, fxpConstants){ SessionTimeoutModalFactory.showSessionTimeoutModal(); }
   */
export class SessionTimeoutModalFactory {

	constructor(
		private modalService: FxpUIBModalService,
		private fxpConfigurationService: FxpConfigurationService,

		private fxplogger: ILogger,
		private SessionTimeoutModalConstant: SessionTimeoutModalConstant,
		private ActivityMonitor: any,
		private $rootScope: IRootScope) {
		
	}

	/**
   * Method to show SessionTimeOut Modal.
   * @method Fxp.Factory.SessionTimeoutModalFactory.showSessionTimeoutModal
   * @example <caption> Example to use showSessionTimeoutModal</caption>
   * sessionTimeoutModalFactory.showSessionTimeoutModal();
   */

	showSessionTimeoutModal() {

		let self = this;
		let options = SessionTimeoutModalConstant.options;
		self.modalService.open(options);
	}

	init() {
		let self = this;
		self.ActivityMonitor.options.inactive = this.fxpConfigurationService.FxpAppSettings.SessionTimeoutDurationInSeconds;
		self.ActivityMonitor.on('inactive', function () {
			self.fxplogger.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SessionTimeoutModalFactory.init`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SessionTimedOut`);

			sessionStorage.clear();

			self.showSessionTimeoutModal();
			self.$rootScope.$broadcast(FxpBroadcastedEvents.OnUserSessionTimeout);
		});

	}

	static getUIDataFactoryObj(modalService: FxpUIBModalService,
		fxpConfigurationService: FxpConfigurationService,

		fxpLoggerService: ILogger,
		SessionTimeoutModalConstant: SessionTimeoutModalConstant,
		ActivityMonitor: any,
		$rootScope: IRootScope) {
		return new SessionTimeoutModalFactory(modalService, fxpConfigurationService, fxpLoggerService, SessionTimeoutModalConstant, ActivityMonitor, $rootScope);
	}
}
