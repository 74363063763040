import { ISettingsServiceConfig } from "../interfaces/ISettingsServiceConfig";
import { ISettingsService } from "../interfaces/ISettingsService";
import { ISettingsServiceProvider } from "../interfaces/ISettingsServiceProvider";
import { SettingsType } from "../common/SettingsType";
import { IFxPService } from "../interfaces/IFxpService";
import { MsalAuthenticationService } from "../services/MsalAuthenticationService";
import { FxpHttpClientService } from "../services/FxpHttpClientService";
import * as Q from 'q';

declare var deferred: any;

export class SettingsServiceProvider implements ISettingsServiceProvider, ISettingsService, IFxPService {
	init() {
		//throw new Error("Method not implemented.");
	}
	addState(stateName: any, config: any): void {
		//throw new Error("Method not implemented.");
	}
	otherwise(input: any): void {
		//throw new Error("Method not implemented.");
	}
	private settingsServiceConfig: ISettingsServiceConfig = { settingsServiceBaseUrl: "" };
	private iReqCount: number;
	private sleepInterval: number;
	private http: FxpHttpClientService;
	private msalAuthenticationService: MsalAuthenticationService;
	private timeout: angular.ITimeoutService;

	public configure(settingsSeerviceConfig: ISettingsServiceConfig) {
		angular.extend(this.settingsServiceConfig, settingsSeerviceConfig);
	}
	constructor() {
		this.$get.$inject = ['FxpHttpClientService', 'MsalAuthenticationService'];
	}

	$get(http: FxpHttpClientService,
		msalAuthenticationService: MsalAuthenticationService,
		): ISettingsService {
		this.http = http;
		this.msalAuthenticationService = msalAuthenticationService;
		this.iReqCount = 0;
		this.sleepInterval = 100;
		var settingsService: ISettingsService = {
			getSettings: (settingType: SettingsType, settingId: string, settingNames: string[]): Q.Promise<string> => this.getSettings(settingType, settingId, settingNames),
			saveSettings: (settingType: SettingsType, settingId: string, settingName: string, settingValue: string): Q.Promise<boolean> => this.saveSettings(settingType, settingId, settingName, settingValue)
		};
		return settingsService;
	}

	getSettings(settingType: SettingsType, settingId: string, settingNames: string[]): Q.Promise<string> {
		var pathUrl = this.getPathUrl(settingType, settingId);
		var url = this.settingsServiceConfig.settingsServiceBaseUrl + pathUrl + '?';
		if (typeof settingNames == 'string') {
			url += 'settingNames=' + settingNames;
		}
		else {
			if (settingNames instanceof Array) {
				settingNames.forEach((setting: string, index: number) => {
					url += index > 0 ?
						'&settingNames=' + setting :
						'settingNames=' + setting;
				});
			}
		}

		return Q(this.getSettingsSvc(url));
	}

	saveSettings(settingType: SettingsType, settingId: string, settingName: string, settingValue: string): Q.Promise<boolean> {
		var pathUrl = this.getPathUrl(settingType, settingId);
		var url = this.settingsServiceConfig.settingsServiceBaseUrl + pathUrl;
		return Q(this.saveSettingsSvc(url, { settingName: settingName, settingValue: settingValue }));
	}

	private getSettingsSvc(url: string): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				return deferred.promise;
			}

			setTimeout(function () {
				self.getSettingsSvc(url);
			}, self.sleepInterval);
		}
		else {
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				self.http.get(url)
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}

		return deferred.promise;
	}

	private saveSettingsSvc(url: string, value: any): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				return deferred.promise;
			}

			setTimeout(function () {
				self.saveSettingsSvc(url, value);
			}, self.sleepInterval);
		}
		else {
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				self.http.post(url, value, { headers: { 'Content-Type': 'application/json' } })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}

		return deferred.promise;
	}

	private getPathUrl(settingType: SettingsType, settingId: string): string {
		var settingTypeValue = SettingsType[settingType];
		var pathUrl = '/' + settingTypeValue + '/' + settingId + '/settings';
		return pathUrl;
	}
}