
import { ApplicationConstants } from "../common/ApplicationConstants";
import { ILogger } from "../interfaces/ILogger";
import { FxpLogHelper } from "../telemetry/FxpLogHelper";
import { TelemetryConstants } from "../telemetry/TelemetryConst";


export class SessionTimeoutModalController {
	constructor(
		private fxplogger: ILogger,

	) { }
	public reLaunch() {
		const self = this;
		self.fxplogger.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SessionTimeoutModalController.reLaunch`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SessionTimeoutModal.RelaunchButtonClicked`);
		const sessionId = FxpLogHelper.getNewSessionId();  /* CodeQL [SM01527] It is used only for tracking */
    window.sessionStorage.setItem(ApplicationConstants.FxpSessionKey, sessionId);
		document.location.reload(true);
	}
}
