/*
    fxpIncludeStaticTemplate is an alternate to ng-include.
    But unlike ng-include it won't create a new scope while fetching the template.

    e.g.
    Usage <section fxp-include-static-template="path/to/static/template.html"> </section>
*/

export class FxpIncludeStaticTemplate implements angular.IDirective {
	scope = false;
	
    constructor(private $templateCache:any){
    }
    
    template = (element: JQuery, attrs) => {
        return this.$templateCache.get(attrs.fxpIncludeStaticTemplate);
    } 
}
