import { IRootScope } from "../interfaces/IRootScope";
import { ILogger } from "../interfaces/ILogger";
import { FxpConstants, ApplicationConstants, PerfMarkers, CustomEvents } from "../common/ApplicationConstants";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { FxpLogHelper } from "../telemetry/FxpLogHelper";
import { FxpConfigurationService } from "./FxpConfiguration";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { UserInfoService } from "./UserInfoService";
import { UserProfileService } from "./userProfileService";
import { FeatureFlagService } from "@fxp/flightingsdk";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpGlobalStoreService } from "./fxp.global.store.service";
import { setLeftnav } from "../../app/leftNavigation/leftNavigation.action";
import { ILeftNavigationGroup } from "../../app/leftNavigation/leftNavigation.model";
import { HideLoader } from "../../app/loader/loader.actions";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { UpsertFeatureFlags } from "../../app/flighting/featureFlags.actions";
import { MsalAuthenticationService } from "./MsalAuthenticationService";
import { FxpHttpClientService } from "./FxpHttpClientService";
import * as Q from 'q';
import { FxpRootScopeService, IRootSubjects } from "./FxpRootScopeService";
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */
/**
  * A service to connect to dashboard service to fetch the leftnav data of the user
  * @class Fxp.Services.DashboardService
  * @classdesc A service to connect to dashaboard service to fetch the leftnav data 
  * @example <caption> Example to create an instance of dashboard service</caption>         
  *  //Initializing DashboardService
  *  angular.module('FxPApp').controller('LeftNavController', ['DashboardService', LeftNavController]);
  *  function LeftNavController(dashboardService){ dashboardService.getLeftNavData(userAlias,roleGroupId); }
  */
declare type IStateService = any;

export class DashboardService implements IFxPService {
	private http: FxpHttpClientService;
	private rootScope: IRootScope;
	private rootScopeService: FxpRootScopeService;
	private fxpRootScope: IRootSubjects;
	private fxpConfiguration: FxpConfigurationService;
	private sleepInterval: number;
	private fxplogger: ILogger;
	private userAlias: string;
	private fxpMessageSvc: FxpMessageService;
	private userInfoService: UserInfoService;
	private fxpConstants: FxpConstants;
	private fxpTelemetryContext: TelemetryContext;
	private userProfileService: UserProfileService;
	private iCount: number = 0;
	private iReqCount: number = 0;
	private iUCount: number = 0;
	private fxpServiceEndPoint: string;
	private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.DashboardService`;
	private static _instance: DashboardService;
	private pageTourWaitTime: number = 2000;
	private msalAuthenticationService: MsalAuthenticationService;

	private static featureFlagPromise: Q.Promise<any>;

	constructor(http: FxpHttpClientService,
		$rootScope: IRootScope,
		fxpConfiguration: FxpConfigurationService,
		loggerService: ILogger,
		msalAuthenticationService: MsalAuthenticationService,
		fxpMessage: FxpMessageService,
		userInfoService: UserInfoService,
		fxpTelemetryContext: TelemetryContext,
		userProfileService: UserProfileService,
		private featureFlagService: FeatureFlagService,
		private startUpFlightConfig: any,
		private globalStoreService: FxpGlobalStoreService
	) {
		this.http = http;
		this.rootScope = $rootScope;
		this.rootScopeService = FxpRootScopeService.getInstance();
		this.rootScopeService.rootScopeSubject.subscribe((data) => {
			this.fxpRootScope = data;
		});
		this.sleepInterval = 100;
		this.fxplogger = loggerService;
		this.fxpMessageSvc = fxpMessage;
		this.fxpTelemetryContext = fxpTelemetryContext;
		this.fxpConfiguration = fxpConfiguration;
		this.userInfoService = userInfoService;
		this.userProfileService = userProfileService;
		this.fxpServiceEndPoint = this.fxpConfiguration.FxpAppSettings.FxpServiceEndPoint;
		this.msalAuthenticationService = msalAuthenticationService;
		if (DashboardService._instance) {
			return DashboardService._instance;
		}
		DashboardService._instance = this;
	}
	/**
* Get the Leftnavigation data of the user
* @method Fxp.Services.DashboardService.getLeftNavData
* @param {tenantId } tenantId of the user.
* @param {userRoles } userRoles of the user.
* @example <caption> Example to invoke getLeftNavData</caption>
*  DashboardService.getLeftNavData(tenantId,userRoles);
*/
	getLeftNavData(tenantId: string, userProfile: string): Q.Promise<any> {
		var deferred = Q.defer();
		const telemetry_source = `${this.sourceForTelemetry}.GetLeftNavData`;
		var url = this.fxpServiceEndPoint + "/navigation";
		var self = this;
		let currentUser = this.userInfoService.getCurrentUser();
		// if current alias is undefined
		const leftNavDataKey = currentUser ? "LeftNavData-" + currentUser.toLowerCase() : "LeftNavData-" + this.userInfoService.getCurrentUserOID();
		let leftNavData = localStorage.getItem(leftNavDataKey);
		if (leftNavData && leftNavData.length > 0) {
			try {
				self.globalStoreService.GetPlatformStore().dispatch(setLeftnav(JSON.parse(leftNavData)));
				let timeoutHandle = setTimeout(function () {
					leftNavData = JSON.parse(leftNavData);
					deferred.notify(leftNavData);
					clearTimeout(timeoutHandle);
				}, 0);
			}
			catch (e) {
				//ingore. 
			}
		}
		this.fxplogger.startTrackPerformance(PerfMarkers.GetLeftNavData);
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.GetLeftNavAuthFailureError.ErrorMessageTitle, ErrorCodes.DashboardService_GetLeftNavDataFailure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.GetLeftNavAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getLeftNavData(tenantId, userProfile);
			}, self.sleepInterval);
		}
		else {
			self.iReqCount = 0;
			let requestHeaders = {};
			requestHeaders[ApplicationConstants.XTenantId] = tenantId;
			requestHeaders[ApplicationConstants.XUserProfileToken] = userProfile;
			this.http.get(url,
				requestHeaders
			).then(function (responseData) {
				localStorage[leftNavDataKey] = JSON.stringify(responseData.data);
				let data: ILeftNavigationGroup = JSON.parse(JSON.stringify(responseData.data));
				self.globalStoreService.GetPlatformStore().dispatch(setLeftnav(data));
				deferred.resolve(responseData);
				if (responseData.headers != undefined) {
					var properties = self.fxplogger.createPropertyBag();
					properties.addToBag("Flighting-Call-Status", responseData.headers(ApplicationConstants.FlightingStatusHeader));
				}
			}).catch(function (err) {
				deferred.reject(err);
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetLeftNavData);
		return deferred.promise;
	}

	getCachedLeftNavForCurrentUser() {
		let currentUser = this.userInfoService.getCurrentUser();
		const leftNavDataKey = currentUser ? "LeftNavData-" + currentUser.toLowerCase() : "LeftNavData-" + this.userInfoService.getCurrentUserOID();

		let leftNavData = JSON.parse(localStorage.getItem(leftNavDataKey));
		this.globalStoreService.GetPlatformStore().dispatch(setLeftnav(leftNavData));
		if (leftNavData && leftNavData.internalLinks.length > 0) {
			return this.getGLNFlatDataStructure(leftNavData.internalLinks);
		}
		return null;
	}

	/**
	* Get the flat structure of Leftnavigation data
	* @method Fxp.Services.DashboardService.getGLNFlatDataStructure
	* @param {leftnavData } leftNavData leftnavigation data of the user.
	* @example <caption> Example to invoke getGLNFlatDataStructure</caption>
	*  DashboardService.getGLNFlatDataStructure();
	*/
	getGLNFlatDataStructure(leftNavData: any) {
		var list = new Array();
		for (var i = 0, L0length = leftNavData.length; i < L0length; i++) {
			if (leftNavData[i].hasChildren) {
				for (var j = 0, L1length = leftNavData[i].children.length; j < L1length; j++) {
					list.push(leftNavData[i].children[j]);
				}
			}
			else {
				list.push(leftNavData[i]);
			}
		}
		return list;
	}

	/**
	* Logging Telemetry Info and Display Error message when FxpUIConfiguration Fetching is Failed by RoleGroupId
	* @method Fxp.Services.DashboardService.fxpConfigurationFailed
	* @example <caption> Example to invoke fxpConfigurationFailed</caption>
	*  DashboardService.fxpConfigurationFailed();
	*/
	fxpConfigurationFailed(): void {
		var self = this;
		var userData = self.globalStoreService.GetPlatformState().CurrentUser.Profile;
		var propbag = self.fxplogger.createPropertyBag();
		propbag.addToBag(FxpConstants.metricConstants.SessionId, self.fxpRootScope.sessionId);
		propbag.addToBag(FxpConstants.metricConstants.TimeStamp, FxpLogHelper.getTimeStamp());
		propbag.addToBag(FxpConstants.metricConstants.UserUPN, self.userInfoService.getCurrentUser());
		propbag.addToBag(FxpConstants.metricConstants.UserBusinessRole, userData.businessRole);
		propbag.addToBag(FxpConstants.metricConstants.UserRoleGroup, userData.roleGroupName);
		propbag.addToBag(FxpConstants.metricConstants.UserAgent, navigator.userAgent);
		propbag.addToBag(FxpConstants.metricConstants.Geography, self.fxpTelemetryContext.getGeography());
		self.fxplogger.logError(`${self.sourceForTelemetry}.FxpConfigurationFailed`, self.fxpRootScope.fxpUIConstants.UIMessages.UserRoleGroupFxpConfigNotFoundError.ErrorMessageTitle, ErrorCodes.FxPConfigurationFailure, "", propbag, null, null, ErrorSeverityLevel.High);
		self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.UserRoleGroupFxpConfigNotFoundError.ErrorMessage, FxpConstants.messageType.error);
		self.globalStoreService.DispatchGlobalAction("Platform", HideLoader({}));
	}

	getInitialFeatureFlagsForPlatform(): Q.Promise<any> {
		if (DashboardService.featureFlagPromise) return DashboardService.featureFlagPromise;

		var self = this;
		var context = {};
		var eventNames = CustomEvents;
		var config = self.startUpFlightConfig;

		var deferred = Q.defer();
		self.userProfileService.getLoggedInUserFlighitngContext().then((flightingContext) => {
			DashboardService.featureFlagPromise = Q(self.featureFlagService
				.getFeatureFlags(config.appName, config.envName, config.featureNames, flightingContext))
				.then(function (response) {
					response = self.fxpConfiguration?.FxpAppSettings?.InitialFeatureFlags ?
						{ ...JSON.parse(self.fxpConfiguration?.FxpAppSettings?.InitialFeatureFlags), ...response } :
						response;

					self.globalStoreService.DispatchGlobalAction("Platform", UpsertFeatureFlags(response));
					localStorage["FeatureFlags"] = JSON.stringify(response);
					let pageTourEnabled = response.pageTourEnabled;
					let initialFlags: any = response;
					initialFlags.pageTourEnabled = false;
					self.rootScopeService.setRootScopeField("initialFlags", initialFlags);

					// Remove timeout once pagetour api is optimized.
					setTimeout(function () {
						let initialFlags = self.fxpRootScope.initialFlags;
						initialFlags.pageTourEnabled = pageTourEnabled;
						self.rootScopeService.setRootScopeField("initialFlags", initialFlags);


						self.rootScopeService.broadcast(eventNames.PageTourFlagRetrieved);
						self.rootScope.$emit(eventNames.PageTourFlagRetrieved);
					}, self.pageTourWaitTime);

					self.rootScopeService.broadcast(eventNames.StartUpFlagRetrieved);
					DashboardService.featureFlagPromise = null;
					deferred.resolve();
				}, function (error) {
					self.fxplogger.logException(`${self.sourceForTelemetry}.GetInitialFeatureFlagsForPlatform`, error, null, null, null, ErrorSeverityLevel.High, ErrorCodes.StartUpFlagRetrieveError);
					self.rootScopeService.broadcast(eventNames.StartUpFlagRetrieved);
					DashboardService.featureFlagPromise = null;
					deferred.reject(error);
				});
		}, function (error) {
			deferred.reject(error);
		});
		return deferred.promise;

	}
}
