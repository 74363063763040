export class UI_LOADER_NAMES {
    

    /**
     * The Loader names are meant have to follow this convention
     * <appname>-<ControllerName>-<optional: methodName/loaderNumber>-loader
     */
    public static FXP_LOADERS = { 
        ADMIN_LANDING_LOADER: `fxp-AdminLanding-loader`,
        ACT_ON_BEHALF_OF_LOADER: `fxp-ActOnBehalfOf-loader`,
        AUTHOR_NOTIFICATION_LOADER: `fxp-AuthorNotification-loader`,
        AUTHOR_NOTIFICATION_PUBLISH_NOTIFICATION_LOADER: `fxp-AuthorNotification-PublishNotification-loader`,
        LEFT_NAV_PERSONALIZATION_LOADER: `fxp-LeftNavPersonalization-loader`,
        ROLE_NAV_PERSONALIZATION_LOADER: `fxp-RoleNavPersonalization-loader`,
        SYSTEM_MESSAGE_POPULATE_MESSAGE_LOADER: `fxp-SystemMessage-populate-loader`,
        SYSTEM_MESSAGE_ADD_MESSAGE_LOADER: `fxp-SystemMessage-addSystemMessage-loader`,
        SYSTEM_MESSAGE_UPDATE_MESSAGE_LOADER: `fxp-SystemMessage-updateSystemMessage-loader`,
        SYSTEM_MESSAGE_DELETE_MESSAGE_LOADER: `fxp-SystemMessage-deleteSystemMessage-loader`,
        FXP_BOOT_LOADER: `fxp-FxpBoot-Loader`
    }

}