import { UserInfoService } from './UserInfoService'
import { IFxPService } from "../interfaces/IFxpService";
import { FxpConfigurationService } from "./FxpConfiguration";
import { INotificationService } from "../interfaces/INotificationService";
import { FxpHttpClientService } from './FxpHttpClientService';
import * as Q from 'q';
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */
/**
	* A service to handle service call for notifications
	* @class Fxp.Services.NotificationService
	* @classdesc A service to handle service calls for notifications
	* @example <caption> Example to create an instance of NotificationService</caption>
	*  //Initializing NotificationService
	*  angular.module('FxPApp').controller('AppController', ['NotificationService', AppController]);
	*  function AppController(notificationService, fxpConstants){ notificationService.getUnreadNotificationCount(); }
	*/
export class NotificationService implements INotificationService, IFxPService {
	private notificationApiBaseUrl: string;
	private fxpServiceEndPoint: string;
	private notificationApiWebNotifResourceUrl: string = "/api/webnotifications";
	
	constructor(
		private http: FxpHttpClientService,
		private fxpConfiguration: FxpConfigurationService,
		private userInfoService: UserInfoService
		) {
		this.notificationApiBaseUrl = fxpConfiguration.FxpAppSettings.NotificationServiceEndpoint;
		this.fxpServiceEndPoint = fxpConfiguration.FxpAppSettings.FxpServiceEndPoint;
		this.notificationApiWebNotifResourceUrl = this.notificationApiBaseUrl + this.notificationApiWebNotifResourceUrl;
	    
	}

	/**
	* Get notifications
	* @method Fxp.Services.notificationService.getNotifications
	* @param {number} startIndex mandatory number value which contains index after which notification is to be fetched.
	* @param {number} count mandatory number value which contains number of notification to be fetched.
	* @param {string} status optional string value which contains status of notifications to be fetched.
	* @param {number} offset optional number value which contains id of last notification fetched.
	* @example <caption> Example to invoke getNotifications</caption>
	*  notificationService.getNotifications(startIndex, count);
	*/
	getNotifications(startIndex, count, status?, offset?, user?, channel?): Q.Promise<any> {
		user = user ? user : this.userInfoService.getCurrentUserUpn();
		channel = channel ? channel : this.fxpConfiguration.FxpAppSettings["NotificationServiceChannel"];
		var urlGetNotiicationsByPage = this.notificationApiWebNotifResourceUrl + "?channel=" + channel + "&userId=" + user + "&skip=" + startIndex + "&take=" + count;
		if (status) {
			urlGetNotiicationsByPage += "&status=" + status;
		}
		if (offset) {
			urlGetNotiicationsByPage += "&filter=notification.PublishedOnTicks >" + offset;
		}
		urlGetNotiicationsByPage += "&Order=notification.PublishedOnTicks DESC";
		var requestConfig: any = {};

		if (this.fxpConfiguration.FxpAppSettings["NotificationServiceAllowNullHeader"]) {
			requestConfig = {
				headers: {}
			};
			requestConfig.headers[this.fxpConfiguration.FxpAppSettings["NotificationServiceAllowNullHeader"]] = true;
		}
		return Q.Promise((resolve, reject) => {
			this.http.get(urlGetNotiicationsByPage, requestConfig)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});  
	}

	/**
	* Delete notification
	* @method Fxp.Services.notificationService.deleteNotification
	* @param {number} webNotificationId mandatory number value which contains id of the notification which is to be deleted.
	* @example <caption> Example to invoke deleteNotification</caption>
	*  notificationService.deleteNotification(webNotificationId);
	*/
	deleteNotification(webNotificationId, user?, channel?): Q.Promise<any> {
		user = user ? user : this.userInfoService.getCurrentUserUpn();
		channel = channel ? channel : this.fxpConfiguration.FxpAppSettings["NotificationServiceChannel"];
		var urlDeleteNotification = this.notificationApiWebNotifResourceUrl + "/" + webNotificationId + "?channel=" + channel + "&userId=" + user;
		return Q.Promise((resolve, reject) => {
			this.http.delete(urlDeleteNotification)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		}); 
	}

	/**
	* Delete all notification
	* @method Fxp.Services.notificationService.deleteAllNotification
	* @example <caption> Example to invoke deleteAllNotification</caption>
	*  notificationService.deleteAllNotification();
	*/
	deleteAllNotification(user?, channel?): Q.Promise<any> {
		user = user ? user : this.userInfoService.getCurrentUserUpn();
		channel = channel ? channel : this.fxpConfiguration.FxpAppSettings["NotificationServiceChannel"];
		var urlDeleteAllNotification = this.notificationApiWebNotifResourceUrl + "?channel=" + channel + "&userId=" + user;
		return Q.Promise((resolve, reject) => {
			this.http.delete(urlDeleteAllNotification)
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});  
	}

	/**
	* Mark notification as Read
	* @method Fxp.Services.notificationService.markNotificationAsRead
	* @param {number} webNotificationId mandatory number value which contains id of the notification which is to be marked as read.
	* @example <caption> Example to invoke markNotificationAsRead</caption>
	*  notificationService.markNotificationAsRead(webNotificationId);
	*/
	markNotificationAsRead(webNotificationId, user?, channel?): Q.Promise<any> {
		user = user ? user : this.userInfoService.getCurrentUserUpn();
		channel = channel ? channel : this.fxpConfiguration.FxpAppSettings["NotificationServiceChannel"];
		var urlMarkAsRead = this.notificationApiWebNotifResourceUrl + "/" + webNotificationId + "/mark" + "?channel=" + channel + "&userId=" + user;
		return Q.Promise((resolve, reject) => {
			this.http.put(urlMarkAsRead, {})
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});  
	}

	/**
	* Mark all notification as Read
	* @method Fxp.Services.notificationService.markAllNotificationAsRead
	* @example <caption> Example to invoke markAllNotificationAsRead</caption>
	*  notificationService.markAllNotificationAsRead();
	*/
	markAllNotificationsAsRead(user?, channel?): Q.Promise<any> {
		user = user ? user : this.userInfoService.getCurrentUserUpn();
		channel = channel ? channel : this.fxpConfiguration.FxpAppSettings["NotificationServiceChannel"];
		var urlMarkAllAsRead = this.notificationApiWebNotifResourceUrl + "/mark" + "?channel=" + channel + "&userId=" + user;
		return Q.Promise((resolve, reject) => {
			this.http.put(urlMarkAllAsRead, {})
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});  
	}

	/**
	* Publish Notification
	* @method Fxp.Services.notificationService.publishNotifications
	* @example <caption> Example to invoke publishNotifications</caption>
	*  notificationService.publishNotifications();
	*/
	publishNotifications(notificationArray: Array<any>): Q.Promise<any> {
		var publishEventUrl = this.fxpConfiguration.FxpAppSettings["EventStoreEndpoint"] + "api/events";
		var event = {
			"EventName": this.fxpConfiguration.FxpAppSettings["EventStoreDefaultNotificationEventName"],
			"EventType": "notification",
			"EventSubject": this.fxpConfiguration.FxpAppSettings["EventStoreDefaultNotificationEventName"],
			"Publisher": {
				"Id": this.fxpConfiguration.FxpAppSettings["EventStoreDefaultNotificationPublisherId"],
				"Name": this.fxpConfiguration.FxpAppSettings["EventStoreDefaultNotificationPublisherName"],
			},
			"Payload": "",
			"Notification": {
				"WebNotification": {
					"Enabled": true,
					"Channel": this.fxpConfiguration.FxpAppSettings["NotificationServiceChannel"],
					"Notifications": []
				}
			}
		};

		if (notificationArray) {
			for (var notification of notificationArray) {
				event.Notification.WebNotification.Notifications.push({
					"SenderAddress": notification.From ? notification.From : this.fxpConfiguration.FxpAppSettings["FxPAdminName"],
					"Subject": notification.Subject ? notification.Subject : "",
					"Content": notification.Content,
					"ReceiverAddresses": [notification.To]
				})
			}
		}

		return Q.Promise((resolve, reject) => {
			this.http.post(
				publishEventUrl,
				event,
				{
					headers:
					{
						'Content-Type': 'application/json'
					}
				})
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});  
	}

	/**
	* Publish Notification (Deprecated - DO NOT USE)
	* @method Fxp.Services.notificationService.publishNotificationsRolesRoleGroup
	* @example <caption> Example to invoke publishNotificationsRolesRoleGroup</caption>
	*  notificationService.publishNotificationsRolesRoleGroup();
	*/
	publishNotificationsRolesRoleGroup(notificationArray): Q.Promise<any> {
		var urlPublish = this.fxpServiceEndPoint + "/notification";

		return Q.Promise((resolve, reject) => {
			this.http.post(
				urlPublish,
				notificationArray,
				{
					headers:
					{
						'Content-Type': 'application/json; charset=UTF-8'
					}
				})
				.then(function (response) {
					resolve(response.data);
				})
				.catch(function (error) {
					reject(error);
				});
		});  
	}
}