import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IFxPService } from "../interfaces/IFxpService";

@Injectable()
export class FxpHttpClientService implements IFxPService {
    constructor(private http: HttpClient) {}

    private addDataFieldInResponse(response: any): any {
        response.data = response.body;
        return response;
    }

    private requestData(url: string, headers: any, responseType: string | undefined): Promise<any> {
        const options: any = { headers: headers, observe: 'response' };
        if (responseType) {
            options.responseType = responseType;
        }
        return this.http.get(url, options).toPromise();
    }

    get(url: string, headers?: any, responseType?: string): Promise<any> {
        return this.requestData(url, headers, responseType)
            .then(response => this.addDataFieldInResponse(response));
    }

    put(url: string, data: any, headers?: any): Promise<any> {
        return this.http.put(url, data, { headers: headers, observe: 'response' }).toPromise()
            .then(response => this.addDataFieldInResponse(response));
    }

    patch(url: string, data: any, headers?: any): Promise<any> {
        return this.http.patch(url, data, { headers: headers, observe: 'response' }).toPromise()
            .then(response => this.addDataFieldInResponse(response));
    }

    post<Type>(url: string, data: any, headers?: any, responseType?: string): Promise<any> {
        const options: any = { headers: headers, observe: 'response' };
        if (responseType) {
            options.responseType = responseType;
        }
        return this.http.post<Type>(url, data, options).toPromise()
            .then(response => this.addDataFieldInResponse(response));
    }

    delete(url: string, headers?: any): Promise<any> {
        return this.http.delete(url, { headers: headers, observe: 'response' }).toPromise()
            .then(response => this.addDataFieldInResponse(response));
    }

    jsonp(url: string, data: any, headers?: any): Promise<any> {
        return this.http.jsonp(url, '').toPromise()
            .then(response => this.addDataFieldInResponse(response));
    }

    head(url: string, headers?: any): Promise<any> {
        return this.http.head(url, { headers: headers, observe: 'response' }).toPromise()
            .then(response => this.addDataFieldInResponse(response));
    }
}
