import { IWindowService } from "angular";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpEventBroadCastService } from "../services/BroadCastingService";
declare var _: any;

export class DeviceFactoryProvider implements IFxPService {

    resolution: any;

    constructor(private fxpBroadCastService: FxpEventBroadCastService,) {
        this.resolution = {
            devicePixelRatio: window.devicePixelRatio,
            types: {
                mobile: 'xs',
                tablet: 'sm',
                desktop: 'lg'
            }
        };
        this.resolution.size = this.getWindowSize();
        this.resolution.type = this.updateType();

        var self = this;
        var lazyUpdate = _.throttle(function (event) {
            setTimeout(function () {
                self.resolution.size = self.getWindowSize();
                self.resolution.type = self.updateType();
                fxpBroadCastService.broadCast(FxpBroadcastedEvents.OnLayoutChanged, self.resolution.type);
            });
        }, 250);

        angular
            .element(window)
            .bind('resize', lazyUpdate);
    }

    getWindowSize = function () {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    };

    updateType = function () {
        if (this.resolution.size.width <= 736) {
            return this.resolution.types.mobile;
        }
        else if (this.resolution.size.width <= 1024) {
            return this.resolution.types.tablet;
        }
        else if (this.resolution.size.width > 1024) {
            return this.resolution.types.desktop;
        }
    };

    isSmallScreen = function () {
        return this.resolution.type === this.resolution.types.mobile
            || this.resolution.type === this.resolution.types.tablet;
    };

    isMobile = function () {
        return this.resolution.type === this.resolution.types.mobile;
    };

    isTablet = function () {
        return this.resolution.type === this.resolution.types.tablet;
    };

    isDesktop = function () {
        return this.resolution.type === this.resolution.types.desktop;
    };

}
