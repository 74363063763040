import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class FxpUIBModalService {
  private modalRef: NgbModalRef | null = null;

  constructor(private modalService: NgbModal) {}

  public setModalParameters(
    modalRef: NgbModalRef,
    title: string,
    description: string,
    buttonText: string,
    onClickCallback: () => void = () => {},
    onCancelCallback: () => void = () => {}
  ) {
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = description;
    modalRef.componentInstance.buttonText = buttonText;
    modalRef.componentInstance.onClick = () => {
      onClickCallback();
      modalRef.close();
    };
    modalRef.componentInstance.onCancel = () => {
      onCancelCallback();
      modalRef.dismiss();
    };
    return modalRef;
  }

  public open(modalProps: any): NgbModalRef {
    this.modalRef = this.modalService.open(modalProps);
    return this.modalRef;
  }

  public close(): void {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

  public dismiss(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
      this.modalRef = null;
    }
  }
  public opened(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.modalRef) {
        this.modalRef.result.then(() => {
          resolve();
        });
      }
    });
  }
}
