import { IRootScope } from "../interfaces/IRootScope";
import { UserProfileService } from "../services/userProfileService";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { ILogger } from "../interfaces/ILogger";
import { FxpConstants, ApplicationConstants } from "../common/ApplicationConstants";
import { IFxpContext } from "../interfaces/IFxpContext";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { UserInfoService } from "../services/UserInfoService";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";
import { UserClaimsService } from "../services/UserClaimsService";
import { FxpHttpClientService } from "../services/FxpHttpClientService";
import * as Q from 'q';

export class ActOnBehalfOfHelper {
	private http: FxpHttpClientService;
	private $rootScope: IRootScope;
	private userProfileService: UserProfileService;
	private fxpLoggerService: ILogger;
	private fxpMessage: FxpMessageService;
	private fxpConstants: FxpConstants;
	private fxpContext: IFxpContext;
	private fxpErrorConstants: any;
	private fxpTelemetryContext: TelemetryContext
	private userInfoService: UserInfoService;

	constructor($rootScope: IRootScope, http: FxpHttpClientService, userProfileService: UserProfileService, fxpLoggerService: ILogger, fxpMessage: FxpMessageService, fxpContextService: IFxpContext,
		fxpTelemetryContext: TelemetryContext, userInfoService: UserInfoService, private userClaimsService: UserClaimsService) {
		this.fxpErrorConstants = $rootScope.fxpUIConstants.UIMessages;
		this.$rootScope = $rootScope;
		this.http = http;
		this.userProfileService = userProfileService;
		this.fxpLoggerService = fxpLoggerService;
		this.fxpMessage = fxpMessage;
		this.fxpConstants = FxpConstants;
		this.fxpContext = fxpContextService;
		this.fxpTelemetryContext = fxpTelemetryContext;
		this.userInfoService = userInfoService;
	}

	getUserProfileAndClaims(oboUserProfile: any): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		try {
			if (oboUserProfile) {
				var alias = oboUserProfile.Alias;
				var aadobjectId = oboUserProfile.AadObjectId;
				self.userInfoService.setCurrentUserOID(aadobjectId);
				self.userProfileService.setCurrentUser(alias);
				self.userProfileService.getBasicProfile(aadobjectId, true).then(function (oboUserInfo) {
					
					self.userInfoService.setCurrentUserUpn(oboUserInfo.upn);
					self.userInfoService.setActingOnBehalfOfFlag(true);
					self.fxpLoggerService.setOBOUserContext(oboUserInfo.roleGroupId, oboUserInfo.roleGroupName, self.userProfileService.isObo(), oboUserInfo.TenantKey, oboUserInfo.TenantName);
					self.userClaimsService.getCalimsSvc(alias).then(function (claimasData) {
						deferred.resolve(oboUserInfo);
					}).catch(function (error) {
						deferred.reject(error);
					});

				}).catch(function (response) {
					self.fxpMessage.addMessage(self.fxpErrorConstants.SelectedUserProfileInformation.ErrorMessage, FxpConstants.messageType.error);
					deferred.reject(response);
				});
			}
		}
		catch (e) {
			self.fxpMessage.addMessage(self.fxpErrorConstants.SelectedUserProfileInformation.ErrorMessage, FxpConstants.messageType.error);
		}

		return deferred.promise;
	}

	getObOUserConfiguration(appRole: string): Q.Promise<any> {
		var self = this;
		if (appRole) {
			return Q(self.http.get(
				ApplicationConstants.PersonaConfigByRoleGroupId + '/' + appRole
			));
		}
		else {
			console.log("App Role is not available for selected user");
		}
	}

	getPropBag(): LogPropertyBag {
		var self = this;
		var propbag = self.fxpLoggerService.createPropertyBag();
		propbag.addToBag(FxpConstants.metricConstants.UserAgent, navigator.userAgent);
		propbag.addToBag(FxpConstants.metricConstants.SessionId, window["sessionId"]);
		propbag.addToBag(FxpConstants.metricConstants.UserUPN, self.userInfoService.getCurrentUserUpn());
		propbag.addToBag(FxpConstants.metricConstants.Geography, self.fxpTelemetryContext.getGeography());
		return propbag;
	}

	getMetricPropBag(startDate: Date, endDate: Date): LogPropertyBag {
		var self = this;
		var propbag = self.fxpLoggerService.createPropertyBag();
		propbag.addToBag(FxpConstants.metricConstants.StartTime, startDate.toUTCString());
		propbag.addToBag(FxpConstants.metricConstants.EndTime, endDate.toUTCString());
		propbag.addToBag(FxpConstants.metricConstants.ServiceName, FxpConstants.metricConstants.searchProfileSvcName);
		propbag.addToBag(FxpConstants.metricConstants.UserAgent, navigator.userAgent);
		propbag.addToBag(FxpConstants.metricConstants.SessionId, self.$rootScope.sessionId);
		propbag.addToBag(FxpConstants.metricConstants.UserUPN, self.userInfoService.getCurrentUserUpn());
		propbag.addToBag(FxpConstants.metricConstants.Geography, self.fxpTelemetryContext.getGeography());
		return propbag;
	}

	static ActOnBehalfOfHelperFactory($rootScope, http, userProfileService, fxpLoggerService, fxpMessage, fxpContextService, fxpTelemetryContext, userInfoService, userClaimsService) {
		return new ActOnBehalfOfHelper($rootScope, http, userProfileService, fxpLoggerService, fxpMessage, fxpContextService, fxpTelemetryContext, userInfoService, userClaimsService);
	}
}
