import { IRootScope } from "../interfaces/IRootScope";
import { FxpContext } from "../context/FxpContext";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpConstants } from "../common/ApplicationConstants";
import { UserInfoService } from "./UserInfoService";
import { UserProfileService } from "./userProfileService";
import { UserClaimsService } from "./UserClaimsService";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpGlobalStoreService } from "./fxp.global.store.service";
import { AddCurrentUserClaims } from "../../app/claims/claims.action";
import { AddCurrentUserProfile } from "../../app/profile/profile.action";
import {AddOBOEntity , RemoveOBOEntity} from "../../app/obo/obo.action";
import { removeOboContext } from "../../app/adminTiles/adminTile.action";
import * as Q from 'q'
import { FxpRootScopeService,IRootSubjects } from "../services/FxpRootScopeService";
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */
/**
    * A service to connect to user profile service to fetch the detaisl of user info and user claims related to partner apps
    * @class Fxp.Services.OBOUserService
    * @classdesc A service to connect to user profile service to fetch the user profile details and claims 
    * @example <caption> Example to create an instance of user profile service</caption>         
    *  //Initializing User Profile Service
    *  angular.module('FxPApp').controller('AppController', ['OBOUserService', AppController]);
    *  function AppController(OBOUserService){ OBOUserService.InitializeOBOEntityFromContext(); }
    */

export class OBOUserService implements IFxPService {
	private $rootScope: IRootScope;
	private static _instance: OBOUserService;
	private fxpContextService: FxpContext;
	private rootScopeService: FxpRootScopeService;
 	private fxpRootScope: IRootSubjects;
	//private q: angular.IQService;
	private OBOUserStatus: boolean;
	private OBOUserRoutes: any;
	private OBOUser: any;
	private OBOUserSessionInfo: string;
	private OBOUserTenantConfiguration: any;
	private fxpMessageService: FxpMessageService;
	private userInfoService: UserInfoService;
	private userProfileService: UserProfileService;
	private isOBOAsNewTab: boolean;
	private userClaimsService:UserClaimsService;
	
	constructor($rootScope: IRootScope, fxpContextService: FxpContext, fxpMessageService: FxpMessageService, userInfoService: UserInfoService, userProfileService: UserProfileService,userClaimsService:UserClaimsService,private globalStoreService:FxpGlobalStoreService) {
		this.$rootScope = $rootScope;
		this.fxpContextService = fxpContextService;
		this.rootScopeService = FxpRootScopeService.getInstance();
		this.rootScopeService.rootScopeSubject.subscribe((data) => {
		this.fxpRootScope = data;
		});
		//this.q = q;
		this.OBOUser = "";
		this.OBOUserRoutes = "";
		this.OBOUserStatus = false;
		this.OBOUserSessionInfo = "";
		this.OBOUserTenantConfiguration = "";
		this.fxpMessageService = fxpMessageService;
		this.userInfoService = userInfoService;
		this.userProfileService = userProfileService;
		this.isOBOAsNewTab = false;
		this.userClaimsService=userClaimsService;

		if (OBOUserService._instance) {
			return OBOUserService._instance;
		}
		OBOUserService._instance = this;
	}

	/**
   * Intitalizes the in memory variables for OBO using the value passed
   * @method Fxp.Services.OBOUserService.initilizeOBOEntity
   * @param {any} value the json value for OBO entity
   * @example <caption> Example to invoke initilizeOBOEntity</caption>
   *  OBOUserService.initilizeOBOEntity(value);
   */
	initilizeOBOEntity(value: any): void {
		this.rootScopeService.setRootScopeField('actOnBehalfOfUserActive', value.OBOUserStatus);
		this.OBOUser = value.OBOUser;
		this.OBOUserRoutes = value.OBOUserRoutes;
		this.OBOUserStatus = value.OBOUserStatus;
		this.OBOUserSessionInfo = value.OBOUserSessionInfo;
		this.OBOUserTenantConfiguration = value.OBOUserTenantConfiguration;
		this.userInfoService.setOBOUserSessionInfo(value.OBOUserSessionInfo);
		this.userInfoService.setActingOnBehalfOfFlag(true);
		if (!this.isOBOAsNewTab) {
			this.userInfoService.setCurrentUserOID(value.OBOUser.aadObjectId);
			this.userInfoService.setCurrentUser(this.OBOUser.alias);
			this.userInfoService.setCurrentUserUpn(this.OBOUser.userUpn);
		}
		//Dispatch action to save obo entity state
		this.globalStoreService.DispatchGlobalAction("Platform",AddOBOEntity(value));
	}


	/**
	* Saves the entity of OBO in context and local inmemory variables
	* @method Fxp.Services.OBOUserService.saveOBOEntityInContext
	* @param {any} value the json value for OBO entity
	* @example <caption> Example to invoke saveOBOEntityInContext</caption>
	*  OBOUserService.saveOBOEntityInContext(value);
	*/
	saveOBOEntityInContext(value: any): void {
		this.initilizeOBOEntity(value);
		this.fxpContextService.saveContext("OBOEntity", JSON.stringify(value));
	}

	setCurrentUserStateFromLoggedInUserState():void{
		//set current user details from logged in user details
		let profile = this.globalStoreService.GetPlatformState().LoggedInUser.Profile;
		let claims = this.globalStoreService.GetPlatformState().LoggedInUser.Claims;
		this.globalStoreService.DispatchGlobalAction("Platform", AddCurrentUserClaims(claims));
		this.globalStoreService.DispatchGlobalAction("Platform", AddCurrentUserProfile(profile));
	}
	/**
	  * Saves the entity of OBO in context and local inmemory variables
	  * @method Fxp.Services.OBOUserService.saveOBOEntityInContext
	  * @param {any} value the json value for OBO entity
	  * @example <caption> Example to invoke saveOBOEntityInContext</caption>
	  *  OBOUserService.saveOBOEntityInContext(value);
	  */

    initializeOBOEntityFromContext(): Q.Promise<any> {
      let self = this;
      //Wrapping it up in a promise just to make FxP wait until this is done. This promise need to succeed in all the cases. 
      let defer = Q.defer();
      this.fxpContextService.readContext("OBOEntity").then(function (udata) {
        if (!udata.IsError) {
		  var oboData = JSON.parse(udata.Result);
		  self.userInfoService.setCurrentUserOID(oboData.OBOUser.aadObjectId);
		  self.userProfileService.setCurrentUser(oboData.OBOUser.alias);
          try {
			self.initilizeOBOEntity(oboData);
            self.userClaimsService.getCalimsSvc(oboData.OBOUser.alias).then(function () {
              self.isOBOAsNewTab = true;
              defer.resolve(oboData.OBOUser);
            }).catch(function () {
              //TODO: Log exception here.
			  defer.resolve(null);
			  self.removeOBOUserContext();
            });
          }
          catch (ex) {
            self.fxpMessageService.addMessage(self.$rootScope.fxpUIConstants.UIMessages.SelectedProfileRoles.ErrorMessage, FxpConstants.messageType.error);
            //TODO: Log exception here. 
            // Do not reject this promise otherwise FxP will not load. If this is failing OBO will not get initialized but FxP will boot normal. 
			defer.resolve(null);
			self.removeOBOUserContext();
          }
        }
        else {
          self.removeOBOUserContext();
          defer.resolve(null);
        }
        //Due to async nature of the call to this method, the variable 
        //actOnBehalfOfUserActive is not being set by the time FXP has 
        //reached Home page.Hence forcing it to apply the rootscope once again.
        if (!self.$rootScope.$$phase) {
          self.$rootScope.$apply();
        }

      }, function (error) {
        self.removeOBOUserContext();
        //Due to async nature of the call to this method, the variable 
        //actOnBehalfOfUserActive is not being set by the time FXP has 
        //reached Home page.Hence forcing it to apply the rootscope once again.
        if (!self.$rootScope.$$phase) {
          self.$rootScope.$apply();
        }
        defer.resolve(null);

      });

      return defer.promise;
    }

	/**
	* Reset the value in context and in memory variables for OBO User by removing values from there
	* @method Fxp.Services.OBOUserService.removeOBOUserContext
	* @example <caption> Example to invoke removeOBOUserContext</caption>
	*  OBOUserService.removeOBOUserContext();
	*/
	removeOBOUserContext(): void {
		var self = this;
		//dispatch action to remove obo context state
		self.globalStoreService.DispatchGlobalAction("Platform",RemoveOBOEntity());
	
		self.rootScopeService.setRootScopeField('actOnBehalfOfUserActive', false);
		if (self.OBOUser != "" && self.OBOUser.alias) {
			self.fxpContextService.deleteContext(self.OBOUser.alias + "-userInfo").then(function (data) {
				console.log(self.OBOUser.alias + "-userInfo - deleted" + data);
			}, function (error) {
				console.log(self.OBOUser.alias + "-userInfo - deleted Error :", error);
			});

			self.fxpContextService.deleteContext(self.OBOUser.alias + "-userclaims").then(function (data) {
				console.log(self.OBOUser.alias + "-userclaims - deleted" + data);
			}, function (error) {
				console.log(self.OBOUser.alias + "-userclaims - deleted Error :", error);
			});
			self.fxpContextService.deleteContext(self.OBOUser.aadObjectId + "-userclaims").then(function (data) {
				console.log(self.OBOUser.aadObjectId + "-userclaims - deleted" + data);
			}, function (error) {
				console.log(self.OBOUser.aadObjectId + "-userclaims - deleted Error :", error);
			});
		}

		this.OBOUserRoutes = "";
		this.OBOUser = "";
		this.OBOUserStatus = false
		this.OBOUserTenantConfiguration = "";
		this.fxpContextService.deleteContext("OBOEntity");
		this.userInfoService.setActingOnBehalfOfFlag(false);
		this.userInfoService.setCurrentUserOID(this.userInfoService.getLoggedInUserOID());
		this.userInfoService.setCurrentUser(this.userInfoService.getLoggedInUser());
		this.userInfoService.setCurrentUserUpn(this.userInfoService.getLoggedInUserUpn());
		this.globalStoreService.GetPlatformStore().dispatch(removeOboContext());
	};


	/**
	* Get the routes for OBO User
	* @method Fxp.Services.OBOUserService.getOBOUserRoutes
	* @example <caption> Example to invoke getOBOUserRoutes</caption>
	*  OBOUserService.getOBOUserRoutes();
	*/
	getOBOUserRoutes(): any {
		return this.OBOUserRoutes;
	};

	/**
	* get the OBO User Informations
	* @method Fxp.Services.OBOUserService.getOBOUser
	* @example <caption> Example to invoke getOBOUser</caption>
	*  OBOUserService.getOBOUser(OBOUser);
	*/
	getOBOUser(): any {
		return this.OBOUser;
	};

	/**
	* get the OBO User Tenant UIStrings
	* @method Fxp.Services.OBOUserService.getOBOUserTenantConfiguration
	* @example <caption> Example to invoke getOBOUserTenantConfiguration</caption>
	*  OBOUserService.getOBOUserTenantConfiguration();
	*/
	getOBOUserTenantConfiguration(): any {
		return this.OBOUserTenantConfiguration;
	};
}
