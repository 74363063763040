import { forwardRef, Inject, Injectable } from "@angular/core"
import { UrlService,StateRegistry,StateService, TransitionService, HookMatchCriteria, HookRegOptions, TransitionHookFn } from "@uirouter/core"
import { IStateConfig } from "../../js/interfaces/IStateConfig"
@Injectable()
export class FxpStateService {
    constructor(@Inject(forwardRef(() => StateService))
    private stateService: StateService ) { }

    public go(stateName: any, params?: any, transitionOptions?: any): any {
        return this.stateService.go(stateName, params, transitionOptions)
    }
    public get(stateName?: any): any {
        return stateName ? this.stateService.get(stateName) : this.stateService.get()
    }
    public getCurrentStateName(): any {
        return this.stateService.$current.name
    }
    public getCurrentState(): any {
        return this.stateService.$current
    }
    public href(stateName: any, params?: any, options?: any): string {
        return this.stateService.href(stateName, params, options)
    }
    public onInvalid(callbackfunction: any) {
        return this.stateService.onInvalid(callbackfunction);
    }
    public getParams() {
        return this.stateService.params;
    }
    public reload(){
        this.stateService.reload()
    }
}

@Injectable()
export class FxpTransitionService {
    constructor(
        @Inject(forwardRef(() => TransitionService)) private transitionService: TransitionService) { }

    onStart(criteria: HookMatchCriteria, callback: TransitionHookFn, options?: HookRegOptions): Function {
        return this.transitionService.onStart(criteria, callback, options);
    }
    onSuccess(criteria: HookMatchCriteria, callback: TransitionHookFn, options?: HookRegOptions): Function {
        return this.transitionService.onSuccess(criteria, callback, options);
    }
    onError(criteria: HookMatchCriteria, callback: TransitionHookFn, options?: HookRegOptions): Function {
        return this.transitionService.onError(criteria, callback, options);
    }
}

//To be used in DashboardHelper for state registry
@Injectable()
export class FxpUIStateRegistryService {
    constructor(
        @Inject(forwardRef(() => StateRegistry)) private stateRegistry: StateRegistry,
        @Inject(forwardRef(() => UrlService)) private urlService: UrlService) { }
       addState(stateName:string, state: IStateConfig){
        this.stateRegistry.register(state);
       }
       otherwise(url:string){
        this.urlService.rules.otherwise(url);
       }
}