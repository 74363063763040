import { BehaviorSubject } from "rxjs";
import { filter, map } from 'rxjs/operators';

export interface IRootSubjects {
    defaultStateName: string;
    messages: Array<any>;
    messageClass: string;
    closeMessage(m): void;
    clearAndReload(): void;
    isAuthenticated: boolean;
    sessionId: any;
    fxpUIConstants: any;
    BusinessRole: string;
    roleGroupId: number;
    roleGroupIdInternal: number;
    configRouteStates: Array<any>;
    navigateToLandingPage(): void;
    getLandingPage(): void;
    actOnBehalfOfUserActive: boolean;
    OBOUserRoutes: any;
    currentRoutes: any;
    appLoaded: boolean;
    showLoader: boolean;
    appBooted: boolean;
    loadingText: string;
    isFxpLoadedWithClaims: boolean;
    pageLoadMetrics: any;
    viewContentLoadingTime: any;
    stateChangeStartTime: any;
    startTime: any;
    isLeftNavPinned: boolean;
    isLeftNavOpen: boolean;
    previousStateName: string;
    fxpBreadcrumb: Array<any>;
    isBreadcrumbEnabled: boolean;
    initialFlags: any;
    displayName: string;
    tenantId: string;
    tenantIdInternal: string;
    isNotificationDNDEnabled: boolean;
    isNewTabAllowed: boolean;
    isHelpOpen: boolean;
    currentLeftNavItem: any;
    isHelpFlyoutPinned: any;
    isUserInfoAvailable: boolean;
    isBotEnabled: boolean;
    activeElement: string | undefined;
    isLoginRequired: boolean;
    isBlockedCSAUser: boolean;
    isFullScreenEnabled: boolean;
    isSystemAnnouncementVisible: boolean;
    defaultAppRole: string;
    userProfile: any;
    defaultAppRoleInternal: string;
    userThumbnailPhoto: string;
    reportsToThumbnailPhoto: string;
    fxpheaderdata: any;
}

interface Event {
    name: string;
    data?: any;
}

export class FxpRootScopeService {
    private static _rootScopeInstance: FxpRootScopeService;
    public rootScopeSubject: BehaviorSubject<IRootSubjects>;
    public eventSubject: BehaviorSubject<Event | null>;

    private constructor() { }
    static getInstance(): FxpRootScopeService {
        if (!this._rootScopeInstance) {
            this._rootScopeInstance = new FxpRootScopeService();
            this._rootScopeInstance.init();
        }
        return this._rootScopeInstance;
    }

    private init() {
        this.eventSubject = new BehaviorSubject<Event | null>(null);
        this.rootScopeSubject = new BehaviorSubject<IRootSubjects>({
            defaultStateName: '',
            messages: [],
            messageClass: '',
            closeMessage: (m) => { },
            clearAndReload: () => { },
            isAuthenticated: false,
            sessionId: '',
            fxpUIConstants: {},
            BusinessRole: '',
            roleGroupId: 0,
            roleGroupIdInternal: 0,
            configRouteStates: [],
            navigateToLandingPage: () => { },
            getLandingPage: () => { },
            actOnBehalfOfUserActive: false,
            OBOUserRoutes: {},
            currentRoutes: {},
            appLoaded: false,
            showLoader: false,
            appBooted: false,
            loadingText: '',
            isFxpLoadedWithClaims: false,
            pageLoadMetrics: {},
            viewContentLoadingTime: {},
            stateChangeStartTime: {},
            startTime: {},
            isLeftNavPinned: false,
            isLeftNavOpen: false,
            previousStateName: '',
            fxpBreadcrumb: [],
            isBreadcrumbEnabled: false,
            initialFlags: {},
            displayName: '',
            tenantId: '',
            tenantIdInternal: '',
            isNotificationDNDEnabled: false,
            isNewTabAllowed: false,
            isHelpOpen: false,
            currentLeftNavItem: {},
            isHelpFlyoutPinned: {},
            isUserInfoAvailable: false,
            activeElement: undefined,
            isBotEnabled: false,
            isLoginRequired: false,
            isBlockedCSAUser: false,
            isFullScreenEnabled: false,
            isSystemAnnouncementVisible: false,
            defaultAppRole: "",
            userProfile: {},
            defaultAppRoleInternal: "",
            userThumbnailPhoto: "",
            reportsToThumbnailPhoto: "",
            fxpheaderdata: {
                ElementType: "span",
                DisplayText: "",
                Name: "",
                Email: "",
                Role: "",
            },
        });
    }

    public getRootScopeField(field: keyof IRootSubjects): any {
        return this.rootScopeSubject.value[field];
    }

    public setRootScopeField(field: string | keyof IRootSubjects, value: any): void {
        const updatedRootScope = { ...this.rootScopeSubject.value as IRootSubjects }; // TODO: Type assertion here
        updatedRootScope[field] = value as any;
        this.rootScopeSubject.next(updatedRootScope);
    }

    public broadcast(eventName: string, ...data: any[]): void {
        this.eventSubject.next({ name: eventName, data });
    }

    public on(eventName: string, callback: (data?: any) => void): void {
        this.eventSubject.pipe(
            filter(event => event !== null && event.name === eventName),
            map(event => event!.data)
        ).subscribe(callback);
    }
}