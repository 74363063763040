import {
  IRootScope
} from "../interfaces/IRootScope";
import {
  ILogger
} from "../interfaces/ILogger";
import { UserInfoService } from "../services/UserInfoService";
import { FxpEventBroadCastService } from "../services/BroadCastingService";
import { FxpBroadcastedEvents } from "../services/FxpBroadcastedEvents";
import { IScope } from "angular";
import { UpdateCounter } from '../../app/init/app.status.action.creators';
import { IPlatformState } from '../../app/store/platform.state.model';
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { FxpSignalRService } from "../services/FxpSignalRClient";
import { FeatureUsageEvent } from "../telemetry/FeatureUsageEvent";
import { ActionType, ComponentType, EventName } from "@microsoftit/telemetry-extensions-npm";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { HideLoader } from "../../app/loader/loader.actions";

export class FxpWelcomeController {
  private signalRSubscriptionHandle: Array<any>; 
  constructor(
    private $rootScope: IRootScope,
    private $scope: IScope,
    private fxpLoggerService: ILogger,
    private userInfoService: UserInfoService,
    private fxpBroadCastService: FxpEventBroadCastService,
    private globalStore: FxpGlobalStoreService,
    private signalRService: FxpSignalRService,
   ) {
    let self = this;
    const source = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpWelcomeController`; 
    this.signalRSubscriptionHandle = new Array<any>();
    let fxpWelcomeFeature = new FeatureUsageEvent("FxpWelcomeFeature", ActionType.User, "Load",  EventName.PageLoad, ComponentType.Web);
    fxpLoggerService.startFeatureUsageEvent(fxpWelcomeFeature); 
    this.fxpBroadCastService.broadCast(FxpBroadcastedEvents.OnPageLoadComplete, "FxpWelcomePage");
    setTimeout(function () {
      self.globalStore.DispatchGlobalAction("Platform", HideLoader({}));
    }, 100);
    let globalState = this.globalStore.GetGlobalState();
    $scope.globalState = globalState;
    $scope.globalCounter = this.globalStore.GetPlatformState().Status.HitCounter;

    let helloWorldNgState = this.globalStore.GetPartnerState("HelloWorld");


    $scope.ng2localCounter = 0;
    if (helloWorldNgState) {
      $scope.ng2localCounter = helloWorldNgState.counter.counter;
    }

    $scope.IncreaseCount = () => {
      console.log("Updating global counter by +1");
      // Send action using Global Dispatcher
      // this.globalStore.DispatchGlobalAction("Platform", UpdateCounter(1));
      
      // Send action to the platform Store directly
      let store = this.globalStore.GetPlatformStore();
      store.dispatch(UpdateCounter(1));
    }

    $scope.DecreaseCount = () => {
      console.log("Updating global counter by -1");
      this.globalStore.DispatchGlobalAction("Platform", UpdateCounter(-1));
    }

    this.globalStore.SubscribeToPlatformState("Platform", (platformState: IPlatformState) => {
      $scope.globalCounter = platformState.Status.HitCounter;
    });

    this.globalStore.SubscribeToGlobalState("Platform", (globalState) => {
      $scope.globalState = globalState;
      if (globalState.HelloWorld) {
        $scope.ng2localCounter = globalState.HelloWorld.counter.counter;
      }
    });

    $scope.IncreaseNg1Count = () => {
      this.globalStore.DispatchGlobalAction("Platform", { type: "CHANGE_COUNTER", payload: 1 });
    }

    $scope.DecreaseNg1Count = () => {
      this.globalStore.DispatchGlobalAction("Platform", { type: "CHANGE_COUNTER", payload: -1 });
    }

    $scope.AddNg1Todo = () => {
      this.globalStore.DispatchGlobalAction("Platform", { type: "ADD_TODO", payload: { id: 1000, task: 'Globally Added', isCompleted: false } });
    }

    $scope.eventName = ""; 
    $scope.tenantName = "";
    $scope.singalRResponse = "";
    const callBackFunction = function(data){
      $scope.singalRResponse = JSON.stringify(data); 
    }
    $scope.onSignalREventSubscribe = () => {
      let unSubscribeCallBack = this.signalRService.subscribe($scope.eventName, $scope.tenantName, this.signalRCallBackHandler.bind(this));
      this.signalRSubscriptionHandle.push({eventName: $scope.eventName, unsubscribe: unSubscribeCallBack});
      let feature = new FeatureUsageEvent("SignalREventSubscribe", ActionType.User, "SubscribeToEvent",  EventName.ButtonClick, ComponentType.Device);
      fxpLoggerService.logFeatureUsageEvent(source, feature, undefined, undefined);
    }
    $scope.onSignalREventUnSubscribe = () => {
      let unsubscribe = this.signalRSubscriptionHandle.filter(item=> item.eventName == $scope.eventName)
      if (unsubscribe && unsubscribe.length > 0){
        unsubscribe[0].unsubscribe ();
        unsubscribe.shift();
        this.signalRSubscriptionHandle = [...this.signalRSubscriptionHandle.filter(item=> item.eventName != $scope.eventName), ...unsubscribe];
        let feature = new FeatureUsageEvent("SignalREventUnSubscribe", ActionType.User, "UnSubscribeToEvent",  EventName.ButtonClick, ComponentType.Device);
        fxpLoggerService.logFeatureUsageEvent(source, feature, undefined, undefined); 
      }
    }

    $scope.completeFeature = ()=>{
      fxpLoggerService.endFeatureUsageEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpWelcomeController`, fxpWelcomeFeature); 
    }

    
    
  }

  signalRCallBackHandler(data: any):void{
    
    this.$scope.singalRResponse = JSON.stringify(data); 
  }
}

FxpWelcomeController.$inject = ['$rootScope', '$scope', 'FxpLoggerService', 'UserInfoService', 'FxpEventBroadCastService', 'FxpGlobalStoreService', 'FxpSignalRService'];
