/// <reference path="../../typings/angularjs/angular-cookies.d.ts" />
/// <reference path="../../typings/adal-angular/adal-angular.d.ts" />
import { CommonUtils } from "../utils/CommonUtils";
import { EnvironmentData } from "../telemetry/EnvironmentData";
import {
  PerfMarkers,
  ApplicationConstants,
  CustomEvents,
  FxpConstants,
} from "../common/ApplicationConstants";
import { IRootScope } from "../interfaces/IRootScope";
import { FxpAuthorizationService } from "../services/FxpAuthorizationService";
import { FxpConfigurationService } from "../services/FxpConfiguration";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpContext } from "../context/FxpContext";
import { TelemetryContext } from "../telemetry/telemetrycontext";
import { ILogger } from "../interfaces/ILogger";
import { PlannedDownTimeService } from "../../app/systemMessages/PlannedDownTimeService";
import { UserProfileService } from "../services/userProfileService";
import { OBOUserService } from "../services/OBOUserService";
import { FxpGlobalStoreService } from "../services/fxp.global.store.service";
import { FxpStateTransitionService } from "../services/FxpStateTransitionService";
import { AppControllerHelper } from "../factory/AppControllerHelper";
import { PartnerAppRegistrationService } from "../services/PartnerAppRegistrationService";
import { PlatformStateRouter } from "@fxp/staterouter";
import { PlatformStoreFactory } from "../../app/store/platform.store.factory";
import { ShowLoader } from "../../app/loader/loader.actions";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { EventData } from "../telemetry/EventData";
import { SystemEvent } from "../telemetry/SystemEvent";
import { ComponentType } from "@microsoftit/telemetry-extensions-npm";
import { FxpLogHelper } from "./../telemetry/FxpLogHelper";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { UpsertFeatureFlags } from "../../app/flighting/featureFlags.actions";
import {
  Configuration,
  LogLevel,
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  AccountInfo,
} from "@azure/msal-browser";
import { error } from "jquery";
import {
  MsalAuthenticationService,
  MsalAuthenticationServiceProvider,
} from "../services/MsalAuthenticationService";
import { FxpRouteService } from "../services/FxpRouteService";
import { UserInfoService } from "../services/UserInfoService";
import { FxpRootScopeService } from "../services/FxpRootScopeService";
import { FxpStateService } from "../../app/services/FxpStateRoutingHelperService";

declare type IStateProvider = any;
declare type IUrlRouterProvider = any;

/**
 * @application  Fxp
 */

/**
 * A class that contains angular configurations for FXP app
 * @class Fxp.Boot.FxpBootstrap
 * @classdesc A class that contains angular configurations for FXP app,
 */
export class FxpBootstrap {
  /**
   * An angular configuration for Initializing context and window error handler
   * @method Fxp.Boot.FxpBootstrap.fxpInit
   */
  static onloginSuccessSubcription = null;
  static sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpBootStrap`;
  public static msalPublicClient: PublicClientApplication;
  private static getCommonProperties() {
    let customProperties = FxpLogHelper.createPropertyBag();
    customProperties.addToBag(TelemetryConstants.CLASSNAME, "FxpBootstrap");
    customProperties.addToBag(TelemetryConstants.METHODNAME, "fxpConfigInit");
    customProperties.addToBag("BaseUrl", ApplicationConstants.FxpBaseUrl);
    return customProperties;
  }

  public static fxpConfigInit = function (
    $provide: angular.auto.IProvideService
  ) {
    const source = `${FxpBootstrap.sourceForTelemetry}.FxpConfigInit`;
    if (CommonUtils.isNullOrEmpty(sessionStorage["startTime"]))
      sessionStorage["startTime"] = new Date();

    $provide.decorator("$exceptionHandler", [
      "$delegate",
      "$injector",
      "FxpConfigurationService",
      "FxpLoggerService",
      "FxpTelemetryContext",
      "FxpContextService",
      function (
        $delegate,
        $injector: angular.auto.IInjectorService,
        fxpConfiguration: FxpConfigurationService,
        fxpLoggerService: ILogger,
        FxpTelemetryContext: TelemetryContext,
        fxpContextService: FxpContext
      ) {
        var environmentData = new EnvironmentData(
          fxpConfiguration.ModelConfiguration.EnvironmentName,
          fxpConfiguration.ModelConfiguration.ServiceOffering,
          fxpConfiguration.ModelConfiguration.ServiceLine,
          fxpConfiguration.ModelConfiguration.Service,
          fxpConfiguration.ModelConfiguration.ComponentId,
          fxpConfiguration.ModelConfiguration.ComponentName,
          fxpConfiguration.FxpAppSettings.ApplicationName,
          fxpConfiguration.ModelConfiguration.IctoId,
          fxpConfiguration.ModelConfiguration.BusinessProcessName
        );
        FxpTelemetryContext.setEnvironmentDetails("FXP", environmentData);
        if (
          fxpConfiguration.FxpBaseConfiguration != null &&
          fxpConfiguration.FxpBaseConfiguration.PartnerTelemetryEnvironments !=
          null
        ) {
          FxpTelemetryContext.setPartnerEnvironmentDetails(
            fxpConfiguration.ModelConfiguration.EnvironmentName,
            fxpConfiguration.FxpBaseConfiguration.PartnerTelemetryEnvironments
          );
        }
        fxpContextService.saveContext(
          ApplicationConstants.BaseUrl,
          ApplicationConstants.FxpBaseUrl
        );
        fxpContextService.saveContext(
          ApplicationConstants.AssetsUrl,
          fxpConfiguration.FxpAppSettings.AssetsUrl
        );
        fxpContextService.saveContext(
          ApplicationConstants.BaseConfigDBName,
          fxpConfiguration.FxpBaseConfiguration
        );

        window["fxpLogger"] = fxpLoggerService; //adding for backward compatibility

        let eventData = new SystemEvent(
          TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP +
          ".ConfigurationInitialization",
          ComponentType.Web,
          "Initializing FxP Boot Configuration"
        );
        let customProperties = FxpBootstrap.getCommonProperties();
        customProperties.addToBag(
          "EnvironmentName",
          fxpConfiguration.ModelConfiguration.EnvironmentName
        );
        fxpLoggerService.logSystemEvent(source, eventData, customProperties);

        var windowOnError = function (msg, url, lineNo, columnNo, error) {
          var propbag = fxpLoggerService.createPropertyBag();
          try {
            propbag.addToBag("Url", url);
            propbag.addToBag("LineNo", lineNo);
            propbag.addToBag("ColumnNo", columnNo);
            propbag.addToBag("Type", "javascript");
            propbag.addToBag("Location", window.location.hash);
            propbag.addToBag("Message", msg);
            propbag.addToBag("ErrorObject", CommonUtils.objectToString(error));
          } finally {
            fxpLoggerService.logError(
              source,
              `${CommonUtils.objectToString(msg)}`,
              ErrorCodes.GlobalExceptionHandler,
              error ? CommonUtils.objectToString(error.stack) : null,
              propbag,
              null,
              null
            );
          }
        };

        window.onerror = windowOnError;
        return function (exception, cause) {
          $delegate(exception, cause);
          var propbag = fxpLoggerService.createPropertyBag();
          propbag.addToBag("Type", "angularjs");
          propbag.addToBag("Location", window.location.hash);
          propbag.addToBag("Cause", CommonUtils.objectToString(cause));
          fxpLoggerService.logError(
            source,
            `${CommonUtils.objectToString(exception.message)}`,
            ErrorCodes.GlobalExceptionHandler_AngularJs,
            CommonUtils.objectToString(exception.stack),
            propbag
          );
        };
      },
    ]);
  };

  public static InitializeStore = function (fxpLoggerService: ILogger) {
    PlatformStoreFactory.CreateIfNotExist(fxpLoggerService);
    PlatformStateRouter.Get().Init();
    let properties = FxpBootstrap.getCommonProperties();
    properties.addToBag(TelemetryConstants.METHODNAME, "InitializeStore");
    fxpLoggerService.logTrace(
      `${FxpBootstrap.sourceForTelemetry}.InitializeStore`,
      "Global Platform Store and Router Store has been initialized"
    );
  };

  /**
   * An angular configuration method for setting Application theme
   * @method Fxp.Boot.FxpBootstrap.setSanitizedWhitelist
   */
  public static setSanitizedWhitelist = function (
    $compileProvider: angular.ICompileProvider
  ) {
    $compileProvider
      .imgSrcSanitizationWhitelist(
        /^\s*(https?|http|ms-appx|ms-appx-web|data|blob):/
      )
      .aHrefSanitizationWhitelist(
        /^\s*(https?|ftp|mailto|chrome-extension|sip):/
      );
  };

  /**
   * An angular configuration method for settingdefault behaviour of httpProvider
   * @method Fxp.Boot.FxpBootstrap.configHttpProvider
   */
  public static configHttpProvider = function (
    $httpProvider: angular.IHttpProvider
  ) {
    var token;

    // if (sessionStorage['adal.idtoken']) {
    // 	token = 'Bearer ' + sessionStorage['adal.idtoken'];
    // }
    $httpProvider.defaults.useXDomain = true;
    $httpProvider.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded; charset=utf-8";
    $httpProvider.defaults.headers.common["Authorization"] = token;
    $httpProvider.interceptors.push(
      "FxpPartnerHttpRequestInterceptor",
      "FxpHttpCorrelationInterceptor",
      "FxpHttpRetryInterceptor",
      "MsalHttpRequestInterceptor"
    );
    delete $httpProvider.defaults.headers.common["X-Requested-With"];
  };

  /**
   * An angular configuration method to register states
   * @method Fxp.Boot.FxpBootstrap.registerRoutes
   */
  public static registerRoutes = function (
    $stateProvider: IStateProvider,
    $urlRouterProvider: IUrlRouterProvider
  ) {
    //TODO: Record the current Route and set it later
    if (
      window.location.hash.indexOf("#code") == -1 &&
      window.location.hash != "#/"
    ) {
      sessionStorage[ApplicationConstants.RequestStateName] =
        window.location.hash.substring(1, window.location.hash.length);
    }

    $urlRouterProvider.otherwise("/");

    $stateProvider.state("Home", {
      url: "/",
      requireADLogin: true,
      data: {
        headerName: "Home",
        partnerTelemetryName: "FXP",
        pageTitle: "Home",
        appNameForTelemetry: "FxpPlatform",
        pageNameForTelemetry: "Home",
        featureName: "FxPHomePage",
        actionName: "Login",
      },
      telemetryConfig: {
        pageNameForTelemetry: "Home",
      },
    });
  };

  /**
   * An angular configuration method to initialize adalAuthenticationServiceProvider
   * @method Fxp.Boot.FxpBootstrap.authenticationInit
   */
  public static authenticationInit = function (
    $httpProvider: angular.IHttpProvider
  ) {
    var config = window["ModelConfiguration"];
    var partnerEnpoints = PartnerAppRegistrationService.getRegisterEndpoints();
    var endpoints = JSON.parse(
      JSON.stringify(eval("(" + "{" + config.Endpoints + "}" + ")")) // CodeQL [SM04509] Intent is to set a config value 
    );
    endpoints = CommonUtils.jsonConcat(endpoints, partnerEnpoints);
  };
  // adalAuthenticationServiceProvider.init(
  // 	{
  // 		instance: config.Instance,
  // 		tenant: config.Tenant,
  // 		clientId: config.ClientId,
  // 		extraQueryParameter: 'nux=1',
  // 		endpoints: endpoints,
  // 		cacheLocation: 'localStorage',
  // 		frameRedirectUri: window.location.protocol + "//" + location.host + "/AdalIFrame.html?v=1"
  // 	}, $httpProvider);
  // };

  // private static loginEvent = function($cookies: angular.cookies.ICookiesService, $rootScope: IRootScope, adalAuthenticationService: adal.AdalAuthenticationService, userProfileService: UserProfileService, fxpMessage: FxpMessageService, fxpConfiguration: FxpConfigurationService, fxpContextService: FxpContext, fxpTelemetryContext: TelemetryContext, fxpLoggerService: ILogger, oboUserService: OBOUserService, fxpGlobalStoreService: FxpGlobalStoreService, timeout, appControllerHelper: AppControllerHelper, $http: angular.IHttpService) {
  // 	fxpLoggerService.logTrace(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBoot", "Login succeeded. ");
  // 	 	let currentTime = new Date().toLocaleString();
  // 		let propbag = fxpLoggerService.createPropertyBag();
  // 		propbag.addToBag('OnSuccessGetAdalTokenTime', `${currentTime}`);
  // 		let eventMessage = 'Fxp adal authentication completed successfully';

  // 		let eventData = new SystemEvent(".OnMsalLoginSuccess", ComponentType.Web, eventMessage);
  // 		fxpLoggerService.logSystemEvent(TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap", eventData, propbag);

  // 		FxpBootstrap.onloginSuccessSubcription();
  // 		FxpBootstrap.onLoginSuccess($cookies, adalAuthenticationService, fxpMessage, $rootScope, fxpContextService, fxpTelemetryContext, fxpLoggerService, oboUserService, appControllerHelper, fxpConfiguration);
  // 		FxpBootstrap.evaluateFaultFeatureFlag($http, fxpConfiguration, $rootScope, fxpGlobalStoreService, adalAuthenticationService.userInfo.userName)
  // }

  /**
   * An angular Run for Initializing FXP APP
   * @method Fxp.Boot.FxpBootstrap.init
   */
  public static fxpRunInit = function (
    $cookies: angular.cookies.ICookiesService,
    $rootScope: IRootScope,
    userInfoService: UserInfoService,
    fxpMessage: FxpMessageService,
    fxpConfiguration: FxpConfigurationService,
    fxpContextService: FxpContext,
    fxpTelemetryContext: TelemetryContext,
    fxpLoggerService: ILogger,
    oboUserService: OBOUserService,
    fxpGlobalStoreService: FxpGlobalStoreService,
    appControllerHelper: AppControllerHelper,
    $http: angular.IHttpService,
    msalAuthenticationService: MsalAuthenticationService
  ) {
    const processName =
      TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP + ".FxPInitProcess";
    const rootScopeService = FxpRootScopeService.getInstance();
    $rootScope.isAuthenticated = false;
    //rootScopeService.setRootScopeField('actOnBehalfOfUserActive', false);
    $rootScope.actOnBehalfOfUserActive = false;
    $rootScope.isUserInfoAvailable = false;
    rootScopeService.setRootScopeField('isUserInfoAvailable', false);
    $rootScope.fxpUIConstants =
      fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings;
    rootScopeService.setRootScopeField("fxpUIConstants", fxpConfiguration.FxpBaseConfiguration.FxpConfigurationStrings); 

    $rootScope.appBooted = true;
    fxpGlobalStoreService.DispatchGlobalAction(
      "Platform",
      ShowLoader({
        loadingText:
          $rootScope.fxpUIConstants.UIStrings.LoadingStrings.Authenticating,
        timeoutCallback: appControllerHelper.handleAdalErrorsAuthenticating,
      })
    );
    // localStorage["adalConfig"] = JSON.stringify(adalAuthenticationService.config);
    if (sessionStorage[ApplicationConstants.RequestStateName] != undefined) {
      $cookies.put(
        "requestedUrl",
        sessionStorage[ApplicationConstants.RequestStateName]
      );
    }
    let customProperties = FxpBootstrap.getCommonProperties();
    customProperties.addToBag(TelemetryConstants.METHODNAME, "fxpRunInit");
    let eventData = new SystemEvent(
      processName,
      ComponentType.Web,
      "Authentication Process Started"
    );
    fxpLoggerService.logSystemEvent(
      TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBoot",
      eventData,
      customProperties
    );

    FxpBootstrap.onloginSuccessSubcription = $rootScope.$on(
      "msal:loginSuccess",
      function () {
        fxpLoggerService.logTrace(
          TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBoot",
          "Login succeeded. "
        );
        let currentTime = new Date().toLocaleString();
        let propbag = fxpLoggerService.createPropertyBag();
        propbag.addToBag("OnSuccessGetAdalTokenTime", `${currentTime}`);
        let eventMessage = "Fxp adal authentication completed successfully";

        let eventData = new SystemEvent(
          ".OnMsalLoginSuccess",
          ComponentType.Web,
          eventMessage
        );
        fxpLoggerService.logSystemEvent(
          TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
          eventData,
          propbag
        );
        console.log("Logged in");
        userInfoService.setCurrentUser(
          msalAuthenticationService.getAccount().username
        );
        userInfoService.setCurrentUserOID(
          msalAuthenticationService.getAccount().localAccountId
        );
        userInfoService.setCurrentUserUpn(
          msalAuthenticationService.getAccount().username
        );
        FxpBootstrap.onLoginSuccess(
          $cookies,
          fxpMessage,
          $rootScope,
          fxpContextService,
          fxpTelemetryContext,
          fxpLoggerService,
          oboUserService,
          appControllerHelper,
          fxpConfiguration,
          msalAuthenticationService
        );
        if (fxpConfiguration.FxpAppSettings.MakeFaultFeatureFlagEvaluateCall && JSON.parse(fxpConfiguration.FxpAppSettings.MakeFaultFeatureFlagEvaluateCall)) {
          FxpBootstrap.evaluateFaultFeatureFlag(
            $http,
            fxpConfiguration,
            $rootScope,
            fxpGlobalStoreService,
            userInfoService.getCurrentUserData()
          );
        }
        else {
          $rootScope.isAuthenticated = true;
          $rootScope.$broadcast("fxp:loginSuccess");
        }

      }
    );

    msalAuthenticationService.login($rootScope);
  };

  public static evaluateFaultFeatureFlag(
    $http,
    fxpConfiguration,
    $rootScope,
    globalStoreService,
    userName
  ) {
    var flightingHeader = {
      headers: {
        "x-FlightContext": JSON.stringify({ username: userName }),
        "x-application": fxpConfiguration.FxpAppSettings.ApplicationName,
        "x-environment":
          fxpConfiguration.FxpAppSettings.FlightingEnvironmentName,
      },
    };
    $http
      .get(
        `${fxpConfiguration.FxpAppSettings.FlightingServiceEndpoint}/Evaluate?featureNames=${fxpConfiguration.FxpAppSettings.FaultFeatureFlagName}`,
        flightingHeader
      )
      .then(function (response) {
        globalStoreService.DispatchGlobalAction(
          "Platform",
          UpsertFeatureFlags(response.data)
        );
        $rootScope.isAuthenticated = true;
        $rootScope.$broadcast("fxp:loginSuccess");
      });
  }

  public static onLoginFailure(
    fxpLoggerService: ILogger,
    msalAuthenticationService,
    error,
    appControllerHelper: AppControllerHelper,
    source,
    errorDesc: any,
    $rootScope: angular.IRootScopeService
  ) {
    const processName =
      TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP +
      ".AuthenticationProcess";
    try {
      let errorDetails = CommonUtils.objectToString(error);
      let errorDesciption = CommonUtils.objectToString(errorDesc);
      let errorSource = CommonUtils.objectToString(source);
      if (!errorDetails) {
        errorDetails = "Session timed out. ";
      }

      let currentTime = new Date().toLocaleString();
      let propbag = fxpLoggerService.createPropertyBag();
      propbag.addToBag("ErrorDesc", errorDesciption);
      propbag.addToBag("OnFailureGetMsalTokenTime", `${currentTime}`);
      propbag.addToBag("ErrorSource", errorSource);
      propbag.addToBag(
        "LocalStorage-ErrorDes",
        localStorage["msal.error.description"]
      );

      let message = `Fxp msal login failure with error - ${errorDetails}`;
      fxpLoggerService.logError(
        `${FxpBootstrap.sourceForTelemetry}.OnLoginFailure`,
        message,
        ErrorCodes.OnMsalLoginFailure,
        errorDesciption,
        propbag,
        null,
        null,
        ErrorSeverityLevel.Critical
      );

      console.error(errorDetails);

      let eventMessage = `Fxp msal login failure with error - ${errorDetails} and login popup displayed.`;
      let eventData = new SystemEvent(
        processName + ".LoginFailed",
        ComponentType.Web,
        eventMessage
      );
      fxpLoggerService.logSystemEvent(
        TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
        eventData,
        propbag
      );
    } catch (e) {
      // LogEvent will fail if the user session is expired.
      console.error(
        "An error occurred while executing msal:loginFailure event"
      );
      console.error(e);
    } finally {
      console.error(
        "Login Expired from onLoginFailure method.. Triggering login."
      );
      let eventMessage = ``;
      let eventData = new SystemEvent(
        processName + ".LoginExpiredPopupDisplayed",
        ComponentType.Web,
        eventMessage
      );
      fxpLoggerService.logSystemEvent(
        TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
        eventData
      );

      // check below message and also check why automatic relogin happens without button click
      appControllerHelper.showModalPopupWindow(
        "Login Expired",
        "Login expired due to the session timeout. Please login again.",
        "Login",
        function () {
          fxpLoggerService.logUserAction(
            TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
            "Re-Login button click",
            "User clicked Relogin button. Triggered re-login process."
          );
          msalAuthenticationService.login($rootScope);
        }
      );
    }
  }

  /**
   * A method that is called on every success login. sets the user contexts
   * @method Fxp.Boot.FxpBootstrap.onLoginSuccess
   */
  public static onLoginSuccess = function (
    $cookies: angular.cookies.ICookiesService,
    fxpMessage: FxpMessageService,
    $rootScope: IRootScope,
    fxpContextService: FxpContext,
    fxpTelemetryContext: TelemetryContext,
    fxpLoggerService: ILogger,
    oboUserService: OBOUserService,
    appControllerHelper: AppControllerHelper,
    fxpConfiguration: FxpConfigurationService,
    msalAuthenticationService: MsalAuthenticationService
  ) {
    const processName =
      TelemetryConstants.FXP_TELEMETRY_PLATFORM_BOOTSTRAP +
      ".AuthenticationProcess";
    const source_telemetry: string = `${FxpBootstrap.sourceForTelemetry}.OnLoginSuccess`;
    if (FxpBootstrap.onloginSuccessSubcription) {
      // unsubscribe the 'adal:loginSuccess' event.
      FxpBootstrap.onloginSuccessSubcription();
    }

    let loginFailureHandler = $rootScope.$on(
      "msal:loginFailure",
      function (source, errorDesc, error, error_code) {
        loginFailureHandler(); //unsubscribe the event.
        fxpMessage.showBanner = false;
        FxpBootstrap.onLoginFailure(
          fxpLoggerService,
          msalAuthenticationService,
          error,
          appControllerHelper,
          source,
          errorDesc,
          $rootScope
        );
      }
    );

    let acquiretokenFailure = $rootScope.$on(
      "msal:acquireTokenFailure",
      function (source, errorDesc, err, resource, error_code) {
        let error = CommonUtils.objectToString(err);
        let bag = fxpLoggerService.createPropertyBag();
        bag.addToBag("ErrorDesc", CommonUtils.objectToString(errorDesc));
        bag.addToBag("ErrorResource", CommonUtils.objectToString(resource));
        bag.addToBag("ErrorSource", CommonUtils.objectToString(source));
        bag.addToBag("MsalError", error);
        bag.addToBag(
          "LocalStorage-ErrorDes",
          localStorage["Msal.error.description"]
        );
        fxpLoggerService.logException(
          source_telemetry,
          new Error(`TokenAcquisitionFailed with error ${error}`),
          bag,
          null,
          null,
          ErrorSeverityLevel.Medium,
          ErrorCodes.OnMsalTokenFailure
        );

        if (err === EventType.ACQUIRE_TOKEN_FAILURE) {
          $rootScope.isLoginRequired = true;
          fxpMessage.showBanner = true;
          acquiretokenFailure(); // unsubscribe
          let eventData = new SystemEvent(
            processName + ".LoginRequired",
            ComponentType.Web,
            "Login Required by Msal. Diplayed Login Expired popup window. "
          );
          fxpLoggerService.logSystemEvent(
            TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
            eventData
          );
          console.error(
            "Login expired during token acquisition. Invoking login from Msal:acquireTokenFailure handler"
          );

          if (error_code === undefined || (error_code !== "monitor_window_timeout")) {
            fxpMessage.addMessage(
              "Authorization failed. Please contact IT Support.",
              FxpConstants.messageType.error,
              true
            );
          }
          else if (error_code !== undefined && error_code === "monitor_window_timeout") {
            fxpLoggerService.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Monitor_Timeout_Window_Error`, 'Monitor_Timeout_Window_Error', bag);

            appControllerHelper.showModalPopupWindow(
              "Login Expired",
              "Login expired due to the session timeout. Please login again.",
              "Login",
              function () {
                fxpLoggerService.logUserAction(
                  TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
                  "Re-Login button click",
                  "User clicked Relogin button. Triggered re-login process."
                );
                const self = this;
                self.fxplogger.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.Monitor_Timeout_Window.reLaunch`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.SessionTimeoutModal.RelaunchButtonClicked`);
                localStorage.clear();
                window.location.reload();
              }
            );
          }
        } else {
          let message = `AcquireTokenFailure with error ${err}`;
          fxpLoggerService.logError(
            source_telemetry,
            message,
            ErrorCodes.AcquireTokenFailure_UnKnown_Reason,
            errorDesc,
            bag,
            null,
            null,
            ErrorSeverityLevel.Medium
          );
          fxpMessage.addMessage(
            "Authorization failed. Please contact IT Support.",
            FxpConstants.messageType.error,
            true
          );
          // msalAuthenticationService.login($rootScope);
        }
      }
    );

    // localStorage.setItem("AADBasicInfo", JSON.stringify(adalAuthenticationService.userInfo));

    FxpBootstrap.setTelemetryUserContext(
      fxpLoggerService,
      fxpConfiguration,
      msalAuthenticationService
    );
    let eventData = new SystemEvent(
      processName + ".LoginProcessCompleted",
      ComponentType.Web,
      "Login succeded."
    );
    fxpLoggerService.logSystemEvent(
      TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
      eventData
    );

    fxpLoggerService.startTrackPerformance(PerfMarkers.FxpLoad);
    fxpLoggerService.startTrackPerformance(PerfMarkers.PreDashboardLoad);
    var requestedUrlCookie = $cookies.get("requestedUrl");
    sessionStorage[ApplicationConstants.RequestStateName] = requestedUrlCookie;

    $cookies.remove("requestedUrl");

    // if (msalAuthenticationService.getAccount().userInfo &&
    // 	msalAuthenticationService.getAccount().userInfo.profile &&
    // 	msalAuthenticationService.getAccount().userInfo.profile.ipaddr) {
    // 	fxpTelemetryContext.setGeography(adalAuthenticationService.userInfo.profile.ipaddr);
    // }

    var sessionIdCached = window.sessionStorage.getItem(
      ApplicationConstants.FxpSessionKey
    );
    var sessionId: any;
    if (CommonUtils.isNullOrEmpty(sessionIdCached)) {
      sessionId = fxpTelemetryContext.setNewUserSession(
        msalAuthenticationService.getAccount().username
      );
      window.sessionStorage.setItem(
        ApplicationConstants.FxpSessionKey,
        sessionId
      );

      var propbag = fxpLoggerService.createPropertyBag();
      propbag.addToBag(
        "UserId",
        msalAuthenticationService.getAccount().username
      );
      propbag.addToBag("SessionId", sessionId);
      let eventData = new SystemEvent(
        processName + ".UserSessionCreated",
        ComponentType.Web,
        "Login succeded."
      );
      fxpLoggerService.logSystemEvent(
        TelemetryConstants.FXP_TELEMETRY_BASE_NAME + ".FxpBootstrap",
        eventData,
        propbag
      );
    } else {
      sessionId = fxpTelemetryContext.setNewUserSession(
        msalAuthenticationService.getAccount().username,
        sessionIdCached
      );
    }

    fxpContextService.saveContext("sessionId", sessionId);
    $rootScope.sessionId = sessionId;
  };

  public static setTelemetryUserContext(
    fxpLoggerService: ILogger,
    fxpConfiguration: FxpConfigurationService,
    msalAuthenticationService: MsalAuthenticationService
  ) {
    // Below code commented after utp app id deletion
    /* const utpApiClientId = fxpConfiguration.FxpAppSettings.UTP_API_ClientId;
    msalAuthenticationService.getTokenForAppIdAsync(utpApiClientId).then(
      (token) => {
        //fxpLoggerService.setLoggedInUserContextInUTP(token);
      },
      (error) => {
        fxpLoggerService.logException(
          `${FxpBootstrap.sourceForTelemetry}.SetTelemetryUserContext`,
          error,
          null,
          null,
          null,
          ErrorSeverityLevel.Medium,
          ErrorCodes.OnTelemetryContextError
        );
      }
    ); */
  }

  /**
   * An angular Run for assigning rootScope Event handlers
   * @method Fxp.Boot.FxpBootstrap.rootScopeEventHandler
   */
  public static rootScopeEventHandler = function (
    $rootScope: IRootScope,
    fxpAuthorizationService: FxpAuthorizationService,
    fxpConfigurationService: FxpConfigurationService,
    plannedDownTimeService: PlannedDownTimeService,
    state: FxpStateService,
    fxpStateTransitionService: FxpStateTransitionService,
    fxpLoggerService: ILogger
  ) {
    fxpStateTransitionService.onStateChangeStart((item) => {
      // next is an object that is the route that we are starting to go to
      // current is an object that is the route where we are currently

      const source = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpStateTransitionService.onStateChangeStart`;
      try {
        $rootScope.stateChangeStartTime = performance.now();
        var currentPath = item.fromState.name;
        var nextPath = item.toState.name;
        let propBag = fxpLoggerService.createPropertyBag();
        propBag.addToBag("CurrentState", currentPath);
        propBag.addToBag("DestinationState", nextPath);
        propBag.addToBag(
          "StateChangeStartTime",
          parseFloat($rootScope.stateChangeStartTime).toString()
        );
        fxpLoggerService.logEvent(
          source,
          `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.StateChange.Started`,
          propBag
        );

        var preStateDuration = 0;
        var displayName = "";
        if (currentPath == nextPath) {
          $rootScope.startTime = 0;
        }

        if ($rootScope.startTime) {
          preStateDuration =
            $rootScope.stateChangeStartTime - $rootScope.startTime;
          displayName = $rootScope.displayName || "";
        }

        $rootScope.$emit("resetPageLoadMetrics");
        $rootScope.pageLoadMetrics.pageTransitionStatus = "stateChangeStart";
        $rootScope.pageLoadMetrics.sourceRoute = currentPath;
        $rootScope.pageLoadMetrics.destinationRoute = nextPath;
        $rootScope.pageLoadMetrics.preStateDuration = preStateDuration;
        $rootScope.pageLoadMetrics.pageDisplayName = displayName;
        $rootScope.pageLoadMetrics.sourceRouteURL = item.fromState.templateUrl;
        $rootScope.pageLoadMetrics.destinationRouteURL =
          item.toState.templateUrl;

        fxpAuthorizationService.checkStatePermission(event, item.toState);

        if (plannedDownTimeService.isStateDown(nextPath)) {
          if (event) {
            event.preventDefault();
          }
          state.go("SystemDown");
        }
      } catch (err) {
        const errorProperites = fxpLoggerService.createPropertyBag();
        errorProperites.addToBag("Message", err.message);
        errorProperites.addToBag(
          "ErrorCode",
          ErrorCodes.OnStateChangeStartException
        );

        fxpLoggerService.logException(
          source,
          err,
          errorProperites,
          undefined,
          undefined,
          ErrorSeverityLevel.Critical
        );
      }
    });
  };
}
