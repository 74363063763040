import { IDataService } from "../interfaces/IDataService";
import { ILogger } from "../interfaces/ILogger";
import { Observable } from "rxjs";
import { IFxPService } from "../interfaces/IFxpService";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { MsalAuthenticationService } from "./MsalAuthenticationService";
import { FxpHttpClientService } from "./FxpHttpClientService";
import * as Q from 'q';

/**
* @application  Fxp
*/
/**
* @module Fxp.Utils.Services
*/
export class DataService implements IDataService, IFxPService {

	private sleepInterval: number = 100;
	private retryCount = {};
	private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.DataService`;

	constructor(
		private http: FxpHttpClientService,
		private msalAuthenticationService: MsalAuthenticationService,
		private fxploggerService: ILogger) {
	}

	public get(url: string, parentDeferred: any): Q.Promise<any> {
		return this.getWithAdalTokenSync(url, parentDeferred);
	}

	public getAsObservable(url: string): Observable<any> {
		return Observable.create(observer => {
			this.getWithAdalTokenSync(url, null).then((data) => {
				observer.next(data);
				observer.complete();
			}).catch((error) => {
				observer.error(error);
			})
		})

	}
	private getWithAdalTokenSync(url: string, parentDeferred: any): Q.Promise<any> {
		var self = this;
		var deferred = parentDeferred || Q.defer();

		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			var retryCountForUrl = self.retryCount[url] || 0;
			retryCountForUrl++;
			self.retryCount[url] = retryCountForUrl;
			if (retryCountForUrl == 5) {
				delete self.retryCount[url];
				var propBag = self.fxploggerService.createPropertyBag();
				propBag.addToBag('ServiceUrl', url);

				self.fxploggerService.logError(`${self.sourceForTelemetry}.GetWithAdalTokenSync`, 'AdalTokenAquisitionFailed', ErrorCodes.DataService_TokenFailure, null, propBag, null, null, ErrorSeverityLevel.Medium);

				return deferred.promise;
			}
			setTimeout(function () {
				self.getWithAdalTokenSync(url, deferred);
			}, self.sleepInterval);
		}
		else {
			delete self.retryCount[url];
			return Q.Promise((resolve, reject) => {
				this.http.get(url)
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}

		return deferred.promise;
	}
}

