import { FxpPartnerHttpInterceptorHooks } from "../services/FxpPartnerHttpInterceptorHooks";
import * as Q from 'q';

/**
	 *  Interceptor provided by fxp team, to invoke all the partner registered interceptors for their http requests .Done sequentially
	 */
export function FxpPartnerHttpRequestInterceptor(fxpPartnerHttpInterceptorHooks: FxpPartnerHttpInterceptorHooks) {
		var requestInterceptors = fxpPartnerHttpInterceptorHooks.getRegisteredInterceptorHooks();
		var fxpPartnerRequestInterceptor = {
			request: function(config) {
				if (requestInterceptors == []){
					return config;
				}
				requestInterceptors.forEach(interceptor => {
					if (interceptor.request){

						let updatedConfig = interceptor.request(config);
						if (updatedConfig){
							config = updatedConfig;
						}
					}			
				 	
				});
				return config;
			},
			requestError: function(rejection) {
				// do something on error
				requestInterceptors.forEach(interceptor => {	
					if (interceptor.requestError){
						interceptor.requestError(rejection);
						
					}		
					
			    });
				return Q.reject(rejection);
			},
			response: function(response) {
				// do something on success
				requestInterceptors.forEach(interceptor => {	
					if (interceptor.response){
						interceptor.response(response);
					}		
				});		
				return response;
			},
			responseError: function(rejection) {
				// do something on error
				requestInterceptors.forEach(interceptor => {	
					if (interceptor.responseError){
						interceptor.responseError(rejection);
					}		
				});	

				return Q.reject(rejection);
			}
		};
	return fxpPartnerRequestInterceptor;
}


