import { Subject } from 'rxjs';
import { IRootScope } from '../interfaces/IRootScope';
import { CommonUtils } from '../utils/CommonUtils';
import { IFxPService } from '../interfaces/IFxpService';
import { FxpRootScopeService } from './FxpRootScopeService';

export class FxpEventBroadCastService implements IFxPService {
	private rootScopeService: FxpRootScopeService;
	constructor(private $rootScope: IRootScope) {
		this.rootScopeService = FxpRootScopeService.getInstance();
	}
	public On(eventName: string, callBack: any): any {
		var self = this;
		return self.$rootScope.$on(eventName, callBack);
	}
	public on(eventName: string, callBack: any): any {
		var self = this;
		return self.rootScopeService.on(eventName, callBack);

	}
	public broadCast(eventName: string, value: any) {
		var self = this;
		self.$rootScope.$broadcast(eventName, value);
		self.rootScopeService.broadcast(eventName, value);
	}

	public emit(eventName: string, callBack: any) {
		var self = this;
		self.$rootScope.$emit(eventName, callBack);
	}

	private getSubjectInstance(): any {
		var subject = new Subject();
		return subject;
	}

}
