import { FxpBootstrap } from "./boot/fxpboot";
import { ISettingsServiceProvider } from "./interfaces/ISettingsServiceProvider";
import { FxpBotServiceProvider } from "./provider/FxpBotServiceProvider";
import { GlobalExceptionHandler } from "./telemetry/GlobalExceptionHandler";
import { SettingsServiceProvider } from "./provider/SettingsServiceProvider";
import { NonFxpApp } from "./iframe_app/iframeAppController";
import { Fxp } from "./module-declarations";
import { FxpConfigurationService } from "./services/FxpConfiguration";
import { FxpStateTransitionService } from "./services/FxpStateTransitionService";
import { FxpLoggerServiceExtension } from "./telemetry/FxpLoggerServiceExtension";
import { UserProfileService } from "./services/userProfileService";
import { UserClaimsService } from "./services/UserClaimsService";
import { UserInfoService } from "./services/UserInfoService";
import { TimeZoneHelper } from "./services/TimeZoneHelper";
import { SystemMessagesService } from "../app/systemMessages/SystemMessagesService";
import { PlannedDownTimeService } from "../app/systemMessages/PlannedDownTimeService";
import { PersonalizationService } from "./services/PersonalizationService";
import { pageTourEventService } from "./services/pageTourEventService";
import { PageLoaderService } from "../app/loader/pageLoaderService";
import { OBOUserService } from "./services/OBOUserService";
import { NotificationStore } from "./services/NotificationStore";
import { NotificationService } from "./services/NotificationService";
import { NotificationActionCenter } from "./services/NotificationActionCenter";
import { HelpCentralService } from "./services/HelpCentralService";
import { FxpStorageService } from "./services/FxpStorageService";
import { LazyLoaderService } from "./services/lazyLoaderService";
import { FxpRouteService } from "./services/FxpRouteService";
import { FxpMessageService } from "../app/banner/FxpMessageService";
import { FxpFeedbackService } from "./services/FxpFeedbackService";
import { FxpBreadcrumbService } from "./services/FxpBreadcrumbService";
import { FxpAuthorizationService } from "./services/FxpAuthorizationService";
import { DashboardService } from "./services/dashboardService";
import { AdminLandingService } from "../app/adminTiles/AdminLandingService";
import { TelemetryConfiguration } from "./telemetry/TelemetryConfiguration";
import { TelemetryContext } from "./telemetry/telemetrycontext";
import { PouchDBProvider } from "./common/PouchDBProvider";
import { FxpContext } from "./context/FxpContext";
import {
  httpCorrelationInterceptor,
  httpRetryInterceptor,
  MsalHttpRequestInterceptor,
} from "./factory/FxpHttpInterceptorFactory";
import { AppControllerHelper } from "./factory/AppControllerHelper";
import { DeviceFactoryProvider } from "./utils/devicefactoryService";
import { SYSTEM_MESSAGE_UI } from "./constants/systemMessages.constants";
import { AppController } from "./controllers/appCntrl";
import { FxpUIData } from "./factory/FxpUIDataFactory";
import { SessionTimeoutModalFactory } from "./factory/SessionTimeoutModalFactory";
import { SessionTimeoutModalConstant } from "./constants/SessionTimeoutModal.constants";
import { FxpModalConstant } from "./constants/FxpModal.constants";
import { ToastNotificationController } from "./controllers/toastNotificationController";
import { DataService } from "./services/DataService";
import { FxpHttpService } from "./services/FxpHttpService";
import { ActOnBehalfOfController } from "./controllers/actOnBehalfOfController";
import { AdminLandingController } from "../app/adminTiles/AdminLandingController";
import { CreateAskOpsController } from "./controllers/createAskOpsController";
import { CreateSupportTicketController } from "./controllers/createSupportTicketController";
import { DashBoardController } from "./controllers/dashBoardController";
import { HelpArticleImageController } from "./controllers/helpArticleImageController";
import { LeftNavPersonalizationController } from "./controllers/leftNavPersonalizationController";
import { PowerBiPageController } from "./controllers/powerBIPageController";
import { RoleNavPersonalizationController } from "./controllers/roleNavPersonalizationController";
import { SessionTimeoutModalController } from "./controllers/SessionTimeoutModalController";
import { SystemMessagesController } from "../app/systemMessages/systemMessagesController";
import { UserLookupPersonalizationController } from "./controllers/userLookupPersonalizationController";
import { CreateAskOpsModalConstant } from "./constants/CreateAskOpsRequest.constants";
import { CreateSupportTicketModalConstant } from "./constants/createSupportTicketRequest.constants";
import { AuthorNotificationConstant } from "./constants/AuthorNotification.constants";
import { HelpArticleImageModalConstant } from "./constants/HelpArticle.constants";
import { SkipToMainContent } from "./directives/fxpSkipToMainContent.directive";
import {
  AuthorNotificationConfirmationController,
  AuthorNotificationController,
} from "./controllers/authorNotificationController";
import { FxpUIStateHelperProvider } from "./provider/UIStateHelperProvider";
import { DashBoardHelper } from "./factory/DashBoardHelper";
import { ActOnBehalfOfHelper } from "./factory/ActOnBehalfOfHelper";
import { AuthorNotificationRoleGroupHelper } from "./factory/AuthorNotificationRoleGroupHelper";
import { ChangeTabIndexByClassDirective } from "./directives/changeTabIndexByClass";
import { AuthorNotificationContentDirective } from "./directives/fxpAuthorNotification.directive";
import { FxpAuthorNotificationListDirective } from "./directives/fxpAuthorNotificationList.directives";
import { BreadcrumbDirectives } from "./directives/fxpBreadcrumb.directive";
import { fxpBreadcrumbLink } from "./directives/fxpBreadcrumbLink";
import { fxpChoiceItem } from "./directives/fxpChoiceControl.directive";
import { FxpGoToTopDirective } from "./directives/fxpGoToTop.directive";
import { FxpHelpDirectives } from "./directives/fxpHelpEvents";
import { FxpIncludeStaticTemplate } from "./directives/fxpIncludeStaticTemplate";
import { FxpComponentDirectives } from "./directives/fxpItems.directive";
import { FxpKeyDownDirective } from "./directives/fxpKeyDown.derective";
import { fxpLeftNavKeydown } from "./directives/fxpLeftNavKeydown";
import { LeftnavLink } from "./directives/fxpLeftnavLink";
import { FxpNotification } from "./directives/fxpNotificationKeyEventHandler.directive";
import { FxpNotificationSetFocusDirective } from "./directives/fxpNotificationSetFocus.directive";
import { FxpSetFocusToElement } from "./directives/fxpSetFocus";
import { FxpShowIfDeviceDirective } from "./directives/fxpShowIfDevice";
import {
  fxpSystemMessageRow,
  fxpSystemMessagePopupDirective,
} from "./directives/fxpSystemMessage.directives";
import { FxpToastNotificationDirectives } from "./directives/fxpToastNotification.directive";
import { LeftNavOutsideClickDirective } from "./directives/leftNavOutsideClick";
import { RenderNavElementsForKeydownDirective } from "./directives/leftNavPersonalization.directive";
import { LeftNavPrsonalizationAdjustScrollDirective } from "./directives/leftNavPersonalizationAdjustScroll.directive";
import { RenderPopUpForKeydownDirective } from "./directives/leftNavPersonalizationPopUp.directive";
import { LeftNavPersonalizationScreenReaderDirective } from "./directives/leftNavPersonalizationScreenReader";
import { LeftNavPersonalizationScrollToActiveDirective } from "./directives/leftNavPersonalizationScrollToActive";
import { MultilineEllipsisDirective } from "./directives/multilineEllipsis";
import { MultilineEllipsisWithAccordionDirective } from "./directives/multilineEllipsisWithAccordion";
import { IntervalFlipDirective } from "./directives/ngLongPress";
import { FeedbackFlyoutDirective } from "./directives/ngRightClick";
import { ScrollToTopDirective } from "./directives/ScrollToTopDirective";
import { Resiliency } from "./resiliency/FxpResiliency";
import { UnCamelCase } from "./filters/unCamelCase.filter";
import { AppDirectives } from "./directives/AppDirectives";
import { AdminLandingDirective } from "./directives/adminLanding.directive";
import { FxpProviders } from "./provider/CorrelationProvider";
import { Subject } from "rxjs";
import { FeatureFlagAngular1Module } from "@fxp/flightingsdk";
import { FxpEventBroadCastService } from "./services/BroadCastingService";
import { FxpWelcomeController } from "./controllers/fxpWelcomeController";
import { ModuleName } from "./../fxptemplates";
import { StickyStatesPlugin } from "@uirouter/sticky-states";
import { FxpPartnerHttpInterceptorHooks } from "./services/FxpPartnerHttpInterceptorHooks";
import { FxpPartnerHttpRequestInterceptor } from "./factory/FxpPartnerHttpInterceptorHandler";
import { FxpBotLauncherComponent } from "./../../src/app/components/fxpbot/fxpbot.component";
import {
  downgradeComponent,
  downgradeInjectable,
} from "@angular/upgrade/static";
import { FxpGlobalStoreService } from "./services/fxp.global.store.service";
import { FxpIrisBotService } from "./services/FxpIrisBotService";
import { Environment } from "./common/enum/environment.enum";
import { PartnerAppRegistrationService } from "./services/PartnerAppRegistrationService";
import { FxpOCVFeedbackService } from "./services/FxpOCVFeedbackService";
import { FxpSignalRService } from "./services/FxpSignalRClient";
import { FxpSharedComponentLoader } from "@fxp/fxpsharedcomponentloader";
import { FxpLoggerService } from "./telemetry/fxpLogger";
import { UserFeedbackUIService } from "./services/UserFeedbackUIService";
import { LogLevel } from "@azure/msal-browser";
import { AdalLoginHelperService } from "./services/AdalLoginHelperService";
import { MsalAuthenticationServiceProvider } from "./services/MsalAuthenticationService";
import { UserAnnouncementUIService } from "./services/UserAnnouncementUIService";
import { PageTourLauncherComponent } from "../app/components/pageTour-Component/pageTour.component";
import { SharedComponentLoader } from "@fxp/angularcontrols";
import { FxpMessagesComponent } from "../app/components/messages-component/fxpmessages.component";
import {
  FxpStateService,
  FxpTransitionService,
} from "../app/services/FxpStateRoutingHelperService";
import { FxpHeaderComponent } from "../app/components/header-component/fxpheader.component";
import { FxpLeftNavigationComponent } from "../app/components/fxpleftnavigation-component/fxpleftnavigation.component";
import { FxpHelpMenuComponent } from "../app/components/fxphelp/fxphelp.component";
import { FxpFooterComponent } from "../app/components/fxpfooter/fxpfooter.component";
import { FxpSystemUpdateMsgComponent } from "../app/components/fxpsystemupdatemsg/fxpsystemupdatemsg.component";
import { BrowserExperienceComponent } from "../app/components/browserExperience/browserExperience.component";
import { FxpHttpClientService } from "./services/FxpHttpClientService";
import {
  AddAuthTokenInterceptor,
  AddHeadersInterceptor,
  AddPartnerInterceptor,
  RetryInterceptor,
} from "../app/services/FxpHttpClientInterceptorFactory";
import { ConfitAngular1Module } from "../../externalLibraries/confitsdk";



FeatureFlagAngular1Module.registerModule();
ConfitAngular1Module.registerModule();

declare type FeatureFlagServiceProvider = any;

var di = [
  ModuleName,
  "oc.lazyLoad",
  NonFxpApp,
  "vx.grid.modules",
  "AdalAngular",
  "ui.router",
  "ui.bootstrap",
  "ngAnimate",
  "ngResource",
  "ngSanitize",
  "ngRoute",
  "FXPComponents",
  "FxpUtils",
  "Telemetry",
  "ngCookies",
  ConfitAngular1Module.ConfitNg1ModuleName,
  "angularCSS",
  "angularMoment",
  "ngMessages",
  "Microsoft.PS.Feedback",
  FeatureFlagAngular1Module.FlightingNg1ModuleName,
  "ActivityMonitor",
  "ng.deviceDetector",
  "ui.router.state.events",
];

//To remove cannot find name error
var PartnerModules = PartnerModules || window["PartnerModules"] || [];
var FxpAppSettings = FxpAppSettings || window["FxpAppSettings"] || {};
var PreLoadModules = PreLoadModules || window["PreLoadModules"] || [];
var TenantConfiguration =
  TenantConfiguration || window["tenantConfiguration"] || {};
var ocLazyLoadDebugFlag = window.localStorage["ShowLazyLoadLogs"] || false;
if (PartnerModules) di = di.concat(PartnerModules);

angular
  .module("FxpUtils", [])
  .service("AdalLoginHelperService", [
    "MsalAuthenticationService",
    AdalLoginHelperService,
  ]);

export var fxpModule = angular
  .module("FxPApp", di)
  .constant("confitRootUrl", FxpAppSettings.ConfitServiceEndPoint)
  .constant("StartUpFlightConfig", {
    appName: FxpAppSettings.ApplicationName,
    envName: FxpAppSettings.FlightingEnvironmentName,
    featureNames: TenantConfiguration.FlightingConfiguration.FeatureFlags,
  })
  .constant("CreateAskOpsModalConstant", CreateAskOpsModalConstant)
  .constant("CreateTicketModalConstant", CreateSupportTicketModalConstant)
  .constant("AuthorNotificationConstant", AuthorNotificationConstant)
  .constant("Environment", Environment)
  .constant("HelpArticleImageModalConstant", HelpArticleImageModalConstant);

// Providers

/* Naming the service as "fxpLoggerService" since the same is is exposed to partner apps
 *  Should change it to FxpLoggerService to be in sync with other service names
 */
angular
  .module("Telemetry", [])
  .service("TelemetryConfiguration", [TelemetryConfiguration])
  //.service('fxpLoggerService', ['TelemetryConfiguration', 'FxpLoggingStrategyFactory', 'FxpConfigurationService', 'FxpLoggerServiceExtension', FxPLoggerServiceBase]) // for Backward Compatibility
  //.service('FxpLoggerService', [FxPLoggerServiceBase])
  //.service('FxpOnlineLoggingStrategy', ['$state', 'TelemetryConfiguration', 'Microsoft.ApplicationInsights.AppInsights', 'FxpTelemetryContext', FxpOnlineLoggingStrategy])
  //.service('FxpLoggerServiceExtension', [FxpLoggerServiceExtension])
  .factory("FxpLoggerServiceExtension", () => {
    return FxpLoggerServiceExtension.getInstance();
  })
  .factory("FxpLoggerService", () => {
    return FxpLoggerService.getInstance();
  })
  .factory("fxpLoggerService", () => {
    return FxpLoggerService.getInstance();
  });
// .provider('fxpLoggerService', () => {
//   this.$get = [function(){
//     return FxPLoggerServiceBase.getInstance();
//   }]

//  });

fxpModule
  .provider("SettingsService", SettingsServiceProvider)
  .provider("FxpBotService", FxpBotServiceProvider)
  .provider("MsalAuthenticationService", MsalAuthenticationServiceProvider)
  .provider("UIStateHelper", [
    "$stateProvider",
    "$urlRouterProvider",
    FxpUIStateHelperProvider.UIStateHelperProvider,
  ]);

fxpModule
  .constant("SYSTEM_MESSAGE_UI", SYSTEM_MESSAGE_UI)
  .constant("SessionTimeoutModalConstant", SessionTimeoutModalConstant)
  .constant("FxpModalConstant", FxpModalConstant);

fxpModule
  .service("FxpUIBModalService", ["FxpUIBModalService", FxpUIBModalService])
  .service("FxpConfigurationService", [FxpConfigurationService])
  .service("FxpHttpService", ["$http", FxpHttpService])
  .service("FxpHttpClientService", [
    "FxpHttpClientService",
    FxpHttpClientService,
  ])
  .service("DeviceFactory", ["FxpEventBroadCastService", DeviceFactoryProvider])
  .service("FxpEventBroadCastService", ["$rootScope", FxpEventBroadCastService])
  .service("FxpGlobalStoreService", ["FxpLoggerService", FxpGlobalStoreService])
  .service("FxpStateTransitionService", [
    "$rootScope",
    "$injector",
    "FxpTransitionService",
    "FxpStateService",
    "FxpGlobalStoreService",
    "FxpLoggerService",
    FxpStateTransitionService,
  ])
  .service("UserProfileService", [
    "FxpHttpClientService",
    "FxpLoggerService",
    "MsalAuthenticationService",
    "FxpMessageService",
    "FxpContextService",
    "FxpConfigurationService",
    "FxpTelemetryContext",
    "UserInfoService",
    "FxpGlobalStoreService",
    UserProfileService,
  ])
  .service("UserClaimsService", [
    "FxpHttpClientService",
    "FxpLoggerService",
    "MsalAuthenticationService",
    "FxpMessageService",
    "FxpContextService",
    "UserInfoService",
    "FxpConfigurationService",
    "FxpGlobalStoreService",
    "UserProfileService",
    UserClaimsService,
  ])
  .service("UserInfoService", ["FxpGlobalStoreService", UserInfoService])
  .service("TimeZoneHelper", ["moment", TimeZoneHelper])
  .service("SystemMessagesService", [
    "FxpHttpClientService",
    "FxpConfigurationService",
    "UserInfoService",
    SystemMessagesService,
  ])
  .service("PlannedDownTimeService", [
    "FxpConfigurationService",
    "SystemMessagesService",
    "TimeZoneHelper",
    "moment",
    "SYSTEM_MESSAGE_UI",
    "FxpLoggerService",
    "FxpSignalRService",
    "DashboardService",
    "FxpRouteService",
    "FxpGlobalStoreService",
    PlannedDownTimeService,
  ])
  .service("PersonalizationService", [
    "FxpHttpClientService",
    "FxpConfigurationService",
    "FxpLoggerService",
    "MsalAuthenticationService",
    "FxpMessageService",
    PersonalizationService,
  ])
  .service("PageTourEventService", [
    "$rootScope",
    "UserProfileService",
    pageTourEventService,
  ])
  .service("PageLoaderService", [
    "FxpHttpClientService",
    "FxpConfigurationService",
    "FxpGlobalStoreService",
    PageLoaderService,
  ])
  .service("OBOUserService", [
    "$rootScope",
    "FxpContextService",
    "FxpMessageService",
    "UserInfoService",
    "UserProfileService",
    "UserClaimsService",
    "FxpGlobalStoreService",
    OBOUserService,
  ])
  .service("NotificationStore", ["NotificationService", NotificationStore])
  .service("NotificationService", [
    "FxpHttpClientService",
    "FxpConfigurationService",
    "UserInfoService",
    NotificationService,
  ])
  .service("NotificationActionCenter", [
    "$rootScope",
    "FxpStateService",
    "FxpConfigurationService",
    "FxpRouteService",
    NotificationActionCenter,
  ])
  .service("HelpCentralService", [
    "FxpHttpClientService",
    "FxpConfigurationService",
    "UserInfoService",
    "DeviceFactory",
    HelpCentralService,
  ])
  .service("FxpStorageService", [FxpStorageService])
  .service("fxpRouteService", [
    "FxpStateService",
    "FxpLoggerService",
    "UserProfileService",
    "UserInfoService",
    "FxpBreadcrumbService",
    FxpRouteService,
  ]) //for backward compatibility
  .service("FxpRouteService", [
    "FxpStateService",
    "FxpLoggerService",
    "UserProfileService",
    "UserInfoService",
    "FxpBreadcrumbService",
    FxpRouteService,
  ])
  .service("fxpMessage", [
    "FxpConfigurationService",
    "FxpLoggerService",
    "FxpGlobalStoreService",
    FxpMessageService,
  ]) //For backward compatibility
  .service("FxpMessageService", [
    "FxpConfigurationService",
    "FxpLoggerService",
    "FxpGlobalStoreService",
    FxpMessageService,
  ])
  .service("FxpFeedbackService", [
    "FxpLoggerService",
    "$rootScope",
    "UserInfoService",
    "FxpTelemetryContext",
    "UserProfileService",
    FxpFeedbackService,
  ])
  .service("FxpBreadcrumbService", [
    "FxpStateService",
    "FxpStorageService",
    "UserInfoService",
    "DashboardService",
    "FxpConfigurationService",
    "FxpLoggerService",
    "FxpTelemetryContext",
    "FxpGlobalStoreService",
    FxpBreadcrumbService,
  ])
  .service("FxpAuthorizationService", [
    "FxpStateService",
    "FxpLoggerService",
    "UserClaimsService",
    "UserInfoService",
    "FxpConfigurationService",
    FxpAuthorizationService,
  ])
  .service("DashboardService", [
    "FxpHttpClientService",
    "$rootScope",
    "FxpConfigurationService",
    "FxpLoggerService",
    "MsalAuthenticationService",
    "FxpMessageService",
    "UserInfoService",
    "FxpTelemetryContext",
    "UserProfileService",
    "FeatureFlagService",
    "StartUpFlightConfig",
    "FxpGlobalStoreService",
    DashboardService,
  ])
  .service("AdminLandingService", [
    "FxpHttpClientService",
    "FxpConfigurationService",
    "FxpLoggerService",
    "MsalAuthenticationService",
    "FxpMessageService",
    "UserClaimsService",
    "UserInfoService",
    "FxpGlobalStoreService",
    AdminLandingService,
  ])
  .factory("FxpTelemetryContext", () => {
    return TelemetryContext.getInstance();
  })
  .service("PouchDBProvider", [PouchDBProvider])
  .service("FxpContextService", ["PouchDBProvider", FxpContext])
  .service("DataService", [
    "FxpHttpClientService",
    "MsalAuthenticationService",
    "FxpLoggerService",
    DataService,
  ])
  .service("FxpPartnerHttpInterceptorHooks", [FxpPartnerHttpInterceptorHooks])
  .service("LazyLoaderService", ["$ocLazyLoad", LazyLoaderService])
  .service("FxpIrisBotService", ["FxpEventBroadCastService", FxpIrisBotService])
  .service("FxpOCVFeedbackService", [
    "FxpLoggerService",
    "FxpGlobalStoreService",
    "FxpHttpClientService",
    "FxpConfigurationService",
    FxpOCVFeedbackService,
  ])
  .service("FxpSignalRService", [
    "FxpLoggerService",
    "FxpConfigurationService",
    "MsalAuthenticationService",
    FxpSignalRService,
  ])
  .service("UserFeedbackUIService", [
    "FxpHttpClientService",
    "FxpOCVFeedbackService",
    "UserInfoService",
    "FxpConfigurationService",
    "FxpUIBModalService",
    "FxpLoggerService",
    "FxpMessageService",
    "FxpGlobalStoreService",
    "$rootScope",
    UserFeedbackUIService,
  ])
  .service("UserAnnouncementUIService", [
    "FxpUIBModalService",
    "FxpLoggerService",
    "FxpConfigurationService",
    "FxpGlobalStoreService",
    "$rootScope",
    "UserFeedbackUIService",
    UserAnnouncementUIService,
  ]);

fxpModule
  .factory(
    "FxpHttpClientService",
    downgradeInjectable(FxpHttpClientService) as any
  )
  .factory("FxpUIBModalService", downgradeInjectable(FxpUIBModalService) as any)
  .factory("AddAuthTokenInterceptor", [
    "AdalLoginHelperService",
    "FxpEventBroadCastService",
    AddAuthTokenInterceptor,
  ])
  .factory("AddHeadersInterceptor", [
    "UserInfoService",
    "FxpConfigurationService",
    AddHeadersInterceptor,
  ])
  .factory("RetryInterceptor", [
    "FxpConfigurationService",
    "FxpLoggerService",
    RetryInterceptor,
  ])
  .factory("AddPartnerInterceptor", [
    "FxpPartnerHttpInterceptorHooks",
    AddPartnerInterceptor,
  ])
  .factory("FxpHttpCorrelationInterceptor", [
    "UserInfoService",
    "FxpConfigurationService",
    "FxpLoggerService",
    "FxpGlobalStoreService",
    httpCorrelationInterceptor,
  ])
  .factory("FxpStateService", downgradeInjectable(FxpStateService) as any)
  .factory(
    "FxpTransitionService",
    downgradeInjectable(FxpTransitionService) as any
  )
  .factory("FxpHttpRetryInterceptor", [
    "$injector",
    "FxpConfigurationService",
    "FxpLoggerService",
    "$rootScope",
    httpRetryInterceptor,
  ])
  .factory("MsalHttpRequestInterceptor", [
    "MsalAuthenticationService",
    MsalHttpRequestInterceptor,
  ])
  .factory("FxpPartnerHttpRequestInterceptor", [
    "FxpPartnerHttpInterceptorHooks",
    FxpPartnerHttpRequestInterceptor,
  ])
  .factory("FxpRootScopeService", () => {
    return FxpRootScopeService.getInstance();
  })
  .factory("AppControllerHelper", [
    "$rootScope",
    "UserProfileService",
    "FxpGlobalStoreService",
    "FxpTelemetryContext",
    "FxpLoggerService",
    "UserInfoService",
    "FeatureFlagService",
    "StartUpFlightConfig",
    "SettingsService",
    "FxpContextService",
    "FxpMessageService",
    "DeviceFactory",
    "deviceDetector",
    "PlannedDownTimeService",
    "FxpConfigurationService",
    "UserClaimsService",
    "FxpUIBModalService",
    "FxpModalConstant",
    "DashboardService",
    "MsalAuthenticationService",
    AppControllerHelper.appControllerHelperFactory,
  ])
  .factory("FxpUIData", [
    "$rootScope",
    "FxpFeedbackService",
    FxpUIData.getUIDataFactoryObj,
  ])
  .factory("SessionTimeoutModalFactory", [
    "FxpUIBModalService",
    "FxpConfigurationService",
    "FxpLoggerService",
    "SessionTimeoutModalConstant",
    "ActivityMonitor",
    "$rootScope",
    SessionTimeoutModalFactory.getUIDataFactoryObj,
  ])
  .factory("DashBoardHelper", [
    "$rootScope",
    "FxpStateService",
    "UIStateHelper",
    "FxpLoggerService",
    "FxpTelemetryContext",
    "FxpContextService",
    "FxpRouteService",
    "$location",
    "PageLoaderService",
    "FxpConfigurationService",
    "$injector",
    "AppControllerHelper",
    "UserInfoService",
    "FxpMessageService",
    "DashboardService",
    "FxpGlobalStoreService",
    "UserFeedbackUIService",
    "UserAnnouncementUIService",
    DashBoardHelper.DashBoardHelperFactory,
  ])

  .factory("ActOnBehalfOfHelper", [
    "$rootScope",
    "FxpHttpClientService",
    "UserProfileService",
    "FxpLoggerService",
    "FxpMessageService",
    "FxpContextService",
    "FxpTelemetryContext",
    "UserInfoService",
    "UserClaimsService",
    ActOnBehalfOfHelper.ActOnBehalfOfHelperFactory,
  ])
  .factory("AuthorNotificationRoleGroupHelper", [
    AuthorNotificationRoleGroupHelper.AuthorNotificationRoleGroupHelper,
  ]);

fxpModule
  .controller("AppController", AppController)
  .controller("ToastNotificationController", [
    "$scope",
    "$rootScope",
    "FxpConfigurationService",
    "NotificationStore",
    "NotificationActionCenter",
    "FxpMessageService",
    "FxpLoggerService",
    ToastNotificationController,
  ])
  .controller("ActOnBehalfOfController", ActOnBehalfOfController)
  .controller("AdminLandingController", AdminLandingController)
  .controller("AuthorNotificationConfirmationController", [
    "FxpUIBModalService",
    AuthorNotificationConfirmationController,
  ])
  .controller("AuthorNotificationController", [
    "$scope",
    "$rootScope",
    "FxpUIBModalService",
    "FxpStateService",
    "UserProfileService",
    "FxpLoggerService",
    "FxpRouteService",
    "NotificationStore",
    "FxpMessageService",
    "FxpConfigurationService",
    "FxpGlobalStoreService",
    "AuthorNotificationConstant",
    "PersonalizationService",
    "AuthorNotificationRoleGroupHelper",
    "FxpEventBroadCastService",
    AuthorNotificationController,
  ])
  .controller("CreateAskOpsController", [
    "$rootScope",
    "FxpUIBModalService",
    "FxpLoggerService",
    "SettingsService",
    "FxpMessageService",
    "defaultRequestType",
    CreateAskOpsController,
  ])
  .controller("CreateTicketController", [
    "$rootScope",
    "FxpUIBModalService",
    "FxpLoggerService",
    "defaultRequestType",
    CreateSupportTicketController,
  ])
  .controller("DashBoardController", [
    "$scope",
    "UIStateHelper",
    "$rootScope",
    "FxpLoggerService",
    "UserProfileService",
    "UserInfoService",
    "FxpMessageService",
    "OBOUserService",
    "DashBoardHelper",
    "FxpTelemetryContext",
    "FxpContextService",
    "FxpConfigurationService",
    "FxpEventBroadCastService",
    "DashboardService",
    "FxpFeedbackService",
    "FxpStateService",
    DashBoardController,
  ])
  .controller("HelpArticleImageController", [
    "FxpUIBModalService",
    "source",
    HelpArticleImageController,
  ])
  .controller(
    "LeftNavPersonalizationController",
    LeftNavPersonalizationController
  )
  .controller("PowerBiPageController", PowerBiPageController)
  .controller(
    "RoleNavPersonalizationController",
    RoleNavPersonalizationController
  )
  .controller("SessionTimeoutModalController", [
    "FxpLoggerService",
    SessionTimeoutModalController,
  ])
  .controller("SystemMessagesController", [
    "$scope",
    "FxpLoggerService",
    "FxpMessageService",
    "TimeZoneHelper",
    "SystemMessagesService",
    "PersonalizationService",
    "FxpGlobalStoreService",
    "UserInfoService",
    "SYSTEM_MESSAGE_UI",
    SystemMessagesController,
  ])
  .controller(
    "UserLookupPersonalizationController",
    UserLookupPersonalizationController
  )
  .controller("FxpWelcomeController", FxpWelcomeController);
fxpModule
  .config([
    "MsalAuthenticationServiceProvider",
    function (
      msalAuthenticationServiceProvider: MsalAuthenticationServiceProvider
    ) {
      msalAuthenticationServiceProvider.configure({
        auth: {
          // clientId: "853531f8-add1-44d2-824b-b4a2790b08ac",
          clientId: FxpAppSettings.FxpAppClientId,
          authority:
            "https://login.microsoftonline.com/microsoft.onmicrosoft.com",
          redirectUri: window.location.origin,
          // navigateToLoginRequestUrl: false
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {},
      });
    },
  ])
  .config([
    "FxpBotServiceProvider",
    function (FxpBotServiceProvider: FxpBotServiceProvider) {
      FxpBotServiceProvider.configure({
        AppID: FxpAppSettings.ConnectMeBotAppId,
        TopicID: FxpAppSettings.ConnectMeBotTopicId,
        Url: FxpAppSettings.ConnectMeBotEndpoint,
      });
    },
  ])
  .config([
    "$locationProvider",
    function ($locationProvider) {
      $locationProvider.html5Mode(false);
      $locationProvider.hashPrefix("");
    },
  ])
  .config(["$provide", FxpBootstrap.fxpConfigInit])
  .config(["$compileProvider", FxpBootstrap.setSanitizedWhitelist])
  .config(["$httpProvider", FxpBootstrap.authenticationInit])
  .config(["$httpProvider", FxpBootstrap.configHttpProvider])
  .config(["$stateProvider", "$urlRouterProvider", FxpBootstrap.registerRoutes])
  .config([
    "$sceProvider",
    function ($sceProvider: angular.ISCEProvider) {
      $sceProvider.enabled(false);
    },
  ])
  .config([
    "SettingsServiceProvider",
    function (SettingsServiceProvider: ISettingsServiceProvider) {
      SettingsServiceProvider.configure({
        settingsServiceBaseUrl: FxpAppSettings.FxpServiceEndPoint,
      });
    },
  ])
  .config([
    "FeatureFlagServiceProvider",
    function (FeatureFlagServiceProvider: FeatureFlagServiceProvider) {
      FeatureFlagServiceProvider.configure(
        {
          ServiceEndpoint: FxpAppSettings.FlightingServiceEndpoint,
        },
        {}
      );
    },
  ])
  .config([
    "$ocLazyLoadProvider",
    function ($ocLazyLoadProvider) {
      $ocLazyLoadProvider.config({
        debug: ocLazyLoadDebugFlag,
        events: true,
        modules: window["OcModules"] || [],
        loadedModules: di,
        serie: true,
      });
    },
  ])
  .config([
    "$uiRouterProvider",
    function ($uiRouterProvider) {
      $uiRouterProvider.plugin(StickyStatesPlugin);
    },
  ])
  .run([
    "$ocLazyLoad",
    function ($ocLazyLoad) {
      PartnerAppRegistrationService.setLazyLoader($ocLazyLoad);
    },
  ])
  .run(["FxpLoggerService", FxpBootstrap.InitializeStore])
  .run([
    "$cookies",
    "$rootScope",
    "UserInfoService",
    "FxpMessageService",
    "FxpConfigurationService",
    "FxpContextService",
    "FxpTelemetryContext",
    "FxpLoggerService",
    "OBOUserService",
    "FxpGlobalStoreService",
    "AppControllerHelper",
    "$http",
    "MsalAuthenticationService",
    FxpBootstrap.fxpRunInit,
  ])
  .run([
    "$rootScope",
    "FxpAuthorizationService",
    "FxpConfigurationService",
    "PlannedDownTimeService",
    "FxpStateService",
    "FxpStateTransitionService",
    "FxpLoggerService",
    FxpBootstrap.rootScopeEventHandler,
  ])
  .run([
    "$rootScope",
    function ($rootScope) {
      $rootScope.BuildNumber = FxpAppSettings.BuildNumber;
    },
  ]);

let fxpKeyDownDirective: any = FxpKeyDownDirective.fxpEnterKeyPressDirective;
let setToFocusDirective: any = FxpSetFocusToElement.fxpSetFocus;
var clickDirective: any = AppDirectives.fxpClickDirective;

angular
  .module("FXPComponents", [])
  .directive(
    "fxpmessages",
    downgradeComponent({
      component: FxpMessagesComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxpfooter",
    downgradeComponent({
      component: FxpFooterComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxpheader",
    downgradeComponent({
      component: FxpHeaderComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxpleftnavigation",
    downgradeComponent({
      component: FxpLeftNavigationComponent,
    }) as angular.IDirectiveFactory
  )
  .directive("actOboHeader", FxpComponentDirectives.fxpOboHeader)
  .directive(
    "fxploader",
    downgradeComponent({
      component: FxpLoaderComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxpbreadcrumb",
    downgradeComponent({
      component: FxpBreadcrumbComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxphelpmenu",
    downgradeComponent({
      component: FxpHelpMenuComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxpsystemupdatemsg",
    downgradeComponent({
      component: FxpSystemUpdateMsgComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxptoastnotification",
    downgradeComponent({
      component: FxpToastNotificationComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "browserExperience",
    downgradeComponent({
      component: BrowserExperienceComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxpbotmenu",
    downgradeComponent({
      component: FxpBotLauncherComponent,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "ngfxpsharedcomponent",
    downgradeComponent({
      component: FxpSharedComponentLoader,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "ngsharedcomponent",
    downgradeComponent({
      component: SharedComponentLoader,
    }) as angular.IDirectiveFactory
  )
  .directive(
    "fxppagetour",
    downgradeComponent({
      component: PageTourLauncherComponent,
    }) as angular.IDirectiveFactory
  );

fxpModule
  .directive("skipToMainContent", SkipToMainContent.getDirective)
  .directive(
    "changeTabIndexByClass",
    ChangeTabIndexByClassDirective.changeTabIndexByClass
  )
  .directive(
    "authorNotificationContent",
    AuthorNotificationContentDirective.authorNotificationContent
  )
  .directive(
    "fxpAuthorNotificationRow",
    FxpAuthorNotificationListDirective.fxpAuthorNotificationRow
  )
  .directive("renderFxpBreadcrumb", BreadcrumbDirectives.renderFxpBreadcrumb)
  .directive(
    "breadcrumbFocusContentAria",
    BreadcrumbDirectives.breadcrumbFocusContentAria
  )
  .directive("fxpBreadcrumbLink", fxpBreadcrumbLink.fxpBreadcrumbLinksDirective)
  .directive("fxpChoiceItem", fxpChoiceItem)
  .directive("fxpGoToTop", FxpGoToTopDirective.fxpGoToTop)
  .directive("fxpHelpEvents", FxpHelpDirectives.fxpHelpEvents)
  .directive("fxpHelp", FxpHelpDirectives.fxpHelp)
  .directive("fxpHelpArticle", FxpHelpDirectives.fxpHelpArticle) //converted to ng8directive
  .directive("fxpHelpOutsideClick", FxpHelpDirectives.fxpHelpOutsideClick)
  .directive("fxpIncludeStaticTemplate", [
    "$templateCache",
    ($templateCache) => new FxpIncludeStaticTemplate($templateCache),
  ])
  .directive("fxpKeyDown", fxpKeyDownDirective)
  .directive('fxpLeftnavLink', LeftnavLink.fxpLeftnavLinkDirective)
  .directive(
    "fxpNotificationDirective",
    FxpNotification.fxpNotificationDirective
  )
  .directive(
    "fxpNotificationSetFocus",
    FxpNotificationSetFocusDirective.fxpNotificationSetFocus
  )
  .directive("fxpSetFocus", setToFocusDirective)
  .directive("fxpShowIfDevice", [
    "$parse",
    "FxpEventBroadCastService",
    "DeviceFactory",
    FxpShowIfDeviceDirective.fxpShowIfDevice,
  ])
  .directive("fxpSystemMessageRow", fxpSystemMessageRow)
  .directive("fxpSystemMessagePopupDirective", fxpSystemMessagePopupDirective)
  .directive(
    "fxpToastNotification",
    FxpToastNotificationDirectives.fxpToastNotification
  )
  .directive(
    "fxpToastNotificationContainer",
    FxpToastNotificationDirectives.fxpToastNotificationContainer
  )
  .directive("leftNavOutsideClick", [
    "$rootScope",
    "$document",
    "$parse",
    LeftNavOutsideClickDirective.leftNavOutsideClick,
  ])
  .directive(
    "renderNavElementsForKeydown",
    RenderNavElementsForKeydownDirective.renderNavElementsForKeydown
  )
  .directive(
    "leftNavPrsonalizationAdjustScroll",
    LeftNavPrsonalizationAdjustScrollDirective.leftNavPrsonalizationAdjustScroll
  )
  .directive(
    "renderPopUpForKeydown",
    RenderPopUpForKeydownDirective.renderPopUpForKeydown
  )
  .directive(
    "leftNavPersonalizationScreenReader",
    LeftNavPersonalizationScreenReaderDirective.leftNavPersonalizationScreenReader
  )
  .directive(
    "leftNavPersonalizationScrollToActive",
    LeftNavPersonalizationScrollToActiveDirective.leftNavPersonalizationScrollToActive
  )
  .directive("multilineEllipsis", MultilineEllipsisDirective.multilineEllipsis)
  .directive(
    "multilineEllipsisWithAccordion",
    MultilineEllipsisWithAccordionDirective.multilineEllipsisWithAccordion
  )
  .directive("ngLongPress", [IntervalFlipDirective.intervalFlip])
  .directive("ngRightClick", ["$parse", FeedbackFlyoutDirective.feedbackFlyout])
  .directive("scrollToTop", ScrollToTopDirective)
  .directive("fxpClick", clickDirective);

fxpModule.filter("unCamelCase", [UnCamelCase.getSpacedString]);

fxpModule.directive("adminLanding", AdminLandingDirective.adminLanding);

fxpModule.provider("FxpCorrelationProvider", [
  FxpProviders.CorrelationProvider,
]);

// This must be imported in the end
import "./module-declarations";
import { FxpRootScopeService } from "./services/FxpRootScopeService";
import { FxpUIBModalService } from "./services/FxpUIBModalService";
import { FxpToastNotificationComponent } from "../app/components/fxptoastnotification/fxptoastnotification.component";
import { FxpBreadcrumbComponent } from "../app/components/fxpbreadcrumb/fxpbreadcrumb.component";
import { FxpLoaderComponent } from "../app/components/fxploader/fxploader.component";

