import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { AdalLoginHelperService } from '../../js/services/AdalLoginHelperService';
import { FxpConfigurationService } from '../../js/services/FxpConfiguration';
import { UserInfoService } from '../../js/services/UserInfoService';
import { tap, filter, catchError, retryWhen, concatMap, delay } from 'rxjs/operators';
import { CommonUtils } from '../../js/utils/CommonUtils';
import { FxpLoggerService } from '../../js/telemetry/fxpLogger';
import { FxpEventBroadCastService } from '../../js/services/BroadCastingService';
import * as Q from 'q'
import { FxpPartnerHttpInterceptorHooks } from '../../js/services/FxpPartnerHttpInterceptorHooks';
@Injectable()
export class AddAuthTokenInterceptor implements HttpInterceptor {
	constructor(private adalLoginHelperService: AdalLoginHelperService, private fxpEventBroadCast: FxpEventBroadCastService) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// if the request can be served via templateCache, no need to token
		// To do find replacement for $tempalteCache
		var resource = this.adalLoginHelperService.getResourceForEndpoint(req.url);
		if (resource === null) {
			return next.handle(req);
		}
		return from(this.setTokenInRequest(req, next)).pipe(
			filter(event => event instanceof HttpResponse),
			catchError((error, caught) => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					var resource = this.adalLoginHelperService.getResourceForEndpoint(error.url);
					//this.adalLoginHelperService.clearCacheForResource(resource);
					this.fxpEventBroadCast.broadCast('adal:notAuthorized', error);
				}
				return throwError(error);
			})
		)
	}
	async setTokenInRequest(req: HttpRequest<any>, next: HttpHandler) {
		var token = await this.getToken(req, next);
		token = 'Bearer ' + token;
		var updatedHeaders = req.headers.set('Authorization', token)
		const clonedRequest = req.clone({ headers: updatedHeaders });

		// Pass the cloned request instead of the original request to the next handle
		return next.handle(clonedRequest).toPromise();
	}
	getToken(req: HttpRequest<any>, next: HttpHandler): Q.Promise<any> {
		var token = this.adalLoginHelperService.getCachedToken(req.url);
		var defer = Q.defer();
		if (token) {
			defer.resolve(token);
		}
		else {
			this.adalLoginHelperService.acquireTokenAsPromise(req.url).then(function (token) {
				defer.resolve(token);
			}, function (error) {
				defer.reject(token);
			});
		}
		return defer.promise;
	}
}
@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
	constructor(private userInfoService: UserInfoService, private fxpConfigurationService: FxpConfigurationService) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Clone the request to add the new header
		var grmInterceptorConfig = window["tenantConfiguration"].AdditionalConfigurationContainer || {};
		var authExcludeExtnPat = new RegExp(this.fxpConfigurationService.FxpBaseConfiguration.FxpConfigurationStrings.AdalAuthExcludeExtn, "i");
		var enableLazyLoading = grmInterceptorConfig.EnableLazyLoading || false;
		let transactionId = $.correlator.getCorrelationId();
		var updatedHeaders = req.headers.set('X-CorrelationId', transactionId).delete('X-Requested-With');

		if (authExcludeExtnPat.test(req.url)) {
			updatedHeaders = req.headers.delete('Authorization');
		}
		if (req.method === 'post') {
			updatedHeaders.set('Content-Type', "application/x-www-form-urlencoded; charset=utf-8")
		}
		if (enableLazyLoading) {
			if (grmInterceptorConfig.InterceptGRMCalls && grmInterceptorConfig.GRM_APIMServiceUrl != undefined && req.url.indexOf(grmInterceptorConfig.GRM_APIMServiceUrl) >= 0) {
				if (req.headers.get('X-SenderId') == undefined) {
					updatedHeaders.set('X-SenderId', 'GRM');
					updatedHeaders.set('X-SenderApp', 'GRM User Experience');
				}
				if (req.headers.get('Ocp-Apim-Subscription-Key') == undefined) {
					updatedHeaders.set('Ocp-Apim-Subscription-Key', grmInterceptorConfig.GRM_APIM_SubscriptionKey || "");

				}
			}
		}
		updatedHeaders = updatedHeaders.set('X-LoggedInUser-Alias', this.userInfoService.getLoggedInUser());
		updatedHeaders = updatedHeaders.set('X-LoggedInUser-Id', this.userInfoService.getLoggedInUserOID());
		if (this.userInfoService.isActingOnBehalfOf()) {
			updatedHeaders = updatedHeaders.set('X-ActonBehalfMode', 'true');
			updatedHeaders = updatedHeaders.set('X-OnBehalfOfUser', this.userInfoService.getCurrentUserUpn());
			updatedHeaders = updatedHeaders.set('X-OnBehalfOfUser-Alias', this.userInfoService.getCurrentUser());
			updatedHeaders = updatedHeaders.set('X-OnBehalfOfUser-Id', this.userInfoService.getCurrentUserOID());
		}

		const clonedRequest = req.clone({ headers: updatedHeaders });
		// Pass the cloned request instead of the original request to the next handle
		return next.handle(clonedRequest);
	}

}
@Injectable()
export class RetryInterceptor implements HttpInterceptor {
	constructor(private fxpConfigurationService: FxpConfigurationService,
		private loggerService: FxpLoggerService) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		var requestURL = req.urlWithParams,
			retries = {},
			waitBetweenErrors = this.fxpConfigurationService.FxpBaseConfiguration.HttpRetryWaitTime || 1000,
			maxRetries = this.fxpConfigurationService.FxpBaseConfiguration.HttpRetryCount || 3;
		var excludedHttpStatusCodesForLogging = [];
		if (!CommonUtils.isNullOrEmpty(this.fxpConfigurationService.FxpAppSettings.ExcludeHttpStatusCodesFromLogging)) {
			excludedHttpStatusCodesForLogging = this.fxpConfigurationService.FxpAppSettings.ExcludeHttpStatusCodesFromLogging.split(',');
		}
		return next.handle(req).pipe(
			filter(event => event instanceof HttpResponse),
			retryWhen(error =>
				error.pipe(
					concatMap((error, count) => {
						let specificError = '';
						let propBag = this.loggerService.createPropertyBag();
						this.logResourceTiming("ResourceTimingFailure", requestURL, error.status.toString());
						var retryEnabled = true;
						propBag.addToBag("ServiceUrl", requestURL);
						propBag.addToBag("Status", error.status.toString());
						if (retryEnabled && error.status === 400) {
							if (count < maxRetries) {
								//retryForUrlCount++;
								//retries[requestURL] = retryForUrlCount;
								//retry the req
								return of(error);
							}
							else {
								specificError = 'ServiceCallRetryExit';
							}
						} else {
							specificError = 'ServiceCallFailed';
						}

						let statusCode = (error.status) ? error.status.toString() : '0';
						if (excludedHttpStatusCodesForLogging.indexOf(statusCode) === -1) {
							let message = `Error in Fxp Http Interceptor due to ${specificError}`;
							this.loggerService.logError("Fxp.HttpRetryInterceptor.responseError", message, '2500', CommonUtils.objectToString(error), propBag);
						}
						return throwError(error)

					}),
					delay(waitBetweenErrors)
				)
			)
		)


	}
	logResourceTiming(message, url, status) {
		if (this.fxpConfigurationService.FxpAppSettings.EnableHttpTransactionTimeLogging) {
			var resourcePerf: any = window.performance.getEntries().filter(function getFilteredRecords(event) { return event.name.indexOf(url) > -1 });
			for (var i = 0; i < resourcePerf.length; i++) {
				var dns = resourcePerf[i].domainLookupEnd - resourcePerf[i].domainLookupStart,//capture domain lookup start
					tcp = resourcePerf[i].connectEnd - resourcePerf[i].connectStart,// TCP handshake
					ttfb = resourcePerf[i].responseStart - resourcePerf[i].startTime,//time to take first byte
					transfer = resourcePerf[i].responseEnd - resourcePerf[i].responseStart,
					total = resourcePerf[i].responseEnd - resourcePerf[i].startTime;// total time taken
				if (total > 0 && ttfb > 0) {
					var propBag = this.loggerService.createPropertyBag();
					propBag.addToBag("ResourceName", url);
					propBag.addToBag("Status", status);
					propBag.addToBag("DNS", dns.toString());
					propBag.addToBag("TCP", tcp.toString());
					propBag.addToBag("TTFB", ttfb.toString());
					propBag.addToBag("Transfer", transfer.toString());
					propBag.addToBag("Total", total.toString());
					this.loggerService.logEvent("Fxp.HttpRetryInterceptor", message, propBag);
				}
			}
		}
	}
}
@Injectable()
export class AddPartnerInterceptor implements HttpInterceptor {
	constructor(private fxpPartnerHttpInterceptorHooks: FxpPartnerHttpInterceptorHooks) { }
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		var requestInterceptors = this.fxpPartnerHttpInterceptorHooks.getRegisteredInterceptorHooks();
		if (requestInterceptors.length==0) {
			//return next.handle(req);
		}

		//create req from config each time we get interceptor.request(config)
		var config = this.createConfigFromRequest(req)

		requestInterceptors.forEach(interceptor => {
			if (interceptor.request) {

				let updatedConfig = interceptor.request(config);
				if (updatedConfig) {
					config = updatedConfig;
				}
			}

		});

		return from(this.createRequestFromConfig(req, next, config)).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					requestInterceptors.forEach(interceptor => {
						if (interceptor.response) {
							var headerKeys = event.headers.keys();
							var responseHeader = {};
							for (var i = 0; i < headerKeys.length; i++) {
								responseHeader[headerKeys[i]] = req.headers.getAll(headerKeys[i])
							}
							// create a response object from event
							var responseFromEvent = {
								data: event.body,
								status: event.status,
								statusText: event.statusText,
								config: config,
								headers: responseHeader
							}
							interceptor.response(responseFromEvent);
						}
					});
				}
				return event;
			}),
			catchError((error, caught) => {
				if (error.error instanceof ErrorEvent) {
					// client-side error
					requestInterceptors.forEach(interceptor => {
						if (interceptor.response) {
							interceptor.requestError(error);
						}
					});
				} else {
					// server-side error
					requestInterceptors.forEach(interceptor => {
						if (interceptor.response) {
							interceptor.responseError(error);
						}
					});
				}

				return throwError(error);
			})
		)
	}
	createConfigFromRequest(req: HttpRequest<any>): any {
		var headerKeys = req.headers.keys();
		var header ={};
		for (var i = 0; i < headerKeys.length; i++) {
			console.log("headerKeys"+ headerKeys[i]);
			header[headerKeys[i]] = req.headers.get(headerKeys[i])
			console.log("headerValue"+ header[headerKeys[i]]);
		}
		var paramKeys = req.params.keys();
		var params ={};
		for (var i = 0; i < paramKeys.length; i++) {
			params[paramKeys[i]] = req.params.get(paramKeys[i])
		}
		var config = {
			method: req.method,
			url: req.url,
			data: req.body,
			withCredentials: req.withCredentials,
			responseType: req.responseType,
			params: params,
			headers: header
		}
		return config;
	}
	createRequestFromConfig(req: HttpRequest<any>, next: HttpHandler, config: any) {
		// Pass the cloned request instead of the original request to the next handle
		var headerKeys = Object.keys(config.headers);
		var header: HttpHeaders = new HttpHeaders();
		for (var i = 0; i < headerKeys.length; i++) {
			header = header.append(headerKeys[i], config.headers[headerKeys[i]])
		}
		var paramKeys = Object.keys(config.params);
		var params: HttpParams= new HttpParams();
		for (var i = 0; i < paramKeys.length; i++) {
			params.append(paramKeys[i], config.params[paramKeys[i]])
		}

		var clonedReq = req.clone({
			headers: header,
			params: params,
			responseType: config.responseType,
			withCredentials: config.withCredentials,
			body: config.data,
			method: config.method,
			url: config.url,
		});
		return next.handle(clonedReq).toPromise();
	}
}