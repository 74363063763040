declare type IStateService = any;
/// <reference path="../../typings/adal-angular/adal-angular.d.ts" />
import { IBrowserExperience } from "../interfaces/IUserExperience";
import { UserInfoService } from "./UserInfoService";
import { BrowserExperienceObject } from "../interfaces/BrowserExperienceObject";
import { BrowserExperienceConstants } from "../constants/BrowserExperienceConstants";


export class BrowserExperience {
    private $scope: IBrowserExperience;
    public message: string;
    private userInfoService: UserInfoService;
    public isBannerHidden: number;
    public topMargin: string;
    private timeLimit: number;


    constructor(userInfoService: UserInfoService) {
        this.message = BrowserExperienceConstants.message
        this.userInfoService = userInfoService;
        this.isBannerHidden = 0;
        this.topMargin = "57px !important";
        this.timeLimit = BrowserExperienceConstants.timeLeft;
    }

    setMarginZero() {
        this.topMargin = "0px !important";
    }

    setBrowserExperienceObject(flag) {
        var browserExperienceObject: BrowserExperienceObject = { userName: "", timestamp: 123456, noThanksFlag: 2 };
        browserExperienceObject.userName = this.userInfoService.getLoggedInUser();
        browserExperienceObject.timestamp = new Date().getTime();
        browserExperienceObject.noThanksFlag = flag;

        return JSON.stringify(browserExperienceObject);
    }

    noThanksClick() {
        this.remindMeLaterClick();
    }

    public remindMeLaterClick() {
        localStorage.setItem("browserExperience", this.setBrowserExperienceObject(0));
        this.setMarginZero();
        this.isBannerHidden = 1;
    }

    buttonLogic() {
        var browserExperienceJson = localStorage.getItem("browserExperience");

        if (browserExperienceJson == null) {
            this.isBannerHidden = 0;
            this.topMargin = "57px !important";
        }
        else {
            var browserExperienceObject: BrowserExperienceObject = JSON.parse(browserExperienceJson);

            if (!(browserExperienceObject.noThanksFlag == 1 || browserExperienceObject.timestamp > new Date().getTime() - this.timeLimit)) {
                this.isBannerHidden = 0;
                this.topMargin = "57px !important";
            }
            else {

                this.isBannerHidden = 1;
                this.setMarginZero();
            }
        }
    }

    public checkBrowser() {
        var isIEedge = window.navigator.userAgent.indexOf("Edge") > -1;

        if (!isIEedge) {
            this.isBannerHidden = 1;
            this.setMarginZero();
        } else {

            this.buttonLogic();
        }
    }
}
