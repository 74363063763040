/// <reference path="../../../scripts/typings/angularjs/angular.d.ts" />
import { ConFitService } from "./../service/ConfigService";
var ConfitAngular1Module = /** @class */ (function () {
    function ConfitAngular1Module() {
    }
    ConfitAngular1Module.registerModule = function () {
        angular.module(this._moduleName, [])
            .service('ConFitService', ["$http", "confitRootUrl", ConFitService]);
    };
    Object.defineProperty(ConfitAngular1Module, "ConfitNg1ModuleName", {
        get: function () {
            return this._moduleName;
        },
        enumerable: true,
        configurable: true
    });
    ConfitAngular1Module._moduleName = "ConFit";
    return ConfitAngular1Module;
}());
export { ConfitAngular1Module };
