import { Component, OnInit } from '@angular/core';
import { NotificationActionCenter } from '../../../js/services/NotificationActionCenter';
import { FxpConstants } from '../../../js/common/ApplicationConstants';
import { TelemetryConstants } from '../../../js/telemetry/TelemetryConst';
import { CommonUtils } from '../../../js/utils/CommonUtils';
import { FxpConfigurationService } from '../../../js/services/FxpConfiguration';
import { FxpBroadcastedEvents } from '../../../js/services/FxpBroadcastedEvents';
import { ErrorCodes } from '../../../js/constants/errorCodes';
import { ErrorSeverityLevel } from '../../../js/telemetry/ErrorSeverityLevel';
import { FxpLoggerService } from '../../../js/telemetry/fxpLogger';
import { FxpMessageService } from '../../banner/FxpMessageService';
import { NotificationStore } from '../../../js/services/NotificationStore';
import { FxpRootScopeService, IRootSubjects } from '../../../js/services/FxpRootScopeService';

@Component({
  selector: 'fxp-toast-notification',
  templateUrl: './fxptoastnotification.component.html'
})
export class FxpToastNotificationComponent implements OnInit {
    public toastNotifications: any;
	private toastNotificationLimit: number;
	private fxpConstants: any;
    private rootScopeService: FxpRootScopeService;
    private fxpRootScope: IRootSubjects;
	private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ToastNotificationController`;

    
  constructor(
    private notificationActionCenter: NotificationActionCenter,
    private fxpConfigurationService: FxpConfigurationService,
    private fxpLoggerService: FxpLoggerService,
    private fxpMessage: FxpMessageService,
    private notificationStore: NotificationStore
  ) {

    this.rootScopeService = FxpRootScopeService.getInstance();
    this.rootScopeService.rootScopeSubject.subscribe((data) => {
        this.fxpRootScope = data;
    });
    var self = this,
			// Callback method when new notifications arrive.
			addToastNotifications = function (notifications) {
				// Appending icons to new notifications
				notifications = self.notificationActionCenter.appendPropertiesToNotifications(notifications);
				// Appending new notifications to collection.
				self.toastNotifications = self.toastNotifications.concat(notifications);
			};
		self.fxpConstants = [];
		// Initialize collection.
		self.toastNotifications = [];
		// Set toast notification limit.
		self.toastNotificationLimit = CommonUtils.isNullOrEmpty(fxpConfigurationService.FxpBaseConfiguration.NotificationConfiguration.ToastNotificationLimit) ? 10 : parseInt(fxpConfigurationService.FxpAppSettings.ToastNotificationLimit);
		this.rootScopeService.on(FxpBroadcastedEvents.OnNewNotificationsRecieved, addToastNotifications);

  }

  ngOnInit() {
    
  }

  /**
   * Method to read toast notification.
   * @method Fxp.Controllers.ToastNotificationController.readToastNotification
   * @param {any} notification is an object of containing details of notification.
   * @example <caption> Example to use readToastNotification</caption>
   * <div ng-click="readToastNotification({'Subject': 'new request','From': 'ResourceManagement'})">read notification</div>;
   */
	readToastNotification = (notification) => {
		//const self = this;
		const telemetry_source = `${this.sourceForTelemetry}.ReadToastNotification`;
		let startTime = performance.now(),
			propbag = this.fxpLoggerService.createPropertyBag();
		// Perform Navigation.
		try {
			this.notificationActionCenter.excecuteNotificationAction(notification);
		} catch (error) {
			var message = this.fxpRootScope.fxpUIConstants.UIMessages.NotificationNavigationError;
			// Show error.
			this.fxpMessage.addMessage(message.ErrorMessage, this.fxpConstants.messageType.error);
			// Log error details.
			propbag.addToBag(this.fxpConstants.metricConstants.Status, error.status);
			propbag.addToBag(this.fxpConstants.metricConstants.StatusText, error.statusText);
			this.fxpLoggerService.logError(telemetry_source, message.ErrorMessageTitle, ErrorCodes.ReadToastNotification_Failure, null, propbag, null, null, ErrorSeverityLevel.Medium);
		}
		// Recreate property bag.
		propbag = this.fxpLoggerService.createPropertyBag()
		// Mark Notification as read.
		this.notificationStore.markNotificationAsRead(notification.WebNotificationId).then((response) => {
			// Add notificaton EventId to prop bag.
			propbag.addToBag("EventId", notification.eventId);
			// Add notificaton id to prop bag.
			propbag.addToBag("Notification Id", notification.notificationId);
			// Add more notification info.
			propbag.addToBag("From", notification.senderAddress);
			// Closing Notification Toast.
			this.closeToastNotification(notification);
			// Log event.
			propbag.addToBag("Total Time", (performance.now() - startTime).toString());
			this.fxpLoggerService.logEvent(telemetry_source, "ReadNotification", propbag);
		}, (error) => {
			var message = this.fxpRootScope.fxpUIConstants.UIMessages.ToastNotificationReadError;
			// Show error.
			this.fxpMessage.addMessage(message.ErrorMessage, this.fxpConstants.messageType.error);
			// Log error details.
			propbag.addToBag(this.fxpConstants.metricConstants.Status, error.status);
			propbag.addToBag(this.fxpConstants.metricConstants.StatusText, error.statusText);
			this.fxpLoggerService.logError(telemetry_source, message.ErrorMessageTitle, ErrorCodes.MarkNotificationAsRead_Failure, null, propbag, null, null, ErrorSeverityLevel.Medium);
		});
	}

  /**
   * Method to read toast notification.
   * @method Fxp.Controllers.ToastNotificationController.closeToastNotification
   * @param {any} notification is an object of containing details of notification.
   * @example <caption> Example to use closeToastNotification</caption>
   * <div ng-click="closeToastNotification({'Subject': 'new request','From': 'ResourceManagement'})">Close notification</div>;
   */
	closeToastNotification = (notification) => {
		var self = this,
			// Find index of current notification.
			index = self.toastNotifications.indexOf(notification);
		// Remove notification from collection.
		self.toastNotifications.splice(index, 1);
	}
}