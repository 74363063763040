import { IAction } from 'redux-micro-frontend';
import { AppStatusActionTypes } from './app.status.action.types';

export const StartAppInitialization = (): IAction<void> => ({
    type: AppStatusActionTypes.START_APP_INITIALIZATION,
    payload: null,
    logEnabled: false
});

export const CompleteAppInitialization = (): IAction<void> => ({
    type: AppStatusActionTypes.COMPLETE_APP_INITIALIZATION,
    payload: null,
    logEnabled: false
});

export const AuthenticateUser = (): IAction<void> => ({
    type: AppStatusActionTypes.AUTHENTICATE_USER,
    payload: null,
    logEnabled: false
});

export const FailAuthentication = (error: any): IAction<void> => ({
    type: AppStatusActionTypes.FAIL_AUTHENTICATION,
    payload: error,
    logEnabled: false
});

// Test
export const UpdateCounter = (count: number): IAction<Number> => ({
    type: AppStatusActionTypes.UPDATE_COUNTER,
    payload: count,
    logEnabled: false
});