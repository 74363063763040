import { ISystemMessagesService } from "./ISystemMessagesService";
import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { ISystemMessage } from "./systemMessage.model";
import { UserInfoService } from "../../js/services/UserInfoService";
import { IFxPService } from "../../js/interfaces/IFxpService";
import { FxpHttpClientService } from "../../js/services/FxpHttpClientService";
import * as Q from 'q';

export class SystemMessagesService implements ISystemMessagesService,IFxPService {
	private systemMessagesEndPoint: string;
	private allSystemMessagesEndpoint: string[];

	private allSystemAnnouncementEndpoints: string[];

	constructor(private http: FxpHttpClientService,
		private fxpConfiguration: FxpConfigurationService, private userInfoService: UserInfoService) {
		this.systemMessagesEndPoint = this.fxpConfiguration.FxpAppSettings.FxpServiceEndPoint + "/systemmessages";
		this.allSystemMessagesEndpoint = this.fxpConfiguration.FxpAppSettings.SystemMessageEndpoints.split(',');
		this.allSystemAnnouncementEndpoints = this.fxpConfiguration.FxpAppSettings.SystemAnnouncementEndpoints.split(',');
	}

	getSystemMessagesCollectionAsync = (messageCount: number, pageOffset: number, sortOrder: string): Q.Promise<Array<ISystemMessage>> => {
		let params = {
			'pageSize': messageCount,
			'pageNo': pageOffset,
			'sortOrder': sortOrder
		};
		return Q(this.http.get(this.systemMessagesEndPoint, { params: params }));
	}

	saveSystemMessageAsync = (systemMessage: ISystemMessage): Q.Promise<any> => {
		return Q(this.http.post(this.systemMessagesEndPoint, systemMessage, { headers: { 'Content-Type': 'application/json' } }));
	}

	deleteSystemMessageAsync = (systemMessageId: string): Q.Promise<any> => {
		let params = { 'id': systemMessageId };
		return Q(this.http.delete(this.systemMessagesEndPoint, { params: params }));
	}

	getPlannedDownTimeCollection = (): Q.Promise<Array<ISystemMessage>> => {
		let promises : Array<Q.Promise<Array<ISystemMessage>>> = [];
		let self = this;
		self.allSystemMessagesEndpoint.forEach(endpoint => { promises.push(this.getSystemMessages(endpoint))})
		return Q.all(promises).then((results: Array<Array<ISystemMessage>>) => {
			return results.reduce((acc, val) => acc.concat(val), []);
		});
	}

	getSystemAnnoucementCollection = (): Q.Promise<Array<ISystemMessage>> => {
		let promises : Array<Q.Promise<Array<ISystemMessage>>> = [];
		let self = this;
		let userAlias = self.userInfoService.getLoggedInUser();
		let requestHeaders = {};
		requestHeaders["x-alias"] = userAlias;
		self.allSystemAnnouncementEndpoints.forEach(endpoint => { promises.push(this.getSystemMessages(endpoint, requestHeaders))})
		return Q.all(promises).then((results: Array<Array<ISystemMessage>>) => {
			// append all the api results in a single array
			return results.reduce((acc, val) => acc.concat(val), []);
		});
	}

	getSystemMessages(url: string, headers?: any) : Q.Promise<Array<ISystemMessage>> {
		return  Q.Promise((resolve, reject) => {
			this.http.get(url, { headers: headers })
			.then(response => resolve((<any>response.data).systemMessages))
				.catch(function (error) {
					reject(error);
				});
		});  
	}
}