import { IPersonalizationService } from "../interfaces/IPersonalizationService";
import { FxpConfigurationService } from "./FxpConfiguration";
import { ILogger } from "../interfaces/ILogger";
import { FxpMessageService } from "../../app/banner/FxpMessageService";
import { FxpConstants, PerfMarkers } from "../common/ApplicationConstants";
import { CommonUtils } from "../utils/CommonUtils";
import { IFxPService } from "../interfaces/IFxpService";
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { ErrorCodes } from "../constants/errorCodes";
import { ErrorSeverityLevel } from "../telemetry/ErrorSeverityLevel";
import { MsalAuthenticationService } from "./MsalAuthenticationService";
import { FxpHttpClientService } from "./FxpHttpClientService";
import * as Q from 'q';
import { FxpRootScopeService, IRootSubjects } from "./FxpRootScopeService";

export class PersonalizationService implements IPersonalizationService, IFxPService {
	private fxpRootScopeService: FxpRootScopeService;
	private fxpRootScope: IRootSubjects;
	private sleepInterval: number;
	private iCount: number = 0;
	private iReqCount: number = 0;
	private iUCount: number = 0;
	private fxpServiceEndPoint: string;
	private oneProfileEndPoint: string;
	private sourceForTelemetry = `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.PersonalizationService`;
	private tenantClaimsApiEndpoint: string;
	private tenantRolesApiEndpoint: string;
	private tenantNPD: string;
	private NotificationApiEndpoint: string;
	constructor(private http: FxpHttpClientService,
		private fxpConfiguration: FxpConfigurationService,
		private fxplogger: ILogger,
		private msalAuthenticationService: MsalAuthenticationService,
		private fxpMessageSvc: FxpMessageService) {
		this.sleepInterval = 100;
		this.fxpRootScopeService = FxpRootScopeService.getInstance();
		this.fxpRootScopeService.rootScopeSubject.subscribe((data) => {
			this.fxpRootScope = data;
		});
		this.fxpServiceEndPoint = this.fxpConfiguration.FxpAppSettings.FxpServiceEndPoint;
		let profileStore = window["tenantConfiguration"].ProfileStore || {};
		this.oneProfileEndPoint = profileStore.ProfileAPIEndPoint;
		let authStore = window["tenantConfiguration"].AuthStore || {};
		let rolePersonalizationStore = window["tenantConfiguration"].RolePersonalizationStore || {};
		this.tenantClaimsApiEndpoint = authStore.TenantClaimsEndPoint;
		this.tenantRolesApiEndpoint = rolePersonalizationStore.TenantRolesEndPoint;
		this.tenantNPD = window["tenantConfiguration"].NPD;
		let NotificationStore = window["tenantConfiguration"].NotificationStore || {};
		this.NotificationApiEndpoint = NotificationStore.NotificationApiEndPoint;

	}

	/**
	* A method to Get the Persisted Pesanalization User Informantion
	* @method Fxp.Services.PersonalizationService.getPersistedPersonalization
	* @example <caption> Example to use getPersistedPersonalization</caption>
	* PersonalizationService.getPersistedPersonalization()
	*/
	getPersistedPersonalization(): any {
		let context = localStorage.getItem("selectedUserPersonalization");
		let keyContext = null;
		if (!CommonUtils.isNullOrEmpty(context)) {
			let data = JSON.parse(context);
			keyContext = data.user;
		}
		return keyContext;

	}
	/**
   * A method to Persisted   Pesanalization for selected User
   * @method Fxp.Services.PersonalizationService.getPersistedPersonalization
   * @example <caption> Example to use getPersistedPersonalization</caption>
   * PersonalizationService.persistUserPersonalization()
   */
	persistUserPersonalization(value): void {
		let context = localStorage.getItem("selectedUserPersonalization");
		let selectedUserPersonalization = null;
		if (!CommonUtils.isNullOrEmpty(context)) {
			selectedUserPersonalization = JSON.parse(context);
		} else {
			selectedUserPersonalization = {
				user: null
			}
		}
		selectedUserPersonalization.user = value;
		if (!CommonUtils.isNullOrEmpty(selectedUserPersonalization)) {
			localStorage.setItem("selectedUserPersonalization", JSON.stringify(selectedUserPersonalization));
		}
	}
	/**
   * A method to Remove the Persisted Pesanalization User Informantion
   * @method Fxp.Services.PersonalizationService.removePersistedUserPersonalization
   * @example <caption> Example to use removePersistedUserPersonalization</caption>
   * PersonalizationService.removePersistedUserPersonalization()
   */
	removePersistedUserPersonalization(): void {
		localStorage.removeItem("selectedUserPersonalization");
	}
	/**
	 *A method to use Fetch the Global Master LeftNav Items From Api
	 * @method Fxp.Services.PersonalizationService.getMasterLeftNavItems
	 * @example <caption> Example to use getMasterLeftNavItems</caption>
	 * PersonalizationService.getMasterLeftNavItems()
	 */
	getMasterLeftNavItems(): Q.Promise<any> {
		var self = this;
		const telemetry_source = `${self.sourceForTelemetry}.GetMasterLeftNavItems`;
		var deferred = Q.defer();
		var url = self.fxpServiceEndPoint + "/leftNav/master";
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationService_GetMasterLeftNav_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getMasterLeftNavItems();
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.GetMasterLeftNavItems);
			self.iReqCount = 0;
			let requestHeaders = {};
			requestHeaders["X-TenantId"] = window["tenantConfiguration"].TenantId;
			return Q.Promise((resolve, reject) => {
				self.http.get(url, { headers: requestHeaders })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetMasterLeftNavItems);
		return deferred.promise;
	}

	/**
	*A method to use Fetch the Personalaized NavItems From Api
	* @method Fxp.Services.PersonalizationService.getPersonalizedNavItems
	* @example <caption> Example to use getPersonalizedNavItems</caption>
	* PersonalizationService.getPersonalizedNavItems()
	*/
	getPersonalizedNavItems(userAlias: string, roleGroupId: string, userRoleId: string): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		const telemetry_source = `${self.sourceForTelemetry}.GetPersonalizedNavItems`;
		var url = this.fxpServiceEndPoint + "/user/" + userAlias + "/leftNav/personalization?roleGroupId=" + roleGroupId + "&userRoleId=" + userRoleId;
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationService_GetPersonalizedLeftNav_Failure, null, null, null, null, ErrorSeverityLevel.Medium);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getPersonalizedNavItems(userAlias, roleGroupId, userRoleId);
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.GetPersonalizedNavItems);
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				self.http.get(url)
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetPersonalizedNavItems);
		return deferred.promise;
	}

	/**
	*A method to use save the Personalaized NavItems to Api
	* @method Fxp.Services.PersonalizationService.savePersonalizedNavItems
	* @example <caption> Example to use savePersonalizedNavItems</caption>
	* PersonalizationService.savePersonalizedNavItems(userAlias: string, savedUserPrefNavList: any)
	*/
	savePersonalizedNavItems(userAlias: string, savedUserPrefNavList: any, shouldAssignRoles: boolean = true): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		const telemetry_source = `${self.sourceForTelemetry}.SavePersonalizedNavItems`;
		var url = self.fxpServiceEndPoint + "/user/" + userAlias + "/leftNav/personalization";
		savedUserPrefNavList.ShouldAssignRoles = shouldAssignRoles;
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationSerivce_SavePersonalizedNavItems_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.savePersonalizedNavItems(userAlias, savedUserPrefNavList);
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.SavePersonalizedNavItems);
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				self.http.post(url, savedUserPrefNavList, { headers: { 'Content-Type': 'application/json' } })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.SavePersonalizedNavItems);
		return deferred.promise;
	}

	/**
	*A method to use Fetch the RoleGroupDetails From Api
	* @method Fxp.Services.PersonalizationService.getRoleGroupDetails
	* @example <caption> Example to use getRoleGroupDetails</caption>
	* PersonalizationService.getRoleGroupDetails()
	*/
	getRoleGroupDetails(): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		const telemetry_source = `${this.sourceForTelemetry}.GetRoleGroupDetails`;
		var url = self.tenantRolesApiEndpoint + "/roles";
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationSerivce_GetRoleGroupDetails_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getRoleGroupDetails();
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.GetRoleGroupDetails);
			self.iReqCount = 0;
			let requestHeaders = {};
			requestHeaders["X-Tenants"] = self.tenantNPD;
			return Q.Promise((resolve, reject) => {
				this.http.get(url, { headers: requestHeaders })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});

		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetRoleGroupDetails);
		return deferred.promise;
	}

	/**
	*A method to use Fetch the RoleGroupDetails From Api
	* @method Fxp.Services.PersonalizationService.getRoleGroupDetailsForNotification
	* @example <caption> Example to use getRoleGroupDetails</caption>
	* PersonalizationService.getRoleGroupDetailsForNotification()
	*/
	getRoleGroupDetailsForNotification(): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		const telemtry_source = `${this.sourceForTelemetry}.GetRoleGroupDetailsForNotification`;
		var url = self.NotificationApiEndpoint + "/master/rolegroup";
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemtry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationSerivce_GetRoleGroupDetailsForNotification_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getRoleGroupDetails();
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.GetRoleGroupDetails);
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				this.http.get(url)
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});

		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetRoleGroupDetails);
		return deferred.promise;
	}

	/**
	*A method to use get the Role Personalaized NavItems from Api
	* @method Fxp.Services.PersonalizationService.getRolePersonalizedNavItems
	* @example <caption> Example to use getRolePersonalizedNavItems</caption>
	* PersonalizationService.getRolePersonalizedNavItems(userRoleId, roleGroupId)
	*/
	getRolePersonalizedNavItems(userRoleId: string, roleGroupId: string): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		var url = this.fxpServiceEndPoint + "/navigation/personalization?roleGroupId=" + roleGroupId;
		const telemetry_source = `${this.sourceForTelemetry}.GetRolePersonalizedNavItems`;
		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationSerivce_GetRolePersonalizedNavItems_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getRolePersonalizedNavItems(userRoleId, roleGroupId);
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.GetPersonalizedNavItems);
			self.iReqCount = 0;
			var requestHeaders = {}
			requestHeaders["tenantId"] = window["tenantConfiguration"].TenantId;
			return Q.Promise((resolve, reject) => {
				self.http.get(url, { headers: requestHeaders })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetPersonalizedNavItems);
		return deferred.promise;
	}

	/**
	*A method to use get the RoleGroup Personalaized NavItems from Api
	* @method Fxp.Services.PersonalizationService.savePersonalizedNavItems
	* @example <caption> Example to use getRoleGroupPersonalizedList</caption>
	* PersonalizationService.getRoleGroupPersonalizedList(roleGroupId)
	*/
	getRoleGroupPersonalizedList(roleGroupId: string): Q.Promise<any> {
		var self = this;
		var deferred = Q.defer();
		const telemetry_source = `${this.sourceForTelemetry}.GetRoleGroupPersonalizedList`;
		var url = self.fxpServiceEndPoint + "/navigation/personalization?AppRole=" + roleGroupId;

		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationSerivce_GetRoleGroupPersonalizedList_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.getRoleGroupPersonalizedList(roleGroupId);
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.GetPersonalizedNavItems);
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				self.http.get(url, { headers: { "X-TenantId": window["tenantConfiguration"].TenantId } })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.GetPersonalizedNavItems);
		return deferred.promise;
	}

	/**
	*A method to use save the Personalaized NavItems to Api
	* @method Fxp.Services.PersonalizationService.savePersonalizedNavItems
	* @example <caption> Example to use savePersonalizedNavItems</caption>
	* PersonalizationService. saveRoleGroupPersonalizedNavItems(roleGroupId: string, userRoleId: string, savedRoleGroupPrefNavList: any)
	*/
	saveRoleGroupPersonalizedNavItems(savedRoleGroupPrefNavList: any, shouldAssignRoles: boolean = true): Q.Promise<any> {
		var self = this, url;
		var deferred = Q.defer();
		const telemetry_source = `${this.sourceForTelemetry}.SaveRoleGroupPersonalizedNavItems`;
		savedRoleGroupPrefNavList.ShouldAssignRoles = shouldAssignRoles;
		url = self.fxpServiceEndPoint + "/navigation/personalization";

		if (self.msalAuthenticationService.accessTokenRequestInProgress(url)) {
			self.iReqCount++;
			if (self.iReqCount == 5) {
				self.fxplogger.logError(telemetry_source, self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessageTitle, ErrorCodes.PersonalizationSerivce_SaveRoleGroupPersonalizedNavItems_Failure, null, null, null, null, ErrorSeverityLevel.High);
				self.fxpMessageSvc.addMessage(self.fxpRootScope.fxpUIConstants.UIMessages.AADAuthFailureError.ErrorMessage, FxpConstants.messageType.error);
			}
			setTimeout(function () {
				self.saveRoleGroupPersonalizedNavItems(savedRoleGroupPrefNavList, shouldAssignRoles);
			}, self.sleepInterval);
		} else {
			self.fxplogger.startTrackPerformance(PerfMarkers.SaveRoleGroupPersonalizedNavItems);
			self.iReqCount = 0;
			return Q.Promise((resolve, reject) => {
				self.http.post(url, savedRoleGroupPrefNavList, { headers: { 'Content-Type': 'application/json', 'X-TenantId': window['tenantConfiguration'].TenantId } })
					.then(function (response) {
						resolve(response.data);
					})
					.catch(function (error) {
						reject(error);
					});
			});
		}
		self.fxplogger.stopTrackPerformance(PerfMarkers.SaveRoleGroupPersonalizedNavItems);
		return deferred.promise;
	}

	/**
	*A method to use get the RoleGroup which is configured in Sysytem
	* @method Fxp.Services.PersonalizationService.getDefaultRoleGroup
	* @example <caption> Example to use getDefaultRoleGroup</caption>
	* PersonalizationService.getDefaultRoleGroup(roleGroupId: number)
	*/
	getRoleGroup(roleGroupId: number): any {
		var self = this;
		return self.http.get("/Home/GetRoleGroup?roleGroupId=" + roleGroupId);
	}
}
