import { IRootScope } from "../interfaces/IRootScope";
import { UserProfileService } from "./userProfileService";
import { AppControllerHelper } from "../factory/AppControllerHelper";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpRootScopeService, IRootSubjects } from "./FxpRootScopeService";

export class pageTourEventService implements IFxPService {
	private rootScope: IRootScope;
	currentLeftNavPinState: boolean;
	isPageTourInProgress: boolean;
	private rootScopeService: FxpRootScopeService;
	private fxpRootScope: IRootSubjects;
	constructor($rootScope: IRootScope,
		private userProfileService: UserProfileService,
		appControllerHelper: AppControllerHelper
	) {
		this.rootScope = $rootScope;
		this.isPageTourInProgress = false;
		this.rootScopeService = FxpRootScopeService.getInstance();
		this.rootScopeService.rootScopeSubject.subscribe((data) => {
			this.fxpRootScope = data;
		});
	}
	init() {
		var self = this;
		self.rootScope.$on('pageTour-initialize', function (event) {
			self.isPageTourInProgress = true;
			self.currentLeftNavPinState = self.fxpRootScope.isLeftNavPinned;
			self.rootScopeService.setRootScopeField("isLeftNavOpen", true);
			self.rootScopeService.setRootScopeField('isLeftNavPinned', true);
			self.rootScopeService.setRootScopeField('isNewTabAllowed', false);
		});

		self.rootScope.$on('pageTour-Completed', function (event) {
			self.isPageTourInProgress = false;
			self.rootScopeService.setRootScopeField('isLeftNavPinned', self.currentLeftNavPinState);
			self.rootScopeService.setRootScopeField("isLeftNavOpen", self.currentLeftNavPinState);
			self.rootScope.setRootScopeField('isNewTabAllowed', true);
		});

		self.rootScope.$on('collapse-leftNav', function (event) {
			self.rootScopeService.setRootScopeField('isLeftNavPinned', false);
			self.rootScopeService.setRootScopeField("isLeftNavOpen", false);
			self.currentLeftNavPinState = false;
			self.rootScope.setRootScopeField('isNewTabAllowed', true);
		});

		self.rootScope.$on('reset-leftNav', function (event) {
			self.rootScopeService.setRootScopeField('isLeftNavPinned', true);
			self.rootScopeService.setRootScopeField("isLeftNavOpen", true);
			self.currentLeftNavPinState = true;
			self.rootScope.setRootScopeField('isNewTabAllowed', true);
		});

	}
}