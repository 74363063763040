import { FxpConfigurationService } from "../../js/services/FxpConfiguration";
import { ILogger } from "../../js/interfaces/ILogger";
import { FxpConstants } from "../../js/common/ApplicationConstants";
import { IFxPService } from "../../js/interfaces/IFxpService";
import { AddBanner, CloseBanner } from './banner.action';
import { IPlatformState } from '../store/platform.state.model';
import { FxpGlobalStoreService } from "../../js/services/fxp.global.store.service";
import { PlatformStoreFactory } from '../store/platform.store.factory';
import { TelemetryConstants } from "../../js/telemetry/TelemetryConst";
import { FxpRootScopeService } from "../../js/services/FxpRootScopeService";
/**
 * @application  Fxp
 */
/**
 * @module Fxp.Services
 */
/**
   * A service to display all types of Messages like Error, Warning, Information
   * @class Fxp.Services.fxpMessageService
   * @classdesc A service to display all types of Messages like Error, Warning, Information
   * @example <caption> Example to create an instance of Fxp Message Service</caption>         
  */
export class FxpMessageService implements IFxPService {
	private rootScopeService: FxpRootScopeService;
	private msgTimeout;
	private msgInterval;
	private fxpConfigurationService: FxpConfigurationService;
	private trackIdLabel;
	private msgIDLabel;
	private controlIdToFocusOnClose: any;
	public showBanner: boolean;


	constructor(fxpConfigurationService: FxpConfigurationService,
		private fxpLoggerService: ILogger, private globalStoreService: FxpGlobalStoreService,
	) {
		this.rootScopeService = FxpRootScopeService.getInstance();
		this.rootScopeService.setRootScopeField("messages", []);
		this.rootScopeService.setRootScopeField("messageClass", "modal-hide");
		this.msgTimeout = fxpConfigurationService.FxpAppSettings.FxpMessageTimeout;
		if (this.msgTimeout == "" || this.msgTimeout == null || isNaN(this.msgTimeout))
			this.msgTimeout = 2000;
		this.rootScopeService.setRootScopeField("closeMessage", this.closeMessage.bind(this));
		this.rootScopeService.setRootScopeField("clearAndReload", this.clearAndReload.bind(this));
		this.trackIdLabel = fxpConfigurationService.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.MessageToasterTrackId;
		this.msgIDLabel = fxpConfigurationService.FxpBaseConfiguration.FxpConfigurationStrings.UIStrings.MessageID;
		this.showBanner = true;

		PlatformStoreFactory.CreateIfNotExist(fxpLoggerService);

		this.globalStoreService.SubscribeToPlatformState("Platform", (platformState: IPlatformState) => {
			this.updateBanners(platformState);
		});
	}
	private updateBanners(platformState: IPlatformState) {
		let messages = this.rootScopeService.rootScopeSubject.value.messages;
		if (platformState.Banners && platformState.Banners.length != messages.length) {
			let isNewMessageAdded = platformState.Banners.length > messages.length
			if (isNewMessageAdded) {
				let msg = platformState.Banners[platformState.Banners.length - 1];
				messages.push(msg);
				this.rootScopeService.setRootScopeField("messages", messages);
			}
			else {
				for (var i = 0; i < messages.length; i++) {
					var index = platformState.Banners.indexOf(messages[i]);
					if (index == -1) {
						messages.splice(i, 1);
						break;
					}
				}
			}

		}
	}
	/**
	* Displays Error/Warning/Information messages on FXP and Focus
	* @method Fxp.Services.fxpMessageService.addMessage      
	* @param {string} a mandatory string value which contains Error/Warning/Information.          
	* @param {string} a mandatory string value determing type of messsage Error/Warning/Information.          
	* @example <caption> Example to invoke addMessage</caption>
	*  fxpMessageService.addMessage('Error from FXP', fxpConstants.messageType.error);
	*/
	public addMessage(message: any, messageType: string, doNotAutoClose?: boolean, trackingId?: string, messageID?: string, focusControlIdOnClose?: any) {
		var self = this;
		this.controlIdToFocusOnClose = focusControlIdOnClose;
		if (!this.showBanner) {
			return;
		}
		if (!message || message.length === 0) {
			return;
		}
		let messages: Array<any> = this.rootScopeService.rootScopeSubject.value.messages;
		var msgTrackId = trackingId ? this.trackIdLabel + trackingId : "";
		var msgID = messageID ? this.msgIDLabel + messageID : "";

		var isMsgItemExist = messages.some(function (item) {

			return item.MessageType == messageType && item.Message == message && item.trackId == msgTrackId && item.uniqueTransactionId == msgID;
		});
		if (isMsgItemExist) {
			return;
		}
		var msg: any = {};
		msg.msgDate = new Date();
		msg.MessageType = messageType || FxpConstants.messageType.info;
		msg.Message = message;
		msg.show = true;
		msg.trackId = msgTrackId;
		msg.uniqueTransactionId = msgID;

		switch (msg.MessageType) {
			case FxpConstants.messageType.success:
				msg.doNotAutoClose = (doNotAutoClose !== undefined && doNotAutoClose !== null) ? doNotAutoClose : false;
				break;
			case FxpConstants.messageType.error:
				msg.doNotAutoClose = true; // Never auto close
				var propbag = this.fxpLoggerService.createPropertyBag();
				var errorMessageWithTrackingId = trackingId ? message + " " + trackingId : message;
				propbag.addToBag("ErrorMessage", errorMessageWithTrackingId);
				propbag.addToBag("Location", window.location.hash);
				this.fxpLoggerService.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpMessageService.addMessage`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ErrorDisplayed`, propbag);

				break;
			default:
				// Do not auotoclose unless it is asked explicitly
				msg.doNotAutoClose = (doNotAutoClose !== undefined && doNotAutoClose !== null) ? doNotAutoClose : true;
				break;
		}

		//Dispatch action to add banner
		self.globalStoreService.DispatchGlobalAction("Platform", AddBanner(msg));
		this.rootScopeService.setRootScopeField("messages", messages);
		this.rootScopeService.setRootScopeField("messageClass", messages.length > 0 ? "modal-show" : "modal-hide");

		if (this.isAnyBlockingMessageVisible()) {
			this.rootScopeService.setRootScopeField("messageClass", this.rootScopeService.getRootScopeField("messageClass") + " modal-blocker");
		}
		if (messages.length == 1 && $(":focus").length > 0) {
			this.rootScopeService.setRootScopeField("activeElement", $(":focus"));
		}

		if (messages.length > 0) {
			let timeout = setTimeout(() => {
				const messagecontainers = document.querySelectorAll(".message-container");
				if (messagecontainers.length > 0) {
					const msgContainer = messagecontainers[messagecontainers.length - 1] as HTMLElement;
					const closeButton = msgContainer.querySelector("button");
					if (closeButton) {
						closeButton.focus();
					}
				}
				clearTimeout(timeout);
			}, 100);
		}
		if (!msg.doNotAutoClose) {
			this.monitorMessages();
		}
	}

	private monitorMessages() {
		if (this.msgInterval) {
			return;
		}
		let timeout = this.msgTimeout;
		let rootScopeService = this.rootScopeService;
		let rootScopeMessages = this.rootScopeService.rootScopeSubject.value.messages;
		let self = this;
		this.msgInterval = setInterval(function () {
			let dt: any = new Date();
			let diff: number;
			let messageExists = false;
			for (var i = self.globalStoreService.GetPlatformState().Banners.length - 1; i >= 0; i--) {
				var message = self.globalStoreService.GetPlatformState().Banners[i];
				var messageType = message.MessageType.toLowerCase();

				if ((messageType == "success" || messageType == "warning" || messageType == "info") && (!rootScopeMessages[i].doNotAutoClose)) {
					messageExists = true;
					diff = dt - message.msgDate;
					if (diff >= timeout)
						self.globalStoreService.DispatchGlobalAction("Platform", CloseBanner(message));

				}
			}
			if (!messageExists) {
				clearInterval(self.msgInterval);
				self.msgInterval = null;
			}
			if (rootScopeMessages.length == 0) {
				rootScopeService.setRootScopeField("messageClass", "modal-hide");
				let activeElement = rootScopeService.getRootScopeField("activeElement");
				if (activeElement) {
					let timeout = setTimeout(() => {
						if (activeElement)
							activeElement.focus();
						rootScopeService.setRootScopeField("activeElement", undefined);
						clearTimeout(timeout);
					}, 100);
				}
			}
		}, 1000);
	}

	/**
	* An event handler whenever message close button is clicked.
	* @method Fxp.Services.fxpMessageService.closeMessage
	* @param {onject} message An object which is passed from the view.
	* @example <caption> Example to use closeMessage</caption>
	*  <div ng-app="AppController"><div ng-click="closeMessage">Close Message</div></div>;
	*  <div ng-app="AppController as app"><div ng-click="app.closeMessage">closeMessage</div></div>;
	*/
	public closeMessage(message) {
		var self = this;
		let rootScopeMessages = this.rootScopeService.rootScopeSubject.value.messages;
		//Dispatch action to close banner
		self.globalStoreService.DispatchGlobalAction("Platform", CloseBanner(message));
		this.rootScopeService.setRootScopeField("messageClass", rootScopeMessages.length > 0 ? "modal-show" : "modal-hide");
		if (this.isAnyBlockingMessageVisible())
			this.rootScopeService.setRootScopeField("messageClass", this.rootScopeService.getRootScopeField("messageClass") + " modal-blocker");
		if (rootScopeMessages.length == 0) {
			clearInterval(this.msgInterval);
			setTimeout(function () {
				if (!self.controlIdToFocusOnClose) {
					return;
				}
				if (self.controlIdToFocusOnClose.focus) {
					self.controlIdToFocusOnClose.focus();
				}
				else if (typeof (self.controlIdToFocusOnClose) === 'string') {
					$('#' + self.controlIdToFocusOnClose).focus();
				}
			}, 0);
		}
		else
			setTimeout(function () {
				var messagecontainers = $(".message-container");
				if (messagecontainers.length) {
					let msgContainer = messagecontainers[messagecontainers.length - 1];
					$("." + msgContainer.className + " > button").first().focus();
					return;
				}
			}, 100);

	}

	public clearAndReload() {
		var propbag = this.fxpLoggerService.createPropertyBag();
		propbag.addToBag("Message", "User clicked reset application");
		propbag.addToBag("Location", window.location.hash);
		this.fxpLoggerService.logEvent(`${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpMessageService.clearAndReload`, `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.ResetApplication`, propbag);
		window["localStorage"].clear();
		window["sessionStorage"].clear();
		window.location.reload();
	}

	private isAnyBlockingMessageVisible() {
		return this.rootScopeService.rootScopeSubject.value.messages.some(e => e.MessageType == FxpConstants.messageType.error);
	}
}
