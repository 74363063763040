import { Subject } from "rxjs";
import { SyncRoutes } from "@fxp/staterouter";
import { IFxPService } from "../interfaces/IFxpService";
import { FxpConstants } from "../common/ApplicationConstants";
import { FxpGlobalStoreService } from "./fxp.global.store.service";
import { IRootScope } from "../interfaces/IRootScope";
import { FxpBroadcastedEvents } from "./FxpBroadcastedEvents";
//import { FxpLoggerService } from './../telemetry/FxpLogger';
import { TelemetryConstants } from "../telemetry/TelemetryConst";
import { CommonUtils } from "../utils/CommonUtils";
import { FeatureUsageEvent } from "../telemetry/FeatureUsageEvent";
import {
  ActionStatus,
  ActionType,
  ComponentType,
  EventName,
} from "@microsoftit/telemetry-extensions-npm";
import { FxpLoggerService } from "../telemetry/fxpLogger";
import { LogMetricBag } from "../telemetry/LogMetricBag";
import { LogPropertyBag } from "../telemetry/LogPropertyBag";
import {FxpStateService, FxpTransitionService} from "../../app/services/FxpStateRoutingHelperService";

export class FxpStateTransitionService implements IFxPService {
  //private $transitions;
  private subjects: any = [];
  private $rootScope: IRootScope;
  constructor(
    $rootScope: IRootScope,
    private $injector: angular.auto.IInjectorService,
    private transitions:FxpTransitionService,
    private stateService: FxpStateService,
    private globalStore: FxpGlobalStoreService,
    private logger: FxpLoggerService
  ) {
    this.transitions.onStart({}, this.onStartStateChangeHandler.bind(this));
    this.transitions.onSuccess(
      {},
      this.onSuccessStateChangeHandler.bind(this)
    );
    this.transitions.onError({}, this.onErrorStateChangeHandler.bind(this));
    this.stateService.onInvalid(this.onInvalidStateHandler.bind(this));
    this.$rootScope = $rootScope;
    this.subjects = [
      { id: FxpConstants.StateEvents.OnStart, subject: new Subject() },
      { id: FxpConstants.StateEvents.onSuccess, subject: new Subject() },
      { id: FxpConstants.StateEvents.onError, subject: new Subject() },
      { id: FxpConstants.StateEvents.onInvalid, subject: new Subject() },
    ];
  }

  onStartStateChangeHandler(transition: any): void {
    const self = this;
    self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.OnStart)[0]
      .subject.next(self.getStateInfo(transition));
  }

  onSuccessStateChangeHandler(transition: any): void {
    const self = this;
    self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.onSuccess)[0]
      .subject.next(self.getStateInfo(transition));
    const syncRouteAction = SyncRoutes({
      $current: <any>this.stateService.getCurrentState(),
      params: this.stateService.getParams(),
    });
    this.globalStore.DispatchGlobalAction(
      "Fxp.FxpStateTransitionService",
      syncRouteAction
    );
    self.$rootScope.$broadcast(
      FxpBroadcastedEvents.OnLeftNavHighlightByStateName,
      transition.to().name
    );
    this.logFeatureUsageEventForState(transition);
    this.logPageView(transition);
  }

  private logPageView(transition: any) {
    const currentState = transition.to();
    const appName =
      currentState.data.appNameForTelemetry ||
      currentState.data.partnerTelemetryName;
    const pageName =
      currentState.data.pageNameForTelemetry ||
      currentState.data.breadcrumbText;
    const telemetryName =
      currentState.data.partnerTelemetryName ||
      TelemetryConstants.FXP_TELEMETRY_BASE_NAME;
    let displayNameForPageView = "";
    if (pageName && appName) {
      displayNameForPageView = `${appName}.${pageName}`;
    } else {
      displayNameForPageView = currentState.data.headerName;
    }
    const startTime = parseFloat(this.$rootScope.stateChangeStartTime);
    let duration = 0;
    if (!isNaN(startTime) || startTime !== 0) {
      duration = (performance.now() - startTime) / 1000;
    }
    const measurements = this.logger.createMetricBag();
    const properties = this.logger.createPropertyBag();

    properties.addToBag(
      TelemetryConstants.LOGGING_SOURCE,
      `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpStateTransitionService.logFeatureUsageEventForState`
    );
    properties.addToBag("Unit", "Seconds");
    measurements.addToBag("StateTransitionTime", duration);
    this.logger.logPageView(
      telemetryName,
      displayNameForPageView,
      window.location.href,
      properties,
      measurements,
      duration
    );
  }

  private logFeatureUsageEventForState(transition: any) {
    const currentState = transition.to();
    const appName = currentState.data.appNameForTelemetry;
    const featureName = currentState.data.featureName;
    const subFeatureName = currentState.data.subFeatureName;
    const actionName = currentState.data.actionName;
    const telemetryName =
      currentState.data.partnerTelemetryName ||
      TelemetryConstants.FXP_TELEMETRY_BASE_NAME;
    const fromState = transition.from();
    let previousFeatureName = "",
      previousAppName: "",
      previousSubFeatureName = "";
    if (fromState && fromState.data) {
      previousFeatureName = fromState.data.featureName;
      previousAppName = fromState.data.appNameForTelemetry;
      previousSubFeatureName = fromState.data.subFeatureName;
    }
    let displayFeatureName = "";
    if (!CommonUtils.isNullOrEmpty(featureName)) {
      displayFeatureName = `${appName}.${featureName}`;
      if (!CommonUtils.isNullOrEmpty(subFeatureName)) {
        displayFeatureName = `${displayFeatureName}.${subFeatureName}`;
        if (
          !CommonUtils.isNullOrEmpty(featureName) &&
          previousFeatureName === featureName
        ) {
          this.logger.renewSubCorrelationId();
        } else {
          this.logger.renewCorrelationId();
        }
      } else {
        this.logger.renewCorrelationId();
      }
      //TODO: May be we can create featureusageevent object and set subfeature instead of playing with strings.
      const featureUsageEvent = new FeatureUsageEvent(
        displayFeatureName,
        ActionType.User,
        actionName,
        EventName.PageLoad,
        ComponentType.Web
      );
      featureUsageEvent.SubFeatureName = subFeatureName;
      featureUsageEvent.ActionStatus = ActionStatus.Succeeded;
      const properties = this.logger.createPropertyBag();
      properties.addToBag(
        TelemetryConstants.LOGGING_SOURCE,
        `${TelemetryConstants.FXP_TELEMETRY_BASE_NAME}.FxpStateTransitionService.logFeatureUsageEventForState`
      );
      this.logger.logFeatureUsageEvent(
        telemetryName,
        featureUsageEvent,
        properties,
        null,
        null
      );
    }
  }

  onErrorStateChangeHandler(transition: any): void {
    const self = this;
    self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.onError)[0]
      .subject.next(self.getStateInfo(transition));
  }

  onInvalidStateHandler(toState: any, fromState: any, injector: any): void {
    const self = this;
    self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.onInvalid)[0]
      .subject.next({
        toState: toState,
        fromState: fromState,
        injector: injector,
      });
  }

  getStateInfo(transition: any): any {
    const stateInfo = {
      toState: transition.to(),
      toParams: transition.params("to"),
      fromState: transition.from(),
      fromParams: transition.params("from"),
      error: transition.error(),
    };
    return stateInfo;
  }

  onStateChangeStart(callback): Subject<any> {
    const self = this;
    return self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.OnStart)[0]
      .subject.subscribe(callback);
  }
  onStateChangeSuccess(callback): Subject<any> {
    const self = this;
    return self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.onSuccess)[0]
      .subject.subscribe(callback);
  }
  onStateChangeFailure(callback): Subject<any> {
    const self = this;
    return self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.onError)[0]
      .subject.subscribe(callback);
  }
  onStateNotFound(callback): Subject<any> {
    const self = this;
    return self.subjects
      .filter((event) => event.id === FxpConstants.StateEvents.onInvalid)[0]
      .subject.subscribe(callback);
  }

  offStateChangeStart(observer): void {
    const self = this;
    const currentSubject = self.subjects.filter(
      (event) => event.id === FxpConstants.StateEvents.OnStart
    )[0].subject;
    currentSubject.observers.filter((x) => x == observer)[0].unsubscribe();
  }

  offStateChangeSuccess(observer): void {
    const self = this;
    const currentSubject = self.subjects.filter(
      (event) => event.id === FxpConstants.StateEvents.onSuccess
    )[0].subject;
    currentSubject.observers.filter((x) => x == observer)[0].unsubscribe();
  }

  offStateChangeFailure(observer): void {
    const self = this;
    const currentSubject = self.subjects.filter(
      (event) => event.id === FxpConstants.StateEvents.onError
    )[0].subject;
    currentSubject.observers.filter((x) => x == observer)[0].unsubscribe();
  }

  offStateNotFound(observer): void {
    const self = this;
    const currentSubject = self.subjects.filter(
      (event) => event.id === FxpConstants.StateEvents.onInvalid
    )[0].subject;
    currentSubject.observers.filter((x) => x == observer)[0].unsubscribe();
  }
}
