'use strict';
/**
* A service to connect to ConFit service to fetch configurations
* @implements Microsoft.PS.ConFit.Interface.IConfigService
* @class Microsoft.PS.ConFit.Service.ConFitService
* @classdesc A service to connect to ConFit service to fetch configurations
* @param {Object} $http    an instance of Angular HTTP service
* @param {string} confitRootUrl basePath to connect ConFit service
* @example <caption> Example to create an instance of ConFit service</caption>
    // Inject 'ConFit' module in your module for example 'TestModule'
    var module = angular.module('TestModule', ['ConFit']);

    // In your module 'module', assign constant 'confitRootUrl' as the base adddress of ConFig Service. This is MUST for injecting url to ConFit Service
     module.constant("confitRootUrl", "http://confitservicedev.azurewebsites.net/api/v1");
*/
var ConFitService = /** @class */ (function () {
    function ConFitService($http, confitRootUrl) {
        this.httpService = $http;
        this.confitRootUrl = confitRootUrl;
    }
    /**
     * Gets configurations of a given entity from ConFit service
     * @method Microsoft.PS.ConFit.Service.ConFitService#getConfig
     * @param Microsoft.PS.ConFit.Domain.ConfigRequest request An instance of ConfigRequest
     * @example <caption> Example to invoke getConfig</caption>

        // Inject 'ConFitService' to your controller 'TestController'
        module.controller('TestController', ['$scope', 'ConFitService', function ($scope, ConFitService) {

        // Call the API as shown under
        var request = new Microsoft.PS.ConFit.Domain.ConfigRequest("FXP", "Telemetry", ["Url", "ServerDns"]); // APP, FEATURE, KEYS
        var response = ConFitService.getConfig(request);

        response.then(function (jsonResponse) {
            alert('Service Call Succeeded ');
            $scope.Response = jsonResponse.data.data;
        }, function (reason) {
            alert('Service Call Failed ' + reason);
        });

        }]);
    */
    ConFitService.prototype.getConfig = function (request) {
        var self = this;
        var path = self.confitRootUrl + "/configurations/" + request.app;
        var queryParameters = { feature: request.feature, keys: request.keys };
        var httpRequestParams = {
            method: 'GET',
            url: path,
            json: true,
            params: queryParameters
        };
        return self.httpService(httpRequestParams);
    };
    ;
    ConFitService.$inject = ["$http"];
    return ConFitService;
}());
export { ConFitService };
