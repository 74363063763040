export class FxpTelemGuid {
    constructor(){}
    public create = (format?: string) => {
        function generateNumber() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        let tmpGuid = (format == "N")
            ? generateNumber() + generateNumber() + generateNumber() + generateNumber() + generateNumber() + generateNumber() + generateNumber() + generateNumber()
            : generateNumber() + generateNumber() + '-' + generateNumber() + '-' + generateNumber() + '-' + generateNumber() + '-' + generateNumber() + generateNumber() + generateNumber();
        return tmpGuid;
    }
    public empty = (format?: string) => {
        if (format == "N") {
            return "00000000000000000000000000000000";
        }
        else {
            return "00000000-0000-0000-0000-000000000000";
        }
    }
}