export class FxpModalConstant {
  public static options = {
    templateUrl: "../../templates/fxpmodal.html",
    windowClass: "fxp-modal-popup",
    keyboard: false,
    backdrop: "static",
    controllerAs: "fxpModal"
  };
}

export class FxpCancellableModalConstant {
  public static options = {
    templateUrl: "../../templates/fxpmodal-cancellable.html",
    windowClass: "fxp-modal-popup",
    keyboard: false,
    backdrop: "static",
    controllerAs: "fxpModal"
  };
}

export class FxpFeedbackModalConstant {
  public static options = {
    templateUrl: "../../templates/fxpmodal-feedback.html",
    windowClass: "fxp-modal-popup",
    keyboard: true,
    backdrop: "static",
    controllerAs: "fxpModal",
    size: 'feedback'
  };
}

export class FxpAnnouncementModalConstant {
  public static options = {
    templateUrl: "../../templates/fxpmodal-announcement.html",
    windowClass: "fxp-modal-popup",
    keyboard: true,
    backdrop: "static",
    controllerAs: "fxpModal",
    size: 'feedback'
  };
}